import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
export default function FancyDownline(props) {
    const [allLog, setallLog] = useState([]);
	const [refresh,setrefresh] = useState(true);

    var sum_A1 = 0;

    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        axios.post('https://flagapi.in/agentapi/newagentDeclaredFancy',{
        sid:ssid,
        eventId:props.eventId,
        
			  
           }).then(result => {
            setrefresh(false); 
            if(result.status === 200){
               console.log(result.data);
              var res = [];
              for (let key in result.data) { 
                    
                 if (result.data.hasOwnProperty(key)) 
                 { 
                    result.data[key].selectionId = key;
                    res.push(result.data[key]);
                    
                         
                 } 
              }

              setallLog(res);
              
            }

            
            
     }
    ).catch(e => {
     //setIsError(true);
     });   
    }, [])


    return (
        <React.Fragment>
            <div class="row session-resp-tbl table-responsive" style={{paddingTop: "15px"}}>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th colspan="10" class="bet-place-tbl-th market_type_row session-th-strip align-left" style={{backgroundColor:'rgb(110, 143, 201)'}}>DECLARED SESSIONS</th>
            </tr>
            <tr>
                <th class="bet-place-tbl-th" style={{backgroundColor: "#49494a"}}>SESSION</th>
                <th class="bet-odds-type bet-place-tbl-th">Settled at Runs</th>
                <th class="bet-odds-type bet-place-tbl-th">Status</th>
            </tr>
        </thead>
        <tbody>
        {allLog.map((item,index)=>{
              sum_A1 = sum_A1 + parseFloat(item.position);

return(
            <tr id={index}>
                <td data-column="session_title" class="bet-place-tbl-td" style={{textAlign: "left"}}>{item.runnerName}</td>
                <td class="bet-place-tbl-td bet-val-cent">
                {item.result} 
                </td>
                <td class="bet-place-tbl-td bet-val-cent">
                   {item.position >= 0 ? '+' + parseFloat(item.position).toFixed(2) : '-'+  parseFloat(Math.abs(item.position)).toFixed(2)}
                </td>
            </tr>
              )})}

            <tr>
                <td class="bet-place-tbl-td bet-val-cent">
                    Declare Session Total
                </td>
                <td class="bet-place-tbl-td bet-val-cent"></td>
                <td class="bet-place-tbl-td bet-val-cent">
                   { parseFloat(sum_A1).toFixed(2)}
                </td>
            </tr>
        </tbody>
    </table>
</div>

        </React.Fragment>
    )
}
