import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios';
import ChangeStatus from './ChangeStatus';


const cookies = new Cookies();
var globalArray1 = [];


export default function StockList(props) {
    const [user,setuser] = useState([]);
    const [client,setclient] = useState([]);
    const [agentView,setagentView] = useState(1);
   
    const [clientView,setclientView] = useState(1);
    
    const [a,seta] = useState(1);
    const [b,setb] = useState(1);

    const [refresh,setrefresh] = useState(true);
 



   

       
    useEffect(()=>{
        if(props.agentStack.length > 1)return;
        globalArray1 = [];
        
        var ssid = cookies.get('sid');
        if(!ssid) return;
        setrefresh(true);
        if(window.changePage != 1){
          setagentView(1);
          setclientView(1);
        }
        axios.post('https://flagapi.in/agentapi/Stocklist',{
               sid:ssid
    
              })
              .then(result => {
                  setrefresh(false);
                  if(result.status === 200){
                     
                    ;
                    for (let key in result.data) { 
                        
                        if (result.data.hasOwnProperty(key)) 
                        { 
                            result.data[key].user = key;
                            globalArray1.push(result.data[key]);
                            
                            
                        } 
                    }
                    let arr = [];
                    globalArray1.map((item)=>{
                        arr.push(item);
                       
                    });
                    var startCount = 0;
                    
                    if(window.changePage == 1){
                      var startCount = 15*(agentView-1);
                    }
                    setuser(arr.splice(startCount,15)); 
                    if(window.changePage == 1){
                        window.changePage = 2;
                    }

                  }

                  if(refresh){
                    setrefresh(false);
                  }
         
                 }
                     
                ).catch(e => {
                  //setIsError(true);
              });  
        },[props.updatedStatus]);




        
    useEffect(()=>{
        
        let arr = [];
        globalArray1.map((item)=>{
            arr.push(item);
        });
        var startCount = 15*(agentView-1);
        
        
        setuser(arr.splice(startCount,15)); 


        },[agentView]);


 


    const changeCountAgent = (e)=>{
        
        seta(parseInt(e.target.value));
        
    }

    const changeCountClient = (e)=>{
       
        setb(parseInt(e.target.value));
        
        
    }

    // useEffect(()=>{
       
    //     if(props.agentStack.length <= 1)return;
    //     var agentId = props.agentStack[props.agentStack.length-1].userid;
        
    //     var ssid = cookies.get('sid');
    //     if(!ssid) return;
    //     globalArray1 = [];
       
    //     setagentView(1);
    //     setclientView(1);
    //     setrefresh(true);
    //     axios.post('https://flagapi.in/agentapi/agentBelowBelowDetailInfo',{
    //            sid:ssid,
    //            agentId:agentId
    
    //           })
    //           .then(result => {
    //             setrefresh(false);
    //               if(result.status === 200){
    //                  console.log(globalArray1);
    //                 for (let key in result.data) { 
                        
    //                     if (result.data.hasOwnProperty(key)) 
    //                     { 
    //                         result.data[key].user = key;
    //                         globalArray1.push(result.data[key]);
                            
                            
    //                     } 
    //                 }
    //                 let arr = [];
    //                 globalArray1.map((item)=>{
    //                     arr.push(item);
    //                 });
    //                 var startCount = 15*(agentView-1);
                    
    //                 setuser(arr.splice(0,15)); 
    //                 console.log(arr);

    //               }
         
    //              }
                     
    //             ).catch(e => {
                 
    //         });
    //   },[props.agentStack.length]);

    
      useEffect(()=>{
       /* if(props.search === ''){
            setagentView(1);
            setclientView(1);
            return;
        }*/
        
        var arr1 = globalArray1.filter(function(item) {
            let sub1 = item.user.substring(0,props.search.length);
            return sub1.toUpperCase() === props.search.toUpperCase();
        })
 
        if(props.search === ''){
            setuser(arr1.splice(0,15)); 
            // setclient(arr2.splice(0,15)); 
            setagentView(1);
            setclientView(1);
            return;
        }
        setuser(arr1.splice(0,14)); 
        // setclient(arr2.splice(0,14)); 
                    
        },[props.searchBtn]);
        

    return (
        <React.Fragment>
           
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2> Stockists</h2>
            <ol class="breadcrumb">
                <li>
                    <a href="/agent/dashboard">Dashboard</a>
                </li>
                <li>
                    <strong> Stockists</strong>
                </li>
        
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>
    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        
                        <div class="ibox-tools">
                        <select class="" name="accountStatus" id="accountStatus" style={{float:'left', margin:'0px 10px'}}>
                    
                    <option value="0">ACTIVE
                    </option>
                    
                    <option value="1">SUSPENDED
                    </option>
                    
                    <option value="2">LOCKED
                    </option>
                    
                    <option value="-1">ALL</option>
                </select>
                            <Link to="/createstocklist" class="btn btn-primary btn-xs">Create Stockists</Link>
                        </div>
                    </div>
                    <div class="ibox-content table-responsive">

                        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper form-inline dt-bootstrap no-footer">
                            <div class="dt-buttons"><a class="dt-button buttons-csv buttons-html5" tabindex="0" aria-controls="DataTables_Table_0" href="#"><span>CSV</span></a><a class="dt-button buttons-pdf buttons-html5" tabindex="0" aria-controls="DataTables_Table_0" href="#"><span>PDF</span></a></div>
                            <div
                                id="DataTables_Table_0_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control input-sm" placeholder="" aria-controls="DataTables_Table_0"/></label></div>
                        <table class="table table-bordered table-striped dataTables-example dataTable no-footer" id="DataTables_Table_0"
                            role="grid" aria-describedby="DataTables_Table_0_info">
                        <thead>
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="User Name: activate to sort column ascending" style={{width: 0}}>User Name</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: 0}}>Name</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Fix Limit: activate to sort column ascending" style={{width: 0}}>Fix Limit</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="My Share: activate to sort column ascending" style={{width: 0}}>My Share</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Max Share: activate to sort column ascending" style={{width: 0}}>Agent Share</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Max Share: activate to sort column ascending" style={{width: 0}}>Status</th> 
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" style={{width: "30%", textAlign:'center'}}>Actions</th>
                                </tr>
                            </thead>
                            {user.length === 0 &&  <tbody><th colspan="7" style={{textAlign:'center',padding:'15px'}}>There are no Agent in List</th> </tbody>}
                            <tbody>

                            {user.map((item,index)=>{
        var lev;
        var cla;
        if(item.level == 2){
            lev = 'SC';
            cla = '1';
          }
          else if(item.level == 3){
           lev = 'SST';
           cla = '2';
          }
    
         else if(item.level == 4){
           lev = 'SS';
           cla = '3';
          }
          else if(item.level == 5){
            lev = 'SA';
            cla = '4';
           }
          
          let b = item.balance ? item.balance : 0;
          let ld = item.ldb ? item.ldb : 0;
          let lw = item.lwc ? item.lwc : 0;
          let le = item.le ? item.le : 0;
          var avail_balance = parseFloat(b)+parseFloat(ld) + parseFloat(lw);
          var status = 1;
          let expo = item.clientExposure ? item.clientExposure : 0;

          let cr = avail_balance - parseFloat(item.cf);
          if(item.userBlocked == 0 && item.betBlocked == 0){
              status = 1;
          }
          else if(item.userBlocked == 0 && item.betBlocked == 1){
              status = 2;
          }
          else if(item.userBlocked == 1){
              status = 3;
          }
       
          

        return(   
                            <tr role="row" class="odd">
                              <td class="agent_name"> <a   class="ico_account"  style={{display:'flex'}}>
                                  <span className={`lv_${true ? cla:" "}`}>{lev}</span> {item.user}</a></td>
                            <td> {item.firstname} </td>
                            <td>{item.balance}</td>
                            <td>{parseFloat(props.share).toFixed(2) - parseFloat(item.share).toFixed(2)}</td>
                            <td>{parseFloat(item.share).toFixed(2)}</td>
                            <td id="statusCol">
                            {item.userBlocked == 0 && item.betBlocked == 0 && 
                                <span id="status14" class="status-active">
                                <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Active</span>}
                                {item.userBlocked == 0 && item.betBlocked == 1 &&
                                <span id="status14" class="status-active">
                                <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Suspended</span>}
                                {item.userBlocked == 1 &&
                                <span id="status14" class="status-active">
                                <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Locked</span>}
                                </td>
                            <td class="" style={{display:'flex',padding:'5px, 10px'
                        }}>
                             <Link to={`/editAgent/${item.user}/${item.level}`}  class="btn btn-white btn-sm" ><i class="fa fa-pencil-square"></i> Edit</Link>
                                     
                           <a class="btn btn-white btn-sm" onClick={()=>{ window.changePage = 1; props.HandlePopup(1,true,{lev:lev,user:item.user,userstatus:status,type:'ag'})}}><i class="fa fa-cog"></i> status</a>

                                        <Link to={`/ChangepassAgent/${item.user}/${item.level}`}><button type="button" class="btn btn-w-m btn-primary">Change Password</button></Link>
                                    </td>
                                </tr>
                          )})}
                           
                            </tbody>
                        </table>
                        {globalArray1.length > 0 && 
                        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                            <ul class="pagination">
                                <li onClick = {()=>{if(agentView > 1){setagentView(agentView-1);}}} className={`paginate_button previous ${(agentView > 1) ? "": "disabled"}`} id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0">Previous</a></li>
                                <li class="paginate_button active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0">{agentView}</a></li>
                                <li className={`paginate_button next ${(user.length >= 15) ? "": "disabled"}`} onClick = {()=>{if(user.length >= 15){setagentView(agentView+1);}}} id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="4" tabindex="0">Next</a></li>
                            </ul>
                        </div>}
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

        </React.Fragment>
    )
}
