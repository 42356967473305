import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function BetSlip() {
    const [Users, setUsers] = useState([])
    const [Market, setMarket] = useState([])
    const [Totalbets, setTotalbets] = useState([])
    const [Settlebets, setSettlebets] = useState([])
    const [AllList, setAllList] = useState([]);
    const [marketpnl1,setmarketpnl1] = useState(0);
    const [marketpnl2,setmarketpnl2] = useState(0);
    const [marketpnl3,setmarketpnl3] = useState(0);
    const [Runner1, setRunner1] = useState([]);
    const [Runner2, setRunner2] = useState([]);
    const url = window.location.href;
    const para = url.split( '/' );
    const eventid = para[4];
    const Selectinid = para[5];

    useEffect(() => {
   
        var ssid = cookies.get('sid');
        if(!ssid) return;
        if(!eventid) return;
 
    

         axios.post('https://flagapi.in/agentapi/AllMarket',{
            sid:ssid,
            eventId:eventid,
           })
           .then(result => {
               if(result.status === 200){
             
                 setMarket(result.data);    
               }
              } 
            ).catch(e => {
               //setIsError(true);
           }); 

           axios.post('https://flagapi.in/agentapi/TotalBets',{
            sid:ssid,
            eventId:eventid,
           })
           .then(result => {
               if(result.status === 200){
              setTotalbets(result.data[0].betTotal)
              setSettlebets(result.data[0].settleBet)
               }
              } 
            ).catch(e => {
               //setIsError(true);
           }); 
           var ssid = cookies.get('sid');
           if(!ssid) return;
           if(!eventid) return;
    
           axios.post('https://flagapi.in/agentapi/MatchebetList',{
             sid:ssid,
             eventId:eventid
            })
            .then(result => {
              
                if(result.status === 200){
                  setAllList(result.data);  
                
                  setRunner1(result.data[0].runnerName1)
                  setRunner2(result.data[0].runnerName2);
                }
               }       
             ).catch(e => {
                //setIsError(true);
            });

      
               axios.post('https://flagapi.in/agentapi/newagentMarketExposure',{
                sid:ssid,
                eventId:eventid,
                marketId:Selectinid
               })
               .then(result => {
                   if(result.status === 200){  
                    setmarketpnl1(result.data.teamA_total);
                    setmarketpnl2(result.data.teamB_total);
                    console.log(result.data);
                    setmarketpnl3(result.data.draw_total);

                   }
                  }       
                ).catch(e => {
                   //setIsError(true);
               });


     }, [])
    return (
        <React.Fragment>
            <div class="right_col" role="main" >
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>BET SLIPS</h2>
            {/* <ol class="breadcrumb">
                <li>
                    <a href="/agent/dashboard">Dashboard</a>
                </li>
                <li>
                    <a href="/agent/matches/">Matches</a>
                </li>
                <li>
                    <a href="/agent/matches/33614">Islamabad United vs Quetta Gladiators</a>
                </li>
                <li>
                    <a href="#"><strong><strong>Bet Slips</strong></strong></a>
                </li>
            </ol> */}
        </div>
        <div class="col-lg-2">
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                
                    <h5>Total Bets</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{Totalbets}</h1>
            
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                  
                    <h5>Settled Bets</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{Settlebets}</h1>
             
                </div>
            </div>
        </div>
        {/* <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
 
                    <h5>Unsettled Bets</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">0</h1>
              
                </div>
            </div>
        </div> */}
        {/* <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                  
                    <h5>Reverted Bets</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">0</h1>
               
                </div>
            </div>
        </div> */}
    </div>

    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>Market Position</h5>
                        <div class="ibox-tools">
                        </div>
                    </div>
                    <div class="ibox-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style={{textAlign:'left'}}>RUNNER</th>
                                    <th style={{textAlign:'left'}}>POSITION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{Runner1}</td>
                                    <td>{marketpnl1 >= 0 ? parseFloat(marketpnl1).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl1)).toFixed(2) +')'}</td>
                                </tr>
                                <tr>
                                    <td>{Runner2}</td>
                                    <td>{marketpnl2 >= 0 ? parseFloat(marketpnl2).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl2)).toFixed(2) +')'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>Bet Slips</h5>
                        <div class="ibox-tools">
                        </div>
                    </div>
                    <div class="ibox-content table-responsive ">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th colspan="8">Bet</th>
                                    {/* <th colspan="3">My Share / Position</th> */}
                                    <th colspan="2">Settlement</th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <th>Bet ID</th>
                                    <th>DATE</th>
                                    <th>Market Title</th>
                                    <th>RATE</th>
                                    <th>AMOUNT</th>
                                    <th>MODE</th>
                                    <th>Runner Name</th>
                                    <th>User</th>
                                    {/* <th>{Runner1} </th>
                                    <th>{Runner2}</th> */}
                                    {/* <th>My Share</th>
                                    <th>{Runner1} </th>
                                    <th>{Runner2}</th> */}
                                    <th>Status</th>
                                    <th>Profit/Loss</th>
                                </tr>
                            </thead>
                            <tbody>
                            {AllList.map((item,index)=>{ 

                            var profit='';
                            var matchName;
                            var runner;
                            var odds;
                            var matchtype; 

                            if(item.eventType == '4'){
                            matchName = 'CRICKET';
                            }else if(item.eventType == '1'){
                            matchName = 'SOCCER';
                            }
                            else if(item.eventType == '2'){
                            matchName = 'TENNIS';
                            }

                            if(item.betType == 'fancy'){
                            runner = item.runnerName;
                            odds = item.rate+'/'+ parseFloat(item.teamName*100).toFixed(0);
                            }
                            else if(item.betType == 'match'){
                            odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                            if(item.teamName == 'A'){
                                runner = item.runnerName1;
                            }
                            else if(item.teamName == 'B'){
                            runner = item.runnerName2;
                            }
                            else if(item.teamName == 'T'){
                            runner = 'The Draw';
                            }
                            }

                            if(item.type === 'LAGAI'){
                            matchtype = 'BACK';
                            }
                            else if(item.type === 'KHAI'){
                            matchtype = 'LAY';
                            }
                            else{
                            matchtype = item.type;
                            }

                            if(item.betType == 'fancy' && item.result){
                            if(item.type=="YES"){
                            if(parseFloat(item.rate) <= parseFloat(item.result)){
                            
                                profit=parseFloat(item.amount*item.teamName).toFixed(2);
                            }
                            else profit=item.amount*(-1);

                            }
                            else{
                            if(parseFloat(item.rate)>parseFloat(item.result)){
                                profit=item.amount;
                            }
                            else profit=parseFloat((item.amount*item.teamName)*(-1)).toFixed(2);
                            }
                            }
                            else if(item.betType=='match' && item.winner){
                            if(item.type=="LAGAI"){
                            if(item.teamName=='A'){
                            if(item.winner=="A") profit=parseFloat(item.rate*item.amount).toFixed(2);
                            else profit=item.amount*(-1);
                            }
                            else if(item.teamName=="B"){
                            if(item.winner=="B") profit=parseFloat(item.rate*item.amount).toFixed(2);
                            else profit=item.amount*(-1);
                            }
                            else if(item.teamName=="T"){
                            if(item.winner=="T") profit=parseFloat(item.rate*item.amount).toFixed(2);
                            else profit=item.amount*(-1);
                            }
                            }
                            else if(item.type=="KHAI"){
                            if(item.teamName=='A'){
                            if(item.winner!="A") profit=item.amount;
                            else profit=parseFloat(item.rate*item.amount*-1).toFixed(2);
                            }
                            else if(item.teamName=="B"){
                            if(item.winner!="B") profit=item.amount;
                            else profit=parseFloat(item.rate*item.amount*-1).toFixed(2);
                            }
                            else if(item.teamName=="T"){
                            if(item.winner!="T") profit=item.amount;
                            else profit=parseFloat(item.rate*item.amount*-1).toFixed(2);
                            }
                            }

                            if((item.selectionIdTie== null  || item.selectionIdTie=='') && item.winner=="T"){
                            profit=0;
                            }
                            }
                            return(

                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td><i class="fa fa-clock-o"></i>&nbsp;{item.betTime}</td>
                                    <td>{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
                                    <td>{odds}</td>
                                    <td>{parseFloat(item.amount).toFixed(2)}</td>
                                    <td>{matchtype}</td>
                                    <td>
                                    {runner}
                                    </td>
                                    <td>{item.clientId}</td>
                                    {item.status == 1 &&
                                    <td>
                                        <span class="label label-warning">Active</span>
                                    </td>
                                            }
                                     {item.status == 2 &&
                                    <td>
                                        <span class="label label-warning">Declared</span>
                                    </td>}
                                    {item.status == 3 &&
                                    <td>
                                        <span class="label label-warning">Deleted</span>
                                    </td>}
                                    <td>{profit >= 0 ? profit : '('+ Math.abs(profit) +')'}</td>
                                </tr>
                                    )})}
                            </tbody>
                            {/* <tfoot>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Total</th>
                                    <th>-2500.0 </th>
                                    <th>1775.0 </th>
                                    <th> </th>
                                    <th>-250.0 </th>
                                    <th>177.5 </th>
                                    <th></th>
                                    <th>0.0</th>
                                </tr>
                            </tfoot> */}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
