import React, { useState } from 'react'

export default function PasswModel(props) {
 

    return (
          <React.Fragment>
            <div id="changeStatusModal" class="pop_bg" style={{top:'0',width: '107%', height: '124%',left:'-15px'}}>
    <div class="pop_box" style={{top:'250px', height:'160px'}}><a class="close_pop" onClick={()=>{props.OpenPassModel(false)}} >close_pop</a>
    <h3 style={{marginBottom:'35px'}}>Enter Password</h3>
 
        <div class="btn_box inline-form">
            <dl class="form_list"><dt>Password</dt>
                <dd><input id="changeStatusPassword" style={{height:'30px',width:'190px'}}onChange={(e)=>{props.setagentPass(e.target.value);}} id="paymentPassword" type="password" value={props.agentPass}    placeholder="Enter"/></dd>
            </dl>
            <div class="btn_box"><a id="changeStatusBtn" style={{width:'90px',float:'right'}} class="btn btn-primary btn-xs" onClick={()=>{props.UpdateFixlimit()}}>Change</a></div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}



