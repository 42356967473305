import React from 'react'
import { Link } from 'react-router-dom'

export default function AgentDash(props) {
  
    const url = window.location.href;
    const para = url.split( '/' );
    const ledgerExposure = para[4];
    const user = para[5];
    const ledger = para[6];
    const level = para[7];
    var Levels;

    if(level == 2){
    Levels = "Sub Admin"
    }else if(level == 3){
        Levels = "Super Stockists"
    }else if(level == 4){
        Levels = "Stockists"
    }else if(level== 5){
        Levels = "Agent"
    }else if(level == 6){
        Levels ="Client"
    }
    return (
        <React.Fragment>
    <div class="row border-bottom">
    </div>

    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>Agent</h2>
            <ol class="breadcrumb">
                <li>
                    <a href="/">Dashboard</a>
                </li>
                {level == 1 &&
                <li>
                    <Link to="/createcompany">{Levels}</Link>
                </li>}
               {level == 2 &&
                <li>
                    <Link to="/master">{Levels}</Link>
                </li>}
                {level == 3 &&
                <li>
                    <Link to="/superStockList">{Levels}</Link>
                </li>}
                {level == 4 &&
                <li>
                    <Link to="/stockList">{Levels}</Link>
                </li>}
                {level == 5 &&
                <li>
                    <Link to="/Agent">{Levels}</Link>
                </li>}
                {level == 6 &&
                <li>
                    <Link to="/master">{Levels}</Link>
                </li>}

                <li>
                    <strong>{user}</strong>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">
        </div>
    </div>



    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h1>Agent Dashboard</h1>
                    </div> 
            <div class="ibox-content table-responsive">
                <p style={{textAlign:'center'}}> 
                    <Link  to={`/receiveCash/${ledgerExposure}/${user}`}><button type="button" class="btn btn-primary btn-lg">Recieve Cash</button></Link>
                    <Link  to={`/payCash/${ledgerExposure}/${user}`}><button type="button" class="btn btn-primary btn-lg">Pay Cash</button></Link>
                    <Link  to={`/ledger/${user}`} ><button type="button" class="btn btn-primary btn-lg">Ledger</button></Link>
                    <Link to={`/CashLedger/${user}`} ><button type="button" class="btn btn-primary btn-lg">Cash Ledger</button></Link>
                    <Link to={`/MatchLedger/${user}`} ><button type="button" class="btn btn-primary btn-lg">Match Ledger</button></Link>
                    {/* <Link to="/DirectAgent"><button type="button" class="btn btn-primary btn-lg">Direct Agents</button></Link>
                    <Link to="/DirectClient"><button type="button" class="btn btn-primary btn-lg">Direct Clients</button></Link> */}
                    <Link to="/CoinHistory"><button type="button" class="btn btn-primary btn-lg">Coin History</button></Link>
                </p>
            </div>

                </div>
            </div>
        </div>

    </div>


    <div class="row">
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    {/* <!-- <span class="label label-success pull-right">Monthly</span> --> */}
                    <h5>Coins</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{ledger}</h1>
                    {/* <!--  <div class="stat-percent font-bold text-success">98% <i class="fa fa-bolt"></i></div>
                                <small>Total income</small> --> */}
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                   
                    <h5>Ledger Balance</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{ledgerExposure}</h1>
                   
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    
                    <h5></h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins"></h1>
                    
                </div>
            </div>
        </div>

    </div>


  

        </React.Fragment>
    )
}
