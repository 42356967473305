import React from 'react'

export default function SessionEarnRepo() {
    return (
        <React.Fragment>
        
<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>SESSION EARNING REPORT</h2>
        <ol class="breadcrumb">
            <li>
                <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
                <a href="/liveMatches">Matches</a>
            </li>
            <li>
                <a href="/liveMatches">Islamabad United vs Quetta Gladiators</a>
            </li>
            <li>
                <a href="#"><strong>Session Earning Report</strong></a>
            </li>
        </ol>
    </div>
    <div class="col-lg-2">

    </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Stockists</h5>
                    <div class="ibox-tools">
                    </div>
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <tr class="tbl-header-middle">
                                <th colspan="1"></th>
                                <th colspan="3">SYSTEM</th>
                                <th colspan="2">My Share</th>
                                <th colspan="1">Company Share</th>
                            </tr>
                            <tr>
                                <th>CLIENT NAME</th>
                                <th>SESSION</th>
                                <th>Comm. Session</th>
                                <th>Total</th>
                                <th>Current % Share</th>
                                <th>SHR AMT</th>
                                <th>Final</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>demo</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>5.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                            </tr>
                            <tr>
                                <td>demo01</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>30.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                            </tr>
                            <tr>
                                <td>demo02</td>
                                <td>850.0</td>
                                <td>127.4</td>
                                <td>722.6</td>
                                <td>10.0</td>
                                <td>72.26</td>
                                <td>650.34</td>
                            </tr>
                            <tr>
                                <td>demo003</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    Total
                                </th>
                                <th>850.0</th>
                                <th>127.4</th>
                                <th>722.6</th>
                                <th></th>
                                <th>72.26</th>
                                <th>650.34</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Agent</h5>
                    <div class="ibox-tools">
                    </div>
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <tr class="tbl-header-middle">
                                <th colspan="1"></th>
                                <th colspan="3">SYSTEM</th>
                                <th colspan="2">My Share</th>
                                <th colspan="1">Company Share</th>
                            </tr>
                            <tr>
                                <th>Client Name</th>
                                <th>SESSION</th>
                                <th>Comm. Session</th>
                                <th>TOTAL</th>
                                <th>Current % Share</th>
                                <th>SHR AMT</th>
                                <th>Final</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>demo002</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                                <td>0.0</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    Total
                                    {/* <!-- Username --> */}
                                </th>
                                <th>0.0</th>
                                {/* <!-- Match Plus/Minus --> */}
                                <th>0.0</th>
                                {/* <!-- Session Plus/Minus --> */}
                                <th>0.0</th>
                                {/* <!-- % Share --> */}
                                <th></th>
                                {/* <!-- Total --> */}
                                <th>0.0</th>
                                {/* <!-- Match Commission --> */}
                                <th>0.0</th>
                                {/* <!-- Session Commission --> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

        </React.Fragment>
    )
}
