import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";


const cookies = new Cookies();

export default function CollectionReoprt(props) {

    const [PayReport, setPayReport] = useState([]);
    const [ReceiveReport, setReceiveReport] = useState([]);
    const [ClearReport, setClearReport] = useState([]);
    const [refresh,setrefresh] = useState(true);

    const url = window.location.href;
    const para = url.split( '/' );
    const eventid = para[4];
    var sum_A1 = 0, sum_B1 = 0, sum_C1 = 0

    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        
 
        axios.post('https://flagapi.in/agentapi/PerMatchCollection',{
          sid:ssid,
          eventId:eventid
         })
         .then(result => {
            var res = [];
            var positiveArr = [];
            var negativeArr = [];
            var equal =[];
            if(result.status === 200){
              for (let key in result.data) { 
          
                  if (result.data.hasOwnProperty(key)) 
                  { 
                      res.push(result.data[key]);   
                     
                    //   res.forEach(function(item){
                      if(result.data[key].exposureAbove<0){
                      negativeArr.push(result.data[key]);

                      }
                      else if(result.data[key].exposureAbove>0){
                      positiveArr.push(result.data[key]);
                    
                      }
                      else if(result.data[key].exposureAbove==0){
                        equal.push(result.data[key])
                        }
                    //   })
                     
                  }
                 
               }
            
              setPayReport(positiveArr);
              setReceiveReport(negativeArr);
              setClearReport(equal);

              if(refresh){
                // setrefresh(false);
                setTimeout(()=>{setrefresh(false);},1000);
              }
        
            
           
            }
            } 
          ).catch(e => {
             //setIsError(true);
         }); 
    }, [])

    return (
        <React.Fragment>
<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>COLLECTION REPORT</h2>
        {/* <ol class="breadcrumb">
            <li>
                <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
                <a href="/agent/matches/">Matches</a>
            </li>
            <li>
                <a href="/agent/matches/33614">Islamabad United vs Quetta Gladiators</a>
            </li>
            <li>
                <a href="#"><strong><strong>Collection Report</strong></strong></a>
            </li>
        </ol> */}
    </div>
    <div class="col-lg-2">
    </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">

    
    <div class="row">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>PAYMENT RECEIVING FROM (Lena He)</h5>
                    <div class="ibox-tools">
  
                    </div>
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Client Id</th>
                                <th>Client Name</th>
                                <th>Current Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                        {PayReport.map((item,index)=>{
                                var positiveArr =item.exposureAbove;
                                 sum_A1 = sum_A1 + parseFloat(positiveArr);
                            return(
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.agentId}</td>
                                {/* <!-- Username --> */}
                                <td>{parseFloat(item.exposureAbove).toFixed(2)}</td>
                            </tr>
                             )})}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th>Total</th>
                                {/* <!-- Username --> */}
                                <th>{sum_A1.toFixed(2)}</th>
                                {/* <!-- Total --> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>


        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>PAYMENT PAID TO (Dena He)</h5>
                    <div class="ibox-tools">
          
                    </div>
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Client Id</th>
                                <th>Client Name</th>
                                <th>Current Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                        {ReceiveReport.map((item,index)=>{
                                 var negativeArr =item.exposureAbove;
                                 sum_B1 = sum_B1 + parseFloat(negativeArr);
                            return(  
                            <tr>
                                <td>{item.id}</td>
                                <a>{item.agentId}</a>
                                {/* <!-- Username --> */}
                                <td>{parseFloat(item.exposureAbove).toFixed(2)}</td>
                                {/* <!-- Total --> */}
                            </tr>
                            )})}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th>Total</th>
                                {/* <!-- Username --> */}
                                <th>{sum_B1.toFixed(2)}</th>
                                {/* <!-- Total --> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
