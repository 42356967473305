import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";
import axios from "axios";

const cookies = new Cookies();

export default function ClientReport(props) {
const [clientReports, setclientReports] = useState([])

   const url = window.location.href;
   const para = url.split( '/' );
   const eventid = para[4];

   var sum_A1 = 0, sum_B1 = 0, sum_C1 = 0, sum_D1 = 0, sum_E1= 0, sum_F1= 0, sum_G1= 0, sum_H1= 0, sum_I1= 0,sum_J1=0

   useEffect(() => {
      var ssid = cookies.get('sid');
      if(!ssid) return;

      axios.post('https://flagapi.in/agentapi/ClientReport',{
        sid:ssid,
        id:props.user,
        eventId:eventid
       })
       .then(result => {
         
           if(result.status === 200){
          
            setclientReports(result.data);    
         
           }
          }
              
        ).catch(e => {
           //setIsError(true);
       });
   }, [props.user])
    return (
        <React.Fragment>
               <div class="right_col" role="main" >
              <div class="row wrapper border-bottom white-bg page-heading">
           <div class="col-lg-10">
              <h2>CLIENT REPORT</h2>
              {/* <ol class="breadcrumb">
                 <li>
                    <a href="/agent/dashboard">Dashboard</a>
                 </li>
                 <li>
                    <a href="/agent/matches/">Matches</a>
                 </li>
                 <li>
                      <a href="/agent/matches/33614">Islamabad United vs Quetta Gladiators</a>
                 </li>
                 <li>
                    <a href="#"><strong><strong>Client Report</strong></strong></a>
                 </li>
              </ol> */}
           </div>
           <div class="col-lg-2">
           </div>
        </div>
        
        <div class="wrapper wrapper-content animated fadeInRight">
           <div class="row">
              <div class="col-lg-12">
                 <div class="ibox float-e-margins">
                    <div class="ibox-title">
                       <h5>Client Report</h5>
                    </div>
                    <div class="ibox-content table-responsive">
                       <table class="table table-bordered table-striped">
                          <thead>
                             <tr>
                                <th>User Name</th>
                                <th>Match Plus Minus</th>
                                <th>Session Plus Minus</th>
                                <th>Total</th>
                                <th>Match Commission</th>
                                <th>Session Commission</th>
                                <th>Total Commission</th>
                                <th>Net</th>
                                <th>Agent Share</th>
                                <th>OTHERS</th>
                                <th>Final</th>
                             </tr>
                          </thead>
                          <tbody>
                          {clientReports.map((item,index)=>{
                             var totalPL;
                             var MatchComm =(item.matchWinningAmount*item.matchComm)/100;
                             var Session_Commission = (item.sessionWinningAmount*item.sessionComm)/100
                             var Rolling_Commission =(item.rolling_com*item.transactionComm)/100
                             var Comm1 = parseInt(MatchComm);
                             var comm2 = parseInt(Session_Commission);
                             var num1 = parseInt(item.matchPL);
                             var num2 = parseInt(item.sessionPL);
                             totalPL= num1 + num2;
                             var  total_comm = Comm1 + comm2;
                             var  Net_Total =totalPL - total_comm;
                             var Agent_share = (Net_Total*item.share)/100;
                             var FinalTotal = (Net_Total - Agent_share) +  parseInt(item.others);

                             sum_A1 = sum_A1 + parseFloat(item.matchPL);
                             sum_B1 = sum_B1 + parseFloat(item.sessionPL);
                             sum_C1 = sum_C1 + parseFloat(totalPL);
                             sum_D1 = sum_D1 + parseFloat(MatchComm);
                             sum_E1 = sum_D1 + parseFloat(Session_Commission);
                             sum_F1 = sum_F1 + parseFloat(total_comm);
                             sum_G1 = sum_G1 + parseFloat(Net_Total);
                             sum_H1 = sum_G1 + parseFloat(Agent_share);
                             sum_I1 = sum_I1 + parseFloat(item.others);
                             sum_J1 = sum_J1 + parseFloat(FinalTotal);
                             
                            return(
                             <tr key ={index}>
                                <td>
                                   {item.id}</td>      
                                <td>{item.matchPL}</td>
                                <td>{item.sessionPL}</td>
                                <th>{totalPL}</th>
                                <td>{MatchComm}</td>
                                <td>{Session_Commission}</td>
                                <th>{total_comm}</th>
                                <th>{Net_Total}</th>
                                <td>{Agent_share}</td>
                                <td>{item.others}</td>
                                <th>{FinalTotal}</th>
                             </tr>
                               )})}
                          </tbody>
                          <thead>
                             <tr>
                                <th>Total</th>
                              
                                <th>{sum_A1.toFixed(2)}</th>
                              
                                <th>{sum_B1.toFixed(2)}</th>
                               
                                <th>{sum_C1.toFixed(2)}</th>
                               
                                <th>{sum_D1.toFixed(2)}</th>
                              
                                <th>{sum_E1.toFixed(2)}</th>
                               
                                <th>{sum_F1.toFixed(2)}</th>
                              
                                <th>{sum_G1.toFixed(2)}</th>
                             
                                <th>{sum_H1.toFixed(2)}</th>
                               
                                <th>{sum_I1.toFixed(2)}</th>
                               
                                <th>{sum_J1.toFixed(2)}</th>
                               
                             </tr>
                          </thead>
                       </table>
                    </div>
                 </div>
              </div>
           </div>
        </div>   
        </div>
        </React.Fragment>
    )
}
