import React from 'react'

export default function Marquee(props) {
    return (
        <React.Fragment>
            {/* <div class="row border-bottom">
      <ul class="marquee">
          <h4 style={{position:'absolute', margin:'4px'}}>News</h4>
            <marquee style={{marginLeft:'50px'}}>
                <a> </a>
            </marquee>
        </ul>
</div> */}


<div class="row border-bottom">
    <ul class="marquee">
        <marquee>
            <li>!! Welcome To newbook777.com !! </li>
        </marquee>
    </ul>
</div>














        </React.Fragment>
    )
}
