import React, { useState,useRef } from 'react'
// import { a } from 'react-router-dom';
import {useDetectOutsideClick} from "./useDetectOutsideClick";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function SidebarMenu(props) {
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const dropdownRef4 = useRef(null);
    const dropdownRef5 = useRef(null);
    const [DropDown, setDropDown] = useDetectOutsideClick(dropdownRef1, false);
    const [DropDown1, setDropDown1] = useDetectOutsideClick(dropdownRef2, false);
    const [DropDown2, setDropDown2] = useDetectOutsideClick(dropdownRef3, false);
    const [DropDown3, setDropDown3] = useDetectOutsideClick(dropdownRef4, false);
    const [DropDown4, setDropDown4] = useDetectOutsideClick(dropdownRef5, false);
    const [Tab1, setTab1] = useState(false);
    // const [Tab2, setTab2] = useState(false);
    const [Tab3, setTab3] = useState(false);
    const [Tab4, setTab4] = useState(false);
    const [Tab5, setTab5] = useState(false);
    const [Tab6, setTab6] = useState(false);
    const [Tab7, setTab7] = useState(false);
    const [Tab8, setTab8] = useState(false);

    const FirstTab = () =>{
    
        setDropDown(!DropDown);

  }

  const SecondTab = () =>{
    
    setDropDown1(!DropDown1);

}
const thirdTab = () =>{
    
    setDropDown2(!DropDown2);

}
const fourthTab = () =>{
    
    setDropDown3(!DropDown3);

}
const fifthTab = () =>{
    
    setDropDown4(!DropDown4);

}
   
    return (
        <React.Fragment>
              
        <div class="sidebar-collapse">
            <ul class="nav metismenu" id="side-menu">
                <li class="nav-header">
                    <div class="dropdown profile-element">
                        <span>

                    </span>
                        {/* <a data-toggle="dropdown" class="dropdown-toggle" href="#">
                        <span class="clear">
                            <span class="block m-t-xs">
                                <strong class="font-bold">Admin</strong>
                            </span>
                            <span class="text-muted text-xs block"><span class="translation_missing" title="translation missing: en.sub company">Sub Company</span> <b class="caret"></b></span> 
                        </span> </a> */}
                        <ul class="dropdown-menu animated fadeInRight m-t-xs">
                            <li><a href="/agent/agents/18243">Profile</a></li>
                            <li><a href="">Change Password</a></li>
                            <li class="divider"></li>
                            <li><a class="text" data-method="delete" href="/agents/sign_out" rel="nofollow">Log out</a></li>
                        </ul>
                    </div>
                    <div class="logo-element">
                        newbook777
                    </div>
                </li>

                <li onClick ={()=>{setTab1(1)}}  className={`${(Tab1=== true )? "active": "null"}`} >
                    <a href="/dashboard"><i class="fa fa-th-large"></i> <span class="nav-label">Dashboard</span> 
                    {/* <!-- <span class="fa arrow"></span> --> */}
                    </a>
                </li>

                <li  onClick ={FirstTab}  className={`${DropDown ? "active": "null"}`} ref={dropdownRef1}>
                    <a ><i class="fa fa-users"></i> <span class="nav-label">Manage</span>
                      <span class="fa arrow"></span>
                    </a>
                    {/* {Tab2=== true  &&  */}
                    
                    <ul className={`nav metismenu ${DropDown ? "collapse in": "collapse"}`}   id="user_type">
                       {props.userLevel < 1 &&
                        <li>
                            <a href="/createcompany" data-method="get"><i class="fa fa-users"></i>Admin</a>
                        </li>}
                       {props.userLevel < 2 &&
                        <li>
                            <a href="/master" data-method="get"><i class="fa fa-users"></i>Sub Admin</a>
                        </li>}
                        {props.userLevel < 3 &&
                        <li>
                            <a href="/superStockList" ><i class="fa fa-users"></i>Super Stockists</a>
                        </li>}
                        {props.userLevel < 4 &&
                        <li>
                        <a href="/stockList"><i class="fa fa-users"></i>Stockists</a>
                        </li>}
                        {props.userLevel < 5 &&
                        <li>
                        <a href="/Agent"><i class="fa fa-users"></i>Agent</a>
                        </li>}

                        {props.userLevel <= 5 &&
                        <li>
                        <a href="/newuser"><i class="fa fa-users"></i>client</a>
                        </li>}
                    </ul>
                </li>

                <li  onClick ={()=>{SecondTab()}}  className={`${DropDown1 ? "active": "null"}`}  ref={dropdownRef2}>
                    <a ><i class="fa fa-soccer-ball-o"></i> <span class="nav-label">Sports</span>  <span class="fa arrow"></span></a>
                   
                    <ul className={`nav metismenu ${(DropDown1 === true )? "collapse in": "collapse"}`}  id="user_type1"  >

                        <li> <a href="/Matches" ><i class="fa fa-soccer-ball-o"></i>Matches 

                        </a>
                        </li>
                        <li><a href="/liveMatches" data-method="get"><i class="fa fa-soccer-ball-o"></i>Live Matches
                          </a>
                        </li>

                        <li><a href="/sportsList" data-method="get"><i class="fa fa-soccer-ball-o"></i>Manage Matches
                          </a>
                        </li>
                        <li><a href="/sportsList" data-method="get"><i class="fa fa-soccer-ball-o"></i>Completed Matches
                          </a>
                        </li>
                    </ul>
                </li>

                {/* <li  onClick ={()=>{setTab5(!Tab5)}}  className={`${(Tab5=== true )? "active": "null"}`}>
                <a href="/Clients"><i class="fa fa-users"></i> <span class="nav-label">Manage Clients</span></a>
                </li> */}
                
                <li onClick ={()=>{setTab4(!Tab4)}}  className={`${(Tab4=== true )? "active": "null"}`}>
                    <a href="/commnlimit" data-method="get"><i class="fa fa-money"></i>
                    <span class="nav-label">Commission &amp; Limits</span>  
                  </a>
                </li>

                <li  onClick ={()=>{setTab6(!Tab6)}}  className={`${(Tab6=== true )? "active": "null"}`}>
                    <a href="/Managepass" data-method="get"><i class="fa fa-lock"></i>
                    <span class="nav-label">Manage Password</span>  
                  </a>
                </li>
               
                <li onClick ={()=>{thirdTab()}}  className={`${DropDown2 ? "active": "null"}`}  ref={dropdownRef3}>
                    <a><i class="fa fa-trophy"></i> <span class="nav-label">Sports Ledgers</span> <span class="fa arrow"></span></a>
                   
                    <ul className={`nav metismenu ${DropDown2 ? "collapse in": "collapse"}`}  id="user_type">
                        <li><a href="/CollectionReoprt" data-method="get"><i class="fa fa-inr"></i>Collection Report 
                        </a>
                        </li>
                        <li>
                            <a  href={`/ledger/${props.user}`} data-method="get"><i class="fa fa-book"></i>My Ledgers
                          </a>
                        </li>
                        <li>
                            <a href="/profitloss"><i class="fa fa-inr"></i>Profit &amp; Loss
                        </a>
                        </li>
                    </ul>
                </li>

                {/* <li onClick ={()=>{fourthTab()}}  className={`${DropDown3 ? "active": "null"}`}  ref={dropdownRef4}>
                    <a><i class="fa fa-book"></i> <span class="nav-label">Casino Ledgers</span> <span class="fa arrow"></span></a>
                   
                    <ul className={`nav metismenu ${DropDown3 ? "collapse in": "collapse"}`}  id="user_type">
                        <li><a href="/casinoCollrepo" data-method="get"><i class="fa fa-inr"></i>Collection Report 
                        </a>
                        </li>
                        <li>
                            <a href="/Myledger" data-method="get"><i class="fa fa-book"></i>My Ledgers
                          </a>
                        </li>
                       
                    </ul>
                </li> */}



                {/* <li onClick ={()=>{fifthTab()}}  className={`${DropDown4 ? "active": "null"}`}  ref={dropdownRef5}>
                    <a><i class="fa fa-book"></i> <span class="nav-label">Manage Ledgers</span> <span class="fa arrow"></span></a>
                   
                    <ul className={`nav metismenu ${DropDown4 ? "collapse in": "collapse"}`}  id="user_type">
                        <li><a href="/CollectionReoprt" data-method="get"><i class="fa fa-inr"></i>Collection Report 
                        </a>
                        </li>
                        <li>
                            <a href="/Myledger" data-method="get"><i class="fa fa-book"></i>My Ledgers
                          </a>
                        </li>
                        <li>
                            <a href="/profitloss"><i class="fa fa-inr"></i>Profit &amp; Loss
                        </a>
                        </li>
                    </ul>
                </li> */}

             
                <li onClick ={()=>{setTab7(!Tab7)}}  className={`${(Tab7=== true )? "active": "null"}`}><a  data-method="delete"onClick = {()=>{cookies.remove('sid',  { path: '/' });setTimeout(()=>{window.location.href = '/';},200);}}  rel="nofollow"><i class="fa fa-sign-out"></i> Sign out</a></li>

                {/* <!--
                <li  class="active" >
                  <a data-toggle="collapse" data-parent="#navigation" href="#submenu_daily"><i class="fa fa-table"></i> <span class="nav-label">Jobs Mangamenent</span><span class="fa arrow"></span></a>
                  <ul class="nav nav-second-level collapse" id="submenu_daily">

                      <li  class="active" ><a href="/admin/jobs?jobs=all"><i class="fa fa-list"></i>
                       <span class="nav-label">All Jobs</span>
                        </a>
                      </li>
                      <li  class="active" ><a href="/admin/jobs?job_type=service"><i class="fa fa-medkit"></i>
                        <span class="nav-label">Service Requests</span>
                        </a>
                      </li>

                      <li  class="active" ><a href="/admin/jobs?job_type=diagnostic"><i class="fa fa-stethoscope"></i>
                        <span class="nav-label">Diagnostic Requests</span>
                        </a>
                      </li>

                      <li  class="active" >
                        <a href="/admin/jobs/job_under_booking"><i class="fa fa-user"></i>
                        <span class="nav-label">Jobs Under Booking</span>
                        </a>
                      </li>

                      <li  class="active" >
                        <a href="/admin/jobs/job_abandoned"><i class="fa fa-user"></i>
                        <span class="nav-label">Jobs Abandoned</span>
                        </a>
                      </li>
                  </ul>
              </li>
 --> */}
                {/* <!--   <li  class="active" >
                <a data-toggle="collapse" data-parent="#navigation" href="#garage_submenu"><i class="fa fa-home"></i> <span class="nav-label">Garage Mangamenent</span><span class="fa arrow"></span></a>

                <ul class="nav nav-second-level collapse" id="garage_submenu">
                    <li  class="active" ><a href="/admin/garages?garages=all"><i class="fa fa-list"></i>
                       <span class="nav-label">All Garages</span>
                      </a>
                    </li>

                    <li  class="active" >
                        <a href="/admin/garages?authorised_service_center=true"><i class="fa fa-check"></i>
                           <span class="nav-label">Service Centres</span>
                       </a>
                    </li>

                    <li  class="active" >
                        <a href="/admin/garage_types"><i class="fa fa-tags"></i>
                           <span class="nav-label">Garage Types</span>
                       </a>
                    </li>
                </ul>
              </li>


              <li  class="active"  >
                  <a href="/admin/admins"><i class="fa  fa-user-secret"></i>
                      <span class="nav-label">Admins</span>
                  </a>
              </li>

            <li  class="active" >
                <a href="/admin/drivers"><i class="fa fa-street-view"></i>
                   <span class="nav-label">Drivers</span>
               </a>
            </li>

            <li  class="active" >
                <a href="/admin/users"><i class="fa fa-users"></i>
                   <span class="nav-label">Users</span>
               </a>
            </li>

            <li  class="active" >
                <a href="/admin/makes"><i class="fa fa-medium"></i>
                   <span class="nav-label">Makes</span>
               </a>
            </li>
            <li  class="active" >
                <a href="/admin/services"><i class="fa fa-medkit"></i>
                   <span class="nav-label">Services</span>
               </a>
            </li>
            <li  class="active" >
                <a href="/admin/odometer_ranges"><i class="fa fa-dashboard"></i>
                   <span class="nav-label">Odometer Ranges</span>
               </a>
            </li>

            <li  class="active" >
                <a href="/admin/membership_types"><i class="fa fa-tags"></i>
                   <span class="nav-label">Membership Types</span>
               </a>
            </li>
 --> */}
                {/* <!-- <li>
                        <a href="/admin/vehicles"><i class="fa fa-user"></i>
                             <span class="nav-label">Vehicles</span>
                        </a>
                    </li> --> */}



                {/* <!--  <li>
                        <a href="/admin/garages"><i class="fa fa-user"></i>
                             <span class="nav-label">Garages</span>
                        </a>
                    </li> --> */}

                {/* <!--  <li  class="active" <# end %>>
                  <a href="/admin/garage_services"><i class="fa fa-wrench"></i>
                     <span class="nav-label">Garage Sevices</span>
                 </a>
             </li> --> */}

                {/* <!--

                    <li>
                        <a href="/admin/jobs"><i class="fa fa-user"></i>
                             <span class="nav-label">Jobs</span>
                        </a>
                    </li> --> */}
{/* 
                <!--  <li>
                        <a href="/admin/jobs/job_under_booking"><i class="fa fa-user"></i>
                           <span class="nav-label">Job Under Booking</span>
                       </a>
                   </li>

                   <li>
                    <a href="/admin/jobs/job_abandoned"><i class="fa fa-user"></i>
                       <span class="nav-label">Job Abandoned</span>
                   </a>
               </li> --> */}

                {/* <!--    <li class = "special_a" >
                <a href="/admin/job_bookings/new"><i class="fa fa-plus"></i>
                   <span class="nav-label">New Job Booking</span>
                </a>
              </li>

               <li  class="active" >
                <a href="/admin/statuses/"><i class="fa fa-strikethrough"></i>
                   <span class="nav-label">Status</span>
                </a>
              </li>
               <li  class="active" >
                <a href="/admin/vehicle_details"><i class="fa fa-car"></i>
                   <span class="nav-label">Vehicle Details</span>
               </a>
              </li> --> */}
                {/* <!--  <li>
                        <a href="#"><i class="fa fa-bar-chart-o"></i> <span class="nav-label">Graphs</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="graph_flot.html">Flot Charts</a></li>
                            <li><a href="graph_morris.html">Morris.js Charts</a></li>
                            <li><a href="graph_rickshaw.html">Rickshaw Charts</a></li>
                            <li><a href="graph_chartjs.html">Chart.js</a></li>
                            <li><a href="graph_chartist.html">Chartist</a></li>
                            <li><a href="graph_peity.html">Peity Charts</a></li>
                            <li><a href="graph_sparkline.html">Sparkline Charts</a></li>
                        </ul>
                    </li>
                     <li>
                        <a href="mailbox.html"><i class="fa fa-envelope"></i> <span class="nav-label">Mailbox </span><span class="label label-warning pull-right">16/24</span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="mailbox.html">Inbox</a></li>
                            <li><a href="mail_detail.html">Email view</a></li>
                            <li><a href="mail_compose.html">Compose email</a></li>
                            <li><a href="email_template.html">Email templates</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="metrics.html"><i class="fa fa-pie-chart"></i> <span class="nav-label">Metrics</span>  </a>
                    </li>
                    <li>
                        <a href="widgets.html"><i class="fa fa-flask"></i> <span class="nav-label">Widgets</span></a>
                    </li> --> */}


{/* 
                <!--    <li>
                        <a href="#"><i class="fa fa-desktop"></i> <span class="nav-label">App Views</span>  <span class="pull-right label label-primary">SPECIAL</span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="contacts.html">Contacts</a></li>
                            <li><a href="profile.html">Profile</a></li>
                            <li><a href="profile_2.html">Profile v.2</a></li>
                            <li><a href="contacts_2.html">Contacts v.2</a></li>
                            <li><a href="projects.html">Projects</a></li>
                            <li><a href="project_detail.html">Project detail</a></li>
                            <li><a href="teams_board.html">Teams board</a></li>
                            <li><a href="social_feed.html">Social feed</a></li>
                            <li><a href="clients.html">Clients</a></li>
                            <li><a href="full_height.html">Outlook view</a></li>
                            <li><a href="vote_list.html">Vote list</a></li>
                            <li><a href="file_manager.html">File manager</a></li>
                            <li><a href="calendar.html">Calendar</a></li>
                            <li><a href="issue_tracker.html">Issue tracker</a></li>
                            <li><a href="blog.html">Blog</a></li>
                            <li><a href="article.html">Article</a></li>
                            <li><a href="faq.html">FAQ</a></li>
                            <li><a href="timeline.html">Timeline</a></li>
                            <li><a href="pin_board.html">Pin board</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-files-o"></i> <span class="nav-label">Other Pages</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="search_results.html">Search results</a></li>
                            <li><a href="lockscreen.html">Lockscreen</a></li>
                            <li><a href="invoice.html">Invoice</a></li>
                            <li><a href="login.html">Login</a></li>
                            <li><a href="login_two_columns.html">Login v.2</a></li>
                            <li><a href="forgot_password.html">Forget password</a></li>
                            <li><a href="register.html">Register</a></li>
                            <li><a href="404.html">404 Page</a></li>
                            <li><a href="500.html">500 Page</a></li>
                            <li><a href="empty_page.html">Empty page</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-globe"></i> <span class="nav-label">Miscellaneous</span><span class="label label-info pull-right">NEW</span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="toastr_notifications.html">Notification</a></li>
                            <li><a href="nestable_list.html">Nestable list</a></li>
                            <li><a href="agile_board.html">Agile board</a></li>
                            <li><a href="timeline_2.html">Timeline v.2</a></li>
                            <li><a href="diff.html">Diff</a></li>
                            <li><a href="sweetalert.html">Sweet alert</a></li>
                            <li><a href="idle_timer.html">Idle timer</a></li>
                            <li><a href="spinners.html">Spinners</a></li>
                            <li><a href="tinycon.html">Live favicon</a></li>
                            <li><a href="google_maps.html">Google maps</a></li>
                            <li><a href="code_editor.html">Code editor</a></li>
                            <li><a href="modal_window.html">Modal window</a></li>
                            <li><a href="forum_main.html">Forum view</a></li>
                            <li><a href="validation.html">Validation</a></li>
                            <li><a href="tree_view.html">Tree view</a></li>
                            <li><a href="chat_view.html">Chat view</a></li>
                            <li><a href="masonry.html">Masonry</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-flask"></i> <span class="nav-label">UI Elements</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="typography.html">Typography</a></li>
                            <li><a href="icons.html">Icons</a></li>
                            <li><a href="draggable_panels.html">Draggable Panels</a></li>
                            <li><a href="buttons.html">Buttons</a></li>
                            <li><a href="video.html">Video</a></li>
                            <li><a href="tabs_panels.html">Panels</a></li>
                            <li><a href="tabs.html">Tabs</a></li>
                            <li><a href="notifications.html">Notifications & Tooltips</a></li>
                            <li><a href="badges_labels.html">Badges, Labels, Progress</a></li>
                        </ul>
                    </li>

                    <li>
                        <a href="grid_options.html"><i class="fa fa-laptop"></i> <span class="nav-label">Grid options</span></a>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-table"></i> <span class="nav-label">Tables</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="table_basic.html">Static Tables</a></li>
                            <li><a href="table_data_tables.html">Data Tables</a></li>
                            <li><a href="table_foo_table.html">Foo Tables</a></li>
                            <li><a href="jq_grid.html">jqGrid</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-shopping-cart"></i> <span class="nav-label">E-commerce</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="ecommerce_products_grid.html">Products grid</a></li>
                            <li><a href="ecommerce_product_list.html">Products list</a></li>
                            <li><a href="ecommerce_product.html">Product edit</a></li>
                            <li><a href="ecommerce_product_detail.html">Product detail</a></li>
                            <li><a href="ecommerce-orders.html">Orders</a></li>
                            <li><a href="ecommerce_payments.html">Credit Card form</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-picture-o"></i> <span class="nav-label">Gallery</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li><a href="basic_gallery.html">Lightbox Gallery</a></li>
                            <li><a href="slick_carousel.html">Slick Carousel</a></li>
                            <li><a href="carousel.html">Bootstrap Carousel</a></li>

                        </ul>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-sitemap"></i> <span class="nav-label">Menu Levels </span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse">
                            <li>
                                <a href="#">Third Level <span class="fa arrow"></span></a>
                                <ul class="nav nav-third-level">
                                    <li>
                                        <a href="#">Third Level Item</a>
                                    </li>
                                    <li>
                                        <a href="#">Third Level Item</a>
                                    </li>
                                    <li>
                                        <a href="#">Third Level Item</a>
                                    </li>

                                </ul>
                            </li>
                            <li><a href="#">Second Level Item</a></li>
                            <li>
                                <a href="#">Second Level Item</a></li>
                            <li>
                                <a href="#">Second Level Item</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="css_animation.html"><i class="fa fa-magic"></i> <span class="nav-label">CSS Animations </span><span class="label label-info pull-right">62</span></a>
                    </li>
                    <li class="landing_a">
                        <a target="_blank" href="landing.html"><i class="fa fa-star"></i> <span class="nav-label">Landing Page</span> <span class="label label-warning pull-right">NEW</span></a>
                    </li>
                    <li class="special_a">
                        <a href="package.html"><i class="fa fa-database"></i> <span class="nav-label">Package</span></a>
                    </li> --> */}
            </ul>

        </div>
    
        </React.Fragment>
    )
}
