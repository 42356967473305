import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import { Link,Redirect } from 'react-router-dom'
import axios from 'axios';
import {toast} from 'react-toastify'

const cookies = new Cookies();
toast.configure()

export default function EditClient() {
    const [firstname,setfirstname] = useState('');
    const [fixLimit, setfixLimit] = useState('');
    const [lastname,setlastname] = useState('');
    const [password, setpassword] = useState('');
    const [confirmpass,setconfirmpass] = useState('');


    const url = window.location.href;
    const para = url.split( '/' );
    const user = para[4];


    useEffect(() => {
        var ssid = cookies.get('sid');
      if(!ssid) return;
        axios.post('https://flagapi.in/agentapi/SelectClient',{
            sid:ssid,
            agentId:user
 
           })
           .then(result => {
              
               if(result.status === 200){
            console.log(result.data);
                setfixLimit(result.data[0].curr_limit);
                setfirstname(result.data[0].first_name);
                setlastname(result.data[0].last_name);
               setpassword(result.data[0].password);
                }
              }
                  
             ).catch(e => {
               //setIsError(true);
       });
    }, [])

    const confirmPassword = ()=>{
            
        if(password !== confirmpass){
           
            document.getElementById("repeatPasswordErrorText").innerHTML="Password do not match!";
            return;
             }
            else{
                document.getElementById("repeatPasswordErrorText").innerHTML="";
                }
               
        }  

        const validatePass = ()=>{
            
            if(password.length < 4){
               
               document.getElementById("passwordErrorText").innerHTML="Password must be atleast 4 char long!";
               return;
            }
            else{
                document.getElementById("passwordErrorText").innerHTML="";
            }
           
        }   


    const addClient = ()=>{
      
        if(password !== confirmpass || password.length < 4 || firstname === '' || firstname === ' '){
            confirmPassword();
            validatePass();
           return;
         }

            var ssid = cookies.get('sid');
            if(!ssid) return;
           
            axios.post('https://flagapi.in/agentapi/EditClient',{
                   sid:ssid,
                   firstname:firstname,
                   lastname:lastname,
                  password:password,
                  clientId:user
                  })
                  .then(result => {
                      if(result.status === 200){
                        toast.success('Agent edit successfully!', {position:toast.POSITION.TOP_CENTER});

                        window.location.href = '/newuser';
                        return;
                      }
                    
                    else{
                       toast.warn('Something went wrong!', {position:toast.POSITION.TOP_CENTER})
                    }
                 }
             ).catch(e => {
                      //setIsError(true);
         });
      }


    return (
        <React.Fragment>
            <section id="main" style={{minHeight: "481px"}}>

    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>Edit Client</h2>
            <ol class="breadcrumb">
                <li>
                    <a href="/">Dashboard</a>
                </li>
                <li>
                    <a href="/">Clients</a>
                </li>
                <li class="active">
                    <strong>Create Client</strong>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>

    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>Edit Client</h5>
                        <div class="ibox-tools">
                      
                            <ul class="dropdown-menu dropdown-user">
                                <li><a href="#">Config option 1</a>
                                </li>
                                <li><a href="#">Config option 2</a>
                                </li>
                            </ul>
                        
                        </div>
                    </div>

                    <div class="ibox-content table-responsive">


                        <div accept-charset="UTF-8" class="form-horizontal" id="agent_form">
                            <div style={{display:"none"}}><input name="utf8" type="hidden" value="✓"/><input name="authenticity_token" type="hidden" value="Eobi5cTkiTqewM/20L+5UwNQmRI82Kqx3WmsGisnl5A="/></div>

                         
                            <div class="hr-line-dashed"></div> 
                            <div class="form-group"><label class="col-sm-2 control-label">User Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="userName" name="agent[user_name]" value={user} placeholder="User Name"  type="text" disabled
                                    />
                                <span id="userNameErrorText" class="error-text"></span>
                                </div>
                                
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Password</label>
                                <div class="col-sm-10">
                                    <input class="form-control"  name="agent[user_name]" placeholder="Password"  type="password"
                                   onChange = {(e)=>{setpassword(e.target.value);}} onBlur={()=>{validatePass();}} id="userPassword" value={password} />
                            <span id="passwordErrorText" class="error-text" ></span>
                                </div>
                                
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>


                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Confirm Password</label>
                                <div class="col-sm-10">
                                    <input class="form-control"  name="agent[user_name]" placeholder="Password"
                                     onChange = {(e)=>{setconfirmpass(e.target.value);}} onBlur={()=>{confirmPassword();}} value={confirmpass}
                                      type="password" id="repeatPassword"/>
                                 <span id="repeatPasswordErrorText" class="error-text"></span>
                                </div>
                                
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>


                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">First Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control"  name="agent[first_name]" placeholder="First Name" onChange = {(e)=>{setfirstname(e.target.value);}} 
                                    defaultValue={firstname} type="text"/>
                                    <span id="firstErrorText" class="error-text"></span>
                                </div>
                               
                            </div>

                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Last Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control"  name="agent[last_name]" placeholder="Last Name" type="text" 
                                    onChange = {(e)=>{setlastname(e.target.value);}} defaultValue={lastname}   />
                                </div>
                            </div>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label"> Fix Limit</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_fix_limit" min="0.00" placeholder="Fix Limit" required="required" type="number" disabled value={fixLimit}/> 
                                </div>
                            </div>

                            <div class="hr-line-dashed"></div>
                            <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">

                                    <Link class="btn btn-white" to="/newuser">Cancel</Link>
                                    <button class="btn btn-primary" type="submit" id="submit" onClick = {()=>{addClient();}} >Save Changes</button>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    )
  }