import React,{useState,useEffect} from 'react'
// import DownlineLive from './DownlineLive'
// import DeclaredFancy from './DeclaredFancy'
import Fancybook from "./Fancybook";
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment'
import ScoreBoard from './ScoreBoard'
import { Link } from 'react-router-dom';
import FancyDownline from './FancyDownline';
require('moment-timezone');

const cookies = new Cookies();



const io = require('socket.io-client');

const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if(ranNum == 1){
    ENDPOINT = "https://flagapi.in:2083";
}
else if(ranNum == 2){
    ENDPOINT = "https://flagapi.in:2087"; 
}
else if(ranNum == 3){
    ENDPOINT = "https://flagapi.in:2096";
}
else{
    ENDPOINT = "https://flagapi.in:2053";
}
var socket;



var c1 = 0,c2 =0,c3 = 0, c4 = 0, c5 = 0, c6 = 0,c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0;
var s1,s2,s3,s4,s5,s6,s7,s8,s9,s10,s11,s12,s13,s14,s15,s16,s17,s18,s19,s20,s21,s22,s23,s24;


export default function LiveReport() {

const [runner1BackRate1,setrunner1BackRate1] = useState(' ');
    
const [runner1LayRate1,setrunner1LayRate1] = useState(' ');
const [bookData, setbookData] = useState({})
const [runner2BackRate1,setrunner2BackRate1] = useState(' ');
const [fancyData, setfancyData ] = useState([])
const [marketBetStatus,setmarketBetStatus] = useState(0);
const [showDownline,setshowDownline] = useState(false);
const [popup,setpopup] = useState(null);
const [runner2LayRate1,setrunner2LayRate1] = useState(' ');
const [tieBackRate1,settieBackRate1] = useState(' ');
const [tieLayRate1,settieLayRate1] = useState(' ');
const [marketData, setmarketData ] = useState({});
const [bookData2, setbookData2] = useState({})
const [marketpnl1,setmarketpnl1] = useState(0);
const [marketpnl2,setmarketpnl2] = useState(0);
const [marketpnl3,setmarketpnl3] = useState(0);
const [bookpnl1,setbookpnl1] = useState(0);
const [bookpnl2,setbookpnl2] = useState(0);
const [bookpnl3,setbookpnl3] = useState(0);
const [fancySelection,setfancySelection] = useState(null);
const [fancyName,setfancyName] = useState('');
const [refresh,setrefresh] = useState(true);
const [ScoreData, setScoreData] = useState({})


const url = window.location.href;
const para = url.split( '/' );
const eventid = para[4];
const eventT = para[5];


var matchType = '';
if(eventT == 4){
    matchType = 'Cricket';
}
else if(eventT == 1){
    matchType = 'Soccer'; 
}
else if(eventT == 2){
    matchType = 'Tennis'; 
}

const changeDownlineLive = ()=>{
    setshowDownline(false);
    setpopup(null);
    setfancySelection(null);
    setfancyName('');

}


useEffect(() => {
    socket = io(ENDPOINT);
    console.log('socket connected');
    socket.on('connect', function(data) {
        socket.emit('room1', eventid);
    
    });
    
    return () => {
    socket.close();
    console.log('socket disconnected');
    s1 = -1;s2 = -1;s3=-1;s4=-1;s5=-1;s6=-1;s7=-1;s8=-1;s9=-1;s10=-1;s11=-1;s12=-1;s13=-1;s14=-1;s15=-1;s16=-1;s17=-1;s18=-1;s19=-1;s20=-1;s21=-1;s22=-1;s23=-1;s24=-1;
    }
  }, [])

const getProfitLoss = ()=>{
    var ssid = cookies.get('sid');
    
    if(!ssid) return;
    if(!eventid) return;

    if(marketData.marketId){
     axios.post('https://flagapi.in/agentapi/newagentMarketExposure',{
       sid:ssid,
       eventId:eventid,
       marketId:marketData.marketId
     
       })
       .then(result => {   
        if(refresh) {setrefresh(false);}
        if(result.status === 200){
           
            setmarketpnl1(result.data.teamA_total);
            setmarketpnl2(result.data.teamB_total);
            setmarketpnl3(result.data.draw_total);

        
         }   

        })
         .catch(e => {
    
      });
    }

    if(bookData2.marketId){
      axios.post('https://flagapi.in/agentapi/newagentMarketExposure',{
        sid:ssid,
        eventId:eventid,
        marketId:bookData2.marketId
      
        })
        .then(result => {
          
            if(refresh) {setrefresh(false);}
            if(result.status === 200){

                setbookpnl1(result.data.teamA_total);
                setbookpnl2(result.data.teamB_total);
                setbookpnl3(result.data.draw_total);

     
            }       
    
         })
          .catch(e => {
     
     });
   
    }

}


useEffect(() => {
    getProfitLoss(); 
    
    const intervalId = setInterval(() => {
      getProfitLoss();       
     }, 5000);
     
    
     return () => clearInterval(intervalId); 
     
  }, [marketData,bookData2,eventid])

    useEffect(() => {
        var ssid = cookies.get('sid');
       if(!ssid) return;
       if(!eventid) return;

       axios.post('https://flagapi.in/clientapi/getFullMarket',{
         sid:ssid,
         eventId:eventid,
        })
        .then(result => {
          
            if(result.status === 200){
           ;
           if(result.data[0]){
            let obj = result.data[0]; 
            setrunner1BackRate1(obj.runner1BackRate1); 
            setrunner1LayRate1(obj.runner1LayRate1);
            setrunner2BackRate1(obj.runner2BackRate1);
            setrunner2LayRate1(obj.runner2LayRate1);
            if(obj.selectionIdTie){
                settieBackRate1(obj.tieBackRate1);
                settieLayRate1(obj.tieLayRate1);
            }
            setmarketData(obj);
         
           } 
            }
           }      
         ).catch(e => {
            //setIsError(true);
        });


        axios.post('https://flagapi.in/clientapi/getBookMakerMarket',{
            sid:ssid,
            eventId:eventid,
           })
           .then(result => {
             
            if(result.data[0]){   
                let obj = result.data[0];   
                setbookData2(obj);
               }
              }
                  
            ).catch(e => {
               //setIsError(true);
           })       
    }, [])



    useEffect(() => {
            
        socket.on(eventid,(val)=>{
           
            var value = JSON.parse(val);
            
            if(value.messageType === 'betfair_match_rate'){
                setmarketBetStatus(value.status);
                
                
                if(s1 !== value.runner1BackRate1  ){
                    setrunner1BackRate1(value.runner1BackRate1);
                    s1 = value.runner1BackRate1;
                    c1 = 1;
                    setTimeout(()=>{c1 = 0;},  700);}
               
               
               
               
               if(s3 !== value.runner1LayRate1  ){      
                    setrunner1LayRate1(value.runner1LayRate1);
                    s3 = value.runner1LayRate1;
                    c2 = 1;
                    setTimeout(()=>{c2 = 0;},  700);}
              
             
              
               if(s5 !== value.runner2BackRate1  ){ 
                   setrunner2BackRate1(value.runner2BackRate1);
                    s5 = value.runner2BackRate1;
                    c3 = 1;
                    setTimeout(()=>{c3 = 0;},  700);}
               
              
               
               if(s7 !== value.runner2LayRate1  ){          
                    setrunner2LayRate1(value.runner2LayRate1);
                    s7 = value.runner2LayRate1;
                    c4 = 1;
                    setTimeout(()=>{c4 = 0;},  700);}
               
               

               if(s9 !== value.tieBackRate1  ){ 
                    settieBackRate1(value.tieBackRate1);
                    s9 = value.tieBackRate1;
                    c5 = 1;
                    setTimeout(()=>{c5 = 0;},  700);
                   }
               
               if(s11 !== value.tieLayRate1 ){ 
                    settieLayRate1(value.tieLayRate1);
                    s11 = value.tieLayRate1;
                    c6 = 1;
                    setTimeout(()=>{c6 = 0;},  700);
                   }

               
                          
               }


            if(value[0] && value[0].messageType === 'session_rate'){
                let temp = value;
                temp.sort(function(a,b){
          
                    return moment(a.selectionId) - moment(b.selectionId);
                   }); 
              
                setfancyData(temp);
               

            }
           
            if(value.messageType === 'bookmaker_match_rate'){
               
                let temp = bookData;
                
                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2LayRate1 = value.runner2LayRate1;

              if(bookData2.selectionIdTie){
                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieLayRate1 = value.tieLayRate1;
              }  
                setbookData(temp);
              }  

              if(value.messageType === 'match_score'){
                let temp = value;
                
                setScoreData(temp);
            }   
             })
              return ()=>{
                socket.removeAllListeners(eventid);
                setrunner1BackRate1(' ');setrunner2BackRate1(' ');
                setrunner1LayRate1(' ');setrunner2LayRate1(' ');
                
                setfancyData([]);
             }
    
        
    
      },[])
    return (
        <React.Fragment>
           
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>LIVE MATCH REPORT</h2>
            <ol class="breadcrumb">
                <li>
                    <Link href="/">Dashboard</Link>
                </li>
                <li>
                    <Link href="/liveMatches">Matches</Link>
                </li>
                <li>
                    <Link to="/liveMatches">{marketData.runnerName1} vs {marketData.runnerName2}</Link>
                </li>
                <li>
                    <a><strong><strong>Live Report</strong></strong></a>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
    <div id="render-live-report10">
        <div class="wrapper wrapper-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ibox float-e-margins">
                        <div class="row">
                        {eventT == 4 && <ScoreBoard  eventId = {eventid}  ScoreData={ScoreData}/>}
                        </div>
                        
                      {/* Matchoodsss */}
                      {/* { marketData.matchStatus == 1  &&  */}
                        <div class="row scroll-form">
                            <table class="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="bet-place-tbl-th align-left" style={{backgroundColor:"#6E8FC9"}}>Market : Match Winner</th>
                                    </tr>
                                    <tr>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>RUNNER</th>
                                        <th class="bet-odds-type bet-place-tbl-th">LAGAI</th>
                                        <th class="bet-odds-type bet-place-tbl-th">KHAI</th>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>POSITION</th>
                                    </tr>
                                </thead>
                             
                                <tbody>
                                    <tr>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left'}}>{marketData.runnerName1} </td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer" odd-val="1.77" market-id="294797" runner-id="548209" match-id="224642" data-id="LAGAI" id="Match Winner_224642_back_Leicestershire  _224642">{runner1BackRate1}</td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer" odd-val="1.8" market-id="294797" runner-id="548209" match-id="224642" data-id="KHAI" id="Match Winner_224642_lay_Leicestershire  _224642">{runner1LayRate1}</td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" >
                                        {marketpnl1 >= 0 ? '+' + parseFloat(marketpnl1).toFixed(2) : '-'+ parseFloat(Math.abs(marketpnl1)).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-runner-sid="Derbyshire_224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'white', color: 'rgb(73, 73, 74)'}}>{marketData.runnerName2}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="LAGAI"
                                            id="Match Winner_224642_back_Derbyshire_224642">{runner2BackRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="KHAI"
                                            id="Match Winner_224642_lay_Derbyshire_224642">{runner2LayRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" style={{color: "rgb(73, 73, 74)"}}>
                                        {marketpnl2 >= 0 ? '+' + parseFloat(marketpnl2).toFixed(2) : '-'+ parseFloat(Math.abs(marketpnl2)).toFixed(2)}
                                        </td>
                                    </tr>
                                    {marketData.selectionIdTie  && marketData.selectionIdTie != ' ' &&
                                    <tr>
                                        <td data-runner-sid="Derbyshire_224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'white', color: 'rgb(73, 73, 74)'}}>The Draw</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="LAGAI"
                                            id="Match Winner_224642_back_Derbyshire_224642">{tieBackRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="KHAI"
                                            id="Match Winner_224642_lay_Derbyshire_224642">{tieLayRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" style={{color: "rgb(73, 73, 74)"}}>
                                        {marketpnl3 >= 0 ? '+' + parseFloat(marketpnl3).toFixed(2) : '-'+ parseFloat(Math.abs(marketpnl3)).toFixed(2) }
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        {/* } */}

                        {/* Bookmaker */}
                       {(bookData2.matchStatus == 0 || bookData2.matchStatus == 1 || bookData2.matchStatus == 2)  &&
                        <div class="row scroll-form">
                            <table class="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="bet-place-tbl-th align-left" style={{backgroundColor:"#6E8FC9"}}>Market : Book Winner</th>
                                    </tr>
                                    <tr>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>RUNNER</th>
                                        <th class="bet-odds-type bet-place-tbl-th">LAGAI</th>
                                        <th class="bet-odds-type bet-place-tbl-th">KHAI</th>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>POSITION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left'}}>{bookData2.runnerName1} </td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer" odd-val="1.77" market-id="294797" runner-id="548209" match-id="224642" data-id="LAGAI" id="Match Winner_224642_back_Leicestershire  _224642">{bookData.runner1BackRate1}</td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer" odd-val="1.8" market-id="294797" runner-id="548209" match-id="224642" data-id="KHAI" id="Match Winner_224642_lay_Leicestershire  _224642">{bookData.runner1LayRate1}</td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0">
                                        {bookpnl1 >= 0 ? '+' + parseFloat(bookpnl1).toFixed(2) : '-'+ parseFloat(Math.abs(bookpnl1)).toFixed(2)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td data-runner-sid="Derbyshire_224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'white', color: 'rgb(73, 73, 74)'}}>{marketData.runnerName2}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="LAGAI"
                                            id="Match Winner_224642_back_Derbyshire_224642">{bookData.runner2BackRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="KHAI"
                                            id="Match Winner_224642_lay_Derbyshire_224642">{bookData.runner2LayRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" style={{color: "rgb(73, 73, 74)"}}>
                                        {bookpnl2 >= 0 ? '+' + parseFloat(bookpnl2).toFixed(2) : '-'+ parseFloat(Math.abs(bookpnl2)).toFixed(2)}
                                        </td>
                                    </tr>

                                    { bookData2.selectionIdTie && bookData2.selectionIdTie != ' ' &&  
                                    <tr>
                                        <td data-runner-sid="Derbyshire_224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'white', color: 'rgb(73, 73, 74)'}}>The Draw</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="LAGAI"
                                            id="Match Winner_224642_back_Derbyshire_224642">{bookData.tieBackRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer " odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="KHAI"
                                            id="Match Winner_224642_lay_Derbyshire_224642">{bookData.tieLayRate1}</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" style={{color: "rgb(73, 73, 74)"}}>
                                        {bookpnl3 >= 0 ? '+' + parseFloat(bookpnl3).toFixed(2) : '-'+ parseFloat(Math.abs(bookpnl3)).toFixed(2)}
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        }
                         
                             {/* Fancyy*/}

                             {(marketData.sportId == '4' || bookData2.sportId == '4') && (marketData.matchStatus == 1 || bookData2.matchStatus == 1) &&  fancyData.length > 0 &&
                        <div class="row session-resp-tbl table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="10" class="bet-place-tbl-th market_type_row session-th-strip align-left" style={{backgroundColor:'rgb(110, 143, 201)'}}>PENDING SESSIONS</th>
                                    </tr>
                                    <tr>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}> SESSION</th>
                                        <th class="bet-odds-type bet-place-tbl-th">No</th>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>RATE</th>
                                        <th class="bet-odds-type bet-place-tbl-th">Yes</th>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>RATE</th>
                                        <th class="bet-odds-type bet-place-tbl-th">Position NO</th>
                                        <th class="bet-odds-type bet-place-tbl-th">Position Yes</th>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { fancyData.map(function(item,index){   
                                    return(
                                    <tr id={index}>
                                        <td data-column="session_title" class="bet-place-tbl-td" style={{textAlign:"left"}}>{item.runnerName}</td>
                                        <td data-column="no_runs" class="SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer " data-id="NO" match-id="224642" session-run-id="" session-id="38306" id="0_no">{item.layPrice ? item.layPrice : ' '}</td>
                                       
                                        <td data-column="no_rate" id="0_no_rate" class="no_rate bet-place-tbl-td bet-val-cent ">
                                        {item.laySize ? item.laySize : ' '}
                                        </td>
                                       
                                        <td data-column="yes_runs" class="SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer " data-id="YES" match-id="224642" session-run-id="" session-id="38306" id="0_yes">
                                        
                                        {item.backPrice ? item.backPrice : ' '}
                                        </td>
                                        <td data-column="yes_rate" class="yes_rate bet-place-tbl-td bet-val-cent " id="0_yes_rate">
                                        {item.backSize ? item.backSize : ' '}
                                        </td>
                                        <td class="bet-place-tbl-td bet-val-cent" id="session_no_runns_38306">
                                            0.0
                                        </td>
                                        <td class="bet-place-tbl-td bet-val-cent" id="session_yes_runns_38306">
                                            0.0
                                        </td>
                                        <td class="bet-place-tbl-td"  onClick = {()=>{setfancyName(item.runnerName);setfancySelection(item.selectionId); setshowDownline(true);setpopup(4);}}>
                                            {/* <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong">POSITION</button> --> */}
                                            <a class="btn btn-info" data-method="get" data-remote="true">POSITION</a>
                                        </td>
                                    </tr>
                                    
                                    )})}
                                </tbody>
                            </table>
                        </div>}
                        
                          <FancyDownline changeDownlineLive = {changeDownlineLive} eventId = {eventid} marketname = {marketData.marketName ? marketData.eventName + ' - Declared Fancy' : bookData2.eventName + ' - Declared Fancy'}/>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
    <div id="dynamic_modal"></div>
    {showDownline === true && popup === 4 && <Fancybook  changeDownlineLive = {changeDownlineLive} eventId = {eventid} selectionId = {fancySelection} marketname = {marketData.marketName ? fancyName :fancyName}/>} 
        </React.Fragment>
    )
}

 