import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();



export default function LiveMatches(props) {
const [MatchList, setMatchList] = useState([])
    const [refresh,setrefresh] = useState(true);
    const [reload,setreload] = useState(true);
    const [eventList,seteventList] = useState([]);
    const [ProfitLoss, setProfitLoss] = useState({});
    const [EventType, setEventType] = useState('100')
  
    const changeEvent = (e)=>{
          setEventType(e.target.value);
      }	


    useEffect(() => {
        var ssid = cookies.get('sid');
            if(!ssid) return;
        //     setrefresh(true);
        axios.post('https://flagapi.in/agentapi/SportsListMatch',{
            sid:ssid,
            eventType:EventType
           })
           .then(result => {
               setrefresh(false);
               if(result.status === 200){   
                setMatchList(result.data);
                   let arr = [];
                   result.data.map((item)=>{
                       arr.push(item.eventId);
                   })
                   seteventList(arr);
               }
              }
                  
            ).catch(e => {
               //setIsError(true);
           });
    }, [EventType])
  
     useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        if(eventList.length == 0) return;
        if(props.user == '') return;
        axios.post('https://flagapi.in/agentapi/MatchProfitLoss',{
            sid:ssid,
            eventId: eventList,
           agentId:props.user, 

        })
          .then(result => {
             
            if(result.status === 200){
                var obj = {};
                console.log(result.data);
               
                result.data.map(item =>{
                    obj[item.eventId] = item.total;
                })
                setProfitLoss(obj);

                
            }
                }      
             ).catch(e => {
                 //setIsError(true);
        });
     }, [eventList,props.user])

    return (
        <React.Fragment>
            <div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>Matches</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/dashboard">Dashboard</a>
         </li>
         <li>
            <a href="/livematches"><strong>Matches</strong></a>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>
            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    {/* <h5>Matches</h5> */}
                    <ul class="input-list">
                  <li id="changeEventType" style={{marginLeft:"-40px"}} onChange = {(e)=>{changeEvent(e);}}>
                     <input type="radio" name="events" selected={`${EventType == 100 ? "selected":""}`} defaultChecked id="events_1" value="100"/>All
                     <input type="radio" name="events" id="events_4" selected={`${EventType == 4 ? "selected":""}`} value="4"/>Cricket
                     <input type="radio" name="events" id="events_1" selected={`${EventType == 1 ? "selected":""}`} value="1"/>Soccer
                     <input type="radio" name="events" id="events_2" selected={`${EventType == 2 ? "selected":""}`} value="2"/>Tennis
                  </li>
               </ul>
                    <div class="ibox-tools">
                    </div>
                </div>
                
                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                            <th>DATE</th>
                                <th>Event Id</th>
                                <th>Event Name</th>
                                  <th>Winning Team</th>
                                <th>Profit / Loss</th>
                            </tr>
                        </thead>
                        <tbody>
                        { MatchList.length===0  && <th colspan="7" style={{textAlign:'center',padding:'15px'}}>There are no Matches</th>}
                        {MatchList.map((item,index)=>{  
                             var Winner;
                             if(item.winner === "A"){
                                 Winner =item.runnerName1;
                             }
                             else if(item.winner === "B")
                             {
                                Winner = item.runnerName2;
                             }else if(item.winner === "T"){
                                Winner = "Draw";
                             }  else
                             {
                                Winner = "-"
                             }  
                     return(  
                     <tr key={index}> 
                                <td><i class="fa fa-clock-o"></i>&nbsp; {item.marketStartTime}
                                </td>
                                <td>{item.eventId}</td>
                                <td><strong>
                                <Link  to={`/AgnetMatchDash/${item.eventId}/${item.eventType}`}>{item.eventName} </Link>
                        </strong><br/>
                                   
                                </td>
                                <td>{Winner}  </td>
                                {!ProfitLoss[item.eventId] && 
                                <td class="font-bold text-info">0.00&nbsp;</td>
                            }
                                {ProfitLoss[item.eventId] > 0 &&
                                <td class="font-bold text-info">{parseFloat(ProfitLoss[item.eventId]).toFixed(2)}&nbsp;<i class="fa fa-level-up"></i></td>
                            }
                              {ProfitLoss[item.eventId] < 0 &&
                                <td class="font-bold text-info" style={{color:'red'}}>{parseFloat(ProfitLoss[item.eventId]).toFixed(2)}&nbsp;<i class="fa fa-level-down"></i></td>
                            }
                               
                            </tr>
                               )})}
                        </tbody>
                    </table>


                    {/* <div class="pagination"><span class="previous_page disabled">← Previous</span> 
                    <em class="current">1</em> <a rel="next" href="/agent/matches?page=2">2</a>
                     <a href="/agent/matches?page=3">3</a> <a href="/agent/matches?page=4">4</a>
                      <a href="/agent/matches?page=5">5</a>  <a href="/agent/matches?page=6">6</a>
                                 <a class="next_page" rel="next" href="/agent/matches?page=2">Next →</a></div> */}
                    <div class="pull-right">
                    </div>
                </div>
                
            
             
           


            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
