import React, { useState } from "react";
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";





const cookies = new Cookies();

toast.configure();

export default function Changepassagent(props) {


    const [Newpassword, setNewpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [password, setpassword] = useState("");


    const url = window.location.href;
const para = url.split( '/' );
const user = para[4];
const Level = para[5];



const LevelWise = ()=>{

  if(Level==2){
      window.location.href = '/master';
     
  }else  if(Level==3){
      window.location.href = '/superStockList';
     
  }
  else  if(Level==4){
      window.location.href = '/stockList';
     
  }
  else  if(Level==5){
      window.location.href = '/Agent';
     
  }      
}
  
    const ChangePassword = (e) => {
      e.preventDefault();
   
      //var strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
      //var test = Newpassword.match(strongRegex);
  
      if (Newpassword === "" || confirmpassword === "" || password === "") {
        toast.warn("Password can not be Blank!", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (Newpassword != confirmpassword) {
        toast.warn(" password is not matched!", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (Newpassword === password) {
        toast.warn("Old password and new password is not same!", {
          position: toast.POSITION.TOP_CENTER,
        });
      /*} else if (!test) {
        toast.warn(
          "Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..)",
          { position: toast.POSITION.TOP_CENTER }
        );*/
      } 
      else {
          var ssid = cookies.get('sid');
          if(!ssid) return;
        axios
          .post("https://flagapi.in/agentapi/changeAgentPassword", {
            sid:ssid,
            agentId:user,
            myPass: password,
            agentPass: Newpassword,
          })
          .then((result) => {
            if (result.status === 200) {
              toast.success("Password Changed Successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              LevelWise();
            
            }
            if (result.status === 206) {
              toast.warn("Your password is wrong", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            
          })
          .catch((e) => {
            //setIsError(true);
          });
      }
    };

    
  

    return (
        <React.Fragment>
           <section id="main" style={{minHeight: "481px"}}>
    {/* <!-- START Bootstrap Navbar --> */}
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>Change Password</h2>
            <ol class="breadcrumb">
                <li>
                    <a >Dashboard</a>
                </li>

                <li>
                    <a >Agents</a>
                </li>

                <li class="active">
                    <strong>{props.user}</strong>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>

    {/* <!--/ END Bootstrap Navbar --> */}
    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>Change Password</h5>
                        <div class="ibox-tools">
                            {/* <!--  <a class="collapse-link">
                                    <i class="fa fa-chevron-up"></i>
                                </a>
                                <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                                    <i class="fa fa-wrench"></i>
                                </a> --> */}
                            <ul class="dropdown-menu dropdown-user">
                                <li><a href="#">Config option 1</a>
                                </li>
                                <li><a href="#">Config option 2</a>
                                </li>
                            </ul>
                            {/* <!--  <a class="close-link">
                                    <i class="fa fa-times"></i>
                                </a> --> */}
                        </div>
                    </div>

                    <div class="ibox-content table-responsive" style={{padding:"15px 20px 56px"}}>


                        <div class="form-horizontal" id="edit_user_4">
                            {/* <!-- <div class="hr-line-dashed"></div> --> */}
                            <div class="form-group"><label class="col-sm-2 control-label">YOUR PASSWORD</label>
                                <div class="col-sm-10">
                                    <input type="password" value={password} onChange={(e) => {setpassword(e.target.value); }} id="new_password" name="new_password" class="form-control login_text_field" placeholder="NEW" password=""/>
                                </div>
                            </div>
                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">NEW PASSWORD</label>
                                <div class="col-sm-10">
                                    <input type="password" value={Newpassword}  onChange={(e) => {setNewpassword(e.target.value); }} id="new_password"
                                     name="new_password" class="form-control login_text_field" placeholder="NEW" password=""/>
                                </div>
                            </div>
                            <input id="selected" name="agent_id" type="hidden" value="18413"/>
                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Confirm Password</label>
                                <div class="col-sm-10">
                                    <input type="password" value={confirmpassword} onChange={(e) => {setconfirmpassword(e.target.value);}}
                                    id="password_confirmation" name="password_confirmation" class="form-control login_text_field" placeholder="Confirm" password=""/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-sm-4 col-sm-offset-2">

                                <Link to="/" class="btn btn-white" >Cancel</Link>
                                <button class="btn btn-primary change_password_btn" type="submit" id="submit create_bets" onClick={(e) => {ChangePassword(e);}} >Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</section> 
        </React.Fragment>
    )
}
