import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {toast} from 'react-toastify'
import UpdateLimitPass from './UpdateLimitPass';

toast.configure()

const cookies = new Cookies();
var globalArray1 = [];


export default function SubcompComm(props) {
    const [user,setuser] = useState([]);
    const [FixLimit,setFixLimit] = useState();
    const [agentView,setagentView] = useState(1);
    const [clientView,setclientView] = useState(1);
    const [updateLimit, setupdateLimit] = useState(false);
    const [a,seta] = useState(1);
    const [b,setb] = useState(1);
    const [refresh,setrefresh] = useState(true);
    const [agentsID, setagentsID] = useState('')
    const [OpenPass, setOpenPass] = useState(false);
    const [pass,setpass] = useState('');
    const [UpdateLimit, setUpdateLimit] = useState(false)


    const SaveUpdateLimit = ()=>{
        setUpdateLimit(!UpdateLimit)
     }
   
    useEffect(()=>{
        if(props.agentStack.length > 1)return;
        globalArray1 = [];
        
        var ssid = cookies.get('sid');
        if(!ssid) return;
        setrefresh(true);
        // if(window.changePage != 1){
        //   setagentView(1);
        //   setclientView(1);
        // }
        axios.post('https://flagapi.in/agentapi/subCompany',{
               sid:ssid
    
              })
              .then(result => {
                  setrefresh(false);
                  if(result.status === 200){
                   
                   
                    for (let key in result.data) { 
                        
                        if (result.data.hasOwnProperty(key)) 
                        { 
                            result.data[key].user = key;
                            globalArray1.push(result.data[key]);
                            
                            
                        } 
                    }
                    let arr = [];
                    globalArray1.map((item)=>{
                        arr.push(item);
                       
                    });
                    // var startCount = 0;
                    
                    // if(window.changePage == 1){
                    //   var startCount = 15*(agentView-1);
                    // }
                    setuser(arr); 
                    // if(window.changePage == 1){
                    //     window.changePage = 2;
                    // }

                  
                  }

                  if(refresh){
                    setrefresh(false);
                  }
         
                 }
                     
                ).catch(e => {
                  //setIsError(true);
              });  
        },[UpdateLimit]);

      
        const UpdateFixlimit=()=>{

            if(pass === '' || pass === ' '){
                toast.warn('Password can not be blank! ', {position:toast.POSITION.TOP_CENTER})
                return;
             }
             else if(isNaN(parseFloat(FixLimit))){
                toast.warn('Please select a valid amount', {position:toast.POSITION.TOP_CENTER})
                props.OpenPassModel(false);
                return;
                
             }
            var ssid = cookies.get('sid');
                axios.post('https://flagapi.in/agentapi/updatefixLimit', {
                sid: ssid,
                fixLimit:FixLimit,
                agentId:agentsID,
                password:pass
                
            }) .then(result => {
                if(result.status === 200) { 
                    setupdateLimit(!updateLimit);
                    toast.success('Fixlimit updated successfully', {position:toast.POSITION.TOP_CENTER});
                    SaveUpdateLimit();
                    props.OpenPassModel(false);
                }  
                else if(result.status === 206){
                toast.warn('Wrong password!', {position:toast.POSITION.TOP_CENTER}) 
                    
               }
                }).
                catch(e => {

                });    
           }



        // useEffect(()=>{
        
        //     let arr = [];
        //     globalArray1.map((item)=>{
        //         arr.push(item);
        //     });
        //     var startCount = 15*(agentView-1);
            
            
        //     setuser(arr.splice(startCount,15)); 
    
    
        //     },[agentView]);
    
                     
    return (
        <React.Fragment>
            { props.OpenPass &&
            <UpdateLimitPass pass={pass} setpass={setpass} OpenPassModel={props.OpenPassModel} UpdateFixlimit={UpdateFixlimit}/>}

       
                 <div class="ibox-content table-responsive">
                        <table class="table table-bordered table-responsive">
                            <thead>
                           
                                <tr>
                                <th style={{textAlign:'center'}}>User Name </th>
                                <th style={{textAlign:'center'}}>Name</th>
                                    <th style={{textAlign:'center'}}>Fix Limit</th>
                                    {/* <th style={{textAlign:'center'}}>Agent Limit</th>
                                     <th style={{textAlign:'center'}}>Client Limit</th> */}
                                    <th style={{textAlign:'center'}}>Used Limit</th>
                                    <th style={{textAlign:'center'}}>Remaining Limit</th>
                                    <th style={{textAlign:'center'}}>Action</th>
                                </tr>
                            </thead>
                            {user.length === 0 &&  <tbody><th colspan="7" style={{textAlign:'center',padding:'15px'}}>There are no Sub Company Commision</th> </tbody>}
                            <tbody>
                            {user.map((item,index)=>{
        var lev;
        var cla;
        if(item.level == 2){
            lev = 'SC';
            cla = '1';
          }
          else if(item.level == 3){
           lev = 'SST';
           cla = '2';
          }
    
         else if(item.level == 4){
           lev = 'SS';
           cla = '3';
          }
          else if(item.level == 5){
            lev = 'SA';
            cla = '4';
           }


          
          
          let b = item.balance ? item.balance : 0;
          let ld = item.ldb ? item.ldb : 0;
          let lw = item.lwc ? item.lwc : 0;
          let le = item.le ? item.le : 0;
          
          var status = 1;
          let expo = item.clientExposure ? item.clientExposure : 0;
          var avail_balance = parseFloat(b)-parseFloat(ld) - parseFloat(lw);
          let cr = avail_balance - parseFloat(item.cf);
          if(item.userBlocked == 0 && item.betBlocked == 0){
              status = 1;
          }
          else if(item.userBlocked == 0 && item.betBlocked == 1){
              status = 2;
          }
          else if(item.userBlocked == 1){
              status = 3;
          }
    
        return(  

                            <tr data-agent-id="1294" id={index}>  
                                    <td ><span className={`lv_${true ? cla:" "}`}>{lev}</span> {item.user}</td>
                                    <td> {item.firstname} </td>
                                    <td style={{paddingTop:'10px'}}><input class="update-agent-info col-xs-6" style={{width:'125px',marginLeft:'20px'}} data-field-name="match_commission"   id="match_commission" name="match_commission" type="text" defaultValue={item.balance} onChange={(e) => setFixLimit(e.target.value)}/></td>
                                    {/* <td style={{paddingTop:'10px'}}><input class="update-agent-info col-xs-6"  style={{width:'125px',marginLeft:'20px'}} data-field-name="session_commission"   id="session_commission" disabled name="session_commission" type="text" value={parseFloat(ld).toFixed(2)}/>
                                    </td>
                                    <td style={{paddingTop:'10px'}}><input class="update-agent-info col-xs-6" style={{width:'125px',marginLeft:'20px'}} data-field-name="session_commission"   id="session_commission" disabled name="session_commission" type="text" value={parseFloat(lw).toFixed(2)}/>
                                    </td> */}
                                    <td style={{paddingTop:'10px'}}>
                                        <input class="update-agent-info col-xs-6 style={{width:'125px',marginLeft:'20px'}} col-coins-limit" data-field-name="fix_limit"  id="fix_limit" name="fix_limit" type="text" disabled value={'('+parseFloat(Math.abs(expo)).toFixed(2)+')'}/>
                                    </td>  
                                    <td style={{paddingTop:'10px'}}>
                                    <input class="col-xs-6 col-coins-limit" id="used_coins" name="used_coins" disabled   type="text"  value={parseFloat(avail_balance).toFixed(2)}/>
                                    </td>  
                                    <td><button type="button" class="btn btn-primary update-agent" style={{lineHeight: '15px',fontSize: '12px'}} id={"done"+index} onClick={() => {props.OpenPassModel(true); setagentsID(item.user);}}>Update</button>
                                    </td>
                                </tr>
                                )})}

                          </tbody>
                      </table>
                  </div>
        </React.Fragment>
    )
}
