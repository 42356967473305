import React,{useEffect, useState} from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment';
import Cookies from 'universal-cookie';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();

export default function ProfitLoss(props) {

  const [Startdate, setStartDate] = useState(moment().toDate());
  const [enddate, setendDate] = useState(moment().toDate());
  const [ShowCreate, setShowCreate] = useState(false);
  const [profitloss, setprofitloss] = useState([]);
  const [eventList,seteventList] = useState([]);
  const [ProfitLoss, setProfitLoss] = useState({});
  
  var sum_A1;

  const showCreateMod =  (open)=>{
      setShowCreate(open)
  }

  const changeStartDate = (val)=>{
      setStartDate(val);
  }
  const changeEndDate = (val)=>{
      setendDate(val);
  }

  useEffect(() => {
    var ssid = cookies.get('sid');
    if(!ssid) return;
    axios.post('https://flagapi.in/agentapi/ProfitLoss',{
          sid:ssid,
          id:props.user
       }).then(result => {
        if(result.status === 200){
            setprofitloss(result.data);
            let arr = [];
            result.data.map((item)=>{
                arr.push(item.eventId);
            })
            seteventList(arr);
        }
 }
).catch(e => {
 //setIsError(true);
 });   
}, [props.user])


useEffect(() => {
    var ssid = cookies.get('sid');
    if(!ssid) return;
    if(eventList.length == 0) return;
    if(props.user == '') return;
    axios.post('https://flagapi.in/agentapi/MatchProfitLoss',{
        sid:ssid,
        eventId: eventList,
        agentId:props.user,

    })
      .then(result => {
         
        if(result.status === 200){
            var obj = {};
            console.log(result.data);
           
            result.data.map(item =>{
                obj[item.eventId] = item.total;
            })
            setProfitLoss(obj);

            
        }
            }      
         ).catch(e => {
             //setIsError(true);
    });
 }, [eventList,props.user])







    return (
        <React.Fragment> 
    <div class="row border-bottom">
</div>  
<div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>EARNING REPORT</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/agent/dashboard">Dashboard</a>
         </li>    
          <li>
            <a href="#">Admin</a>
         </li>
         <li>
            <a href="#"><strong>Profit &amp; Loss</strong></a>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>  




<div class="wrapper wrapper-content animated fadeInRight"> 
            <div class="row">
              <div class="col-lg-12">
                  <div class="ibox float-e-margins" style={{marginBottom:'10px'}}>
                      <div class="ibox-title" style={{padding:'10px 0px 0px'}}>
                          {/* <h5>Summary</h5>                                 */}
                          <ul class="input-list" style={{padding:'0px'}}>
    
            <li></li>
          
            <li class="input-list" style={{display: "inline-flex"}}>
            <DatePicker
                        selectsStart
                        dateFormat="yyyy-MM-dd"
                        selected={Startdate}
                        placeholderText="YYYY-MM-DD"
                        className="cal-input"
                        onChange={changeStartDate}
                    />
                <input id="startTime" disabled="" class="time-input disable" type="text" placeholder="09:00" maxlength="5"/>
                <DatePicker
                        selectsStart
                        dateFormat="yyyy-MM-dd"
                        selected={enddate}
                        placeholderText="YYYY-MM-DD"
                        className="cal-input"
                        onChange={changeEndDate}
                    />
                <input id="endTime" disabled="" class="time-input disable" type="text" placeholder="08:59" maxlength="5"/></li>
           
          
            <li>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{padding:"0px 6px"}}>
                <a class="btn btn-primary btn-xs search_date_btn" style={{lineHeight: '18px',width: '92pxpx',height: '30px',fontSize: "13px"}}>Serach</a></div>
              
              <select name="agent" id="agents" style={{width:'92px',height:'30px'}}>
                  <option value="100">All</option><option value="agdemo">Active</option>
                  <option value="demo22">Suspended</option><option value="mahibook">Locked</option></select></li>
        </ul>
              </div>
          
              </div>
              </div>  
            </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                            <div class="ibox-title">
                                <h5>Earning Report</h5>
                                <div class="ibox-tools">
                       
                            <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                                <i class="fa fa-wrench"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-user">
                                <li><a target="_blank" href="/agent/ledgers/earning_report_pdf.pdf">Export PDF</a>
                                </li>
                            </ul>
                       
                        </div>
                    </div>
                    <div class="ibox-content table-responsive">

                        <table class="table table-bordered table-striped" id="earning-report-pdf" cellspacing="0" width="100%">
                            <thead>
                                <tr>
                                    <th>DATE/TIME</th>
                                    <th>Match Id</th>
                                    <th>Match Title</th>
                                    <th>Match Earnings</th>
                                      <th>Commision Earnings</th>
                                      <th>Charges</th>
                                    <th>Total Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                            {profitloss.length === 0 &&  <th colspan="7" style={{textAlign:'center',padding:'15px'}}>There are no Profit loss</th>}
                            {profitloss.map((item,index)=>{
                                  sum_A1 =  parseFloat(ProfitLoss[item.eventId]).toFixed(2);
                           return(

                                <tr id={index}>
                                    <td>{item.timeCurr}</td>
                                    <td>{item.eventId}</td>
                                    <td>{item.eventName}</td>
                                    <td>{parseFloat(ProfitLoss[item.eventId]).toFixed(2)}</td>
                                    <td>0.00</td>
                                      <td>0.00</td>
                                    <td>00.00</td>
                              </tr>  )})}

                          </tbody>
                          {profitloss.length > 0 &&
                          <tfoot>
                          <tr>
                            <th colspan="3" style={{textAlign: "center"}}> Total</th> 
                            <th>
                            {sum_A1}
                            </th>
                              <th>0</th>
                              <th>0</th>
                            <th>0</th>
                          </tr>
                        </tfoot>}
                      </table>
                      
                  </div>
              </div>
          </div>  
      </div>
  </div>

        </React.Fragment>
    )
}
