import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function SessionBetSlip() {


    const [Declare, setDeclare] = useState([])
    const [Totalbets, setTotalbets] = useState([])
    const [Settlebets, setSettlebets] = useState([])
    const [Fannnccy, setFannnccy] = useState([])

    const url = window.location.href;
    const para = url.split( '/' );
    const eventid = para[4];
    const selectionid = para[5];
 
    useEffect(() => {
    

        var ssid = cookies.get('sid');
        if(!ssid) return;
        if(!eventid) return;

 
        axios.post('https://flagapi.in/agentapi/FancybetList',{
             sid:ssid,
             eventId:eventid,
            //  selectionId:selectionid,
 
         })
         .then(result => {
           
             if(result.status === 200){
         console.log(result.data);
               setDeclare(result.data);    
             }
            }
                
          ).catch(e => {
             //setIsError(true);
         });


         axios.post('https://flagapi.in/agentapi/TotalSessionBets',{
            sid:ssid,
            eventId:eventid,
   
           })
           .then(result => {
             
               if(result.status === 200){
              
              setTotalbets(result.data[0].betTotal)
              setSettlebets(result.data[0].settleBet)  
               }
              }
                  
            ).catch(e => {
               //setIsError(true);
           });
        }, [])


    return (
        <React.Fragment>
            <div class="right_col">
<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>SESSION BET SLIP</h2>
        {/* <ol class="breadcrumb">
            <li>
                <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
                <a href="/agent/matches/">Matches</a>
            </li>
            <li>
                <a href="/agent/matches/33614">Islamabad United vs Quetta Gladiators</a>
            </li>
            <li>
                <a href="#"><strong><strong>Session Bet Slip</strong></strong></a>
            </li>
        </ol> */}
    </div>
    <div class="col-lg-2">
    </div>
</div>

<div class="row">
<div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                
                    <h5>Total Bets</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{Totalbets}</h1>
            
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                  
                    <h5>Settled Bets</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{Settlebets}</h1>
             
                </div>
            </div>
        </div>

    {/* <div class="col-lg-3">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h5>Unsettled Session Bets</h5>
            </div>
            <div class="ibox-content">
                <h1 class="no-margins">0</h1>
            </div>
        </div>
    </div>

    <div class="col-lg-3">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h5>Reverted Session Bets</h5>
            </div>
            <div class="ibox-content">
                <h1 class="no-margins">0</h1>
            </div>
        </div>
    </div> */}
</div>
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5> Session Bet Slip</h5>
                    <div class="ibox-tools">
                    </div>
                </div>


                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr class="tbl-header-middle">
                                <th colspan="8">Bet</th>
                                {/* <th colspan="3">My Share / Position</th> */}
                                <th colspan="2">Settlement</th>
                            </tr>
                        </thead>

                        <thead>
                            <tr>
                                <th>Bet ID</th>
                                <th>DATE</th>
                                <th>User</th>
                                <th>Session Title</th>
                                <th>RATE</th>
                                <th>RUNS</th>
                                <th>AMOUNT</th>
                                <th>MODE</th>
                                {/* <th>No</th>
                                <th>Yes</th>
                                <th>My Share</th>
                                <th>No</th>
                                <th>Yes</th> */}
                                <th>Status</th>
                                <th>Plus / Minus</th>
                            </tr>
                        </thead>
                        <tbody>

                        {Declare.map((item,index)=>{ 

var profit='';
var matchName;
var runner;
var odds;
var matchtype; 

if(item.eventType == '4'){
matchName = 'CRICKET';
}else if(item.eventType == '1'){
matchName = 'SOCCER';
}
else if(item.eventType == '2'){
matchName = 'TENNIS';
}

if(item.betType == 'fancy'){
runner = item.runnerName;
odds = item.rate+'/'+ parseFloat(item.teamName*100).toFixed(0);
}
else if(item.betType == 'match'){
 odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
   if(item.teamName == 'A'){
      runner = item.runnerName1;
   }
   else if(item.teamName == 'B'){
   runner = item.runnerName2;
   }
   else if(item.teamName == 'T'){
   runner = 'The Draw';
}
}

if(item.type === 'LAGAI'){
matchtype = 'BACK';
}
else if(item.type === 'KHAI'){
matchtype = 'LAY';
}
else{
matchtype = item.type;
}

if(item.betType == 'fancy' && item.result){
if(item.type=="YES"){
   if(parseFloat(item.rate) <= parseFloat(item.result)){
   
      profit=parseFloat(item.amount*item.teamName).toFixed(2);
   }
   else profit=item.amount*(-1);

}
else{
   if(parseFloat(item.rate)>parseFloat(item.result)){
      profit=item.amount;
   }
   else profit=parseFloat((item.amount*item.teamName)*(-1)).toFixed(2);
}
}
else if(item.betType=='match' && item.winner){
if(item.type=="LAGAI"){
if(item.teamName=='A'){
   if(item.winner=="A") profit=parseFloat(item.rate*item.amount).toFixed(2);
   else profit=item.amount*(-1);
}
else if(item.teamName=="B"){
   if(item.winner=="B") profit=parseFloat(item.rate*item.amount).toFixed(2);
   else profit=item.amount*(-1);
}
else if(item.teamName=="T"){
   if(item.winner=="T") profit=parseFloat(item.rate*item.amount).toFixed(2);
   else profit=item.amount*(-1);
}
}
else if(item.type=="KHAI"){
if(item.teamName=='A'){
   if(item.winner!="A") profit=item.amount;
   else profit=parseFloat(item.rate*item.amount*-1).toFixed(2);
}
else if(item.teamName=="B"){
   if(item.winner!="B") profit=item.amount;
   else profit=parseFloat(item.rate*item.amount*-1).toFixed(2);
}
else if(item.teamName=="T"){
   if(item.winner!="T") profit=item.amount;
   else profit=parseFloat(item.rate*item.amount*-1).toFixed(2);
}
}

if((item.selectionIdTie== null  || item.selectionIdTie=='') && item.winner=="T"){
profit=0;
}

}

        return(  
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td><i class="fa fa-clock-o"></i>&nbsp;{item.betTime}</td>
                                <td>{item.clientId}</td>
                                <td>{runner}</td>
                                <td>{odds}</td>
                                <td>{item.result}</td>
                                <td>{parseFloat(item.amount).toFixed(2)}
                                </td>
                                <td>{matchtype}</td>
                                {item.status == 1 &&
                                <td>
                                    <span class="label label-primary">Active</span>
                                </td>}
                                {item.status == 2 &&
                                <td>
                                    <span class="label label-primary">Declared</span>
                                </td>}
                                {item.status == 3 &&
                                <td>
                                    <span class="label label-primary">Delete</span>
                                </td>}
                                <td>{profit}
                                </td>
                            </tr>
                             )})}
                        </tbody>
                        {Declare.length === 0 &&  <tbody><th colspan="10" style={{textAlign:'center',padding:'15px'}}>There are no BetSlip</th> </tbody>}
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
        </React.Fragment>
    )
}
