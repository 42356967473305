import React from 'react'
import  './Agent.css'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Combine from './Combine'

export default function App() {
  return (
    <React.Fragment>

<Router>
       <Switch>
        <Route exact path= '/' component={Combine}></Route>
        <Route exact path='/:eventType' component={Combine}></Route>
        <Route exact path='/:eventType/:subType' component={Combine}></Route>
        <Route exact path='/:eventType/:subType/:selection' component={Combine}></Route> 
        <Route exact path='/:eventType/:subType/:selection/:ledgers' component={Combine}></Route> 
        <Route exact path='/:eventType/:subType/:selection/:ledgers/:level' component={Combine}></Route> 
        
       </Switch>
     </Router>
    </React.Fragment>
  )
}
