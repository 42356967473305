import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function ScoreBoard(props) {
    const [scoreId, setscoreId] = useState(null);
    const [Inn1, setInn1] = useState(null);
   
    const [thisOver, setthisOver] = useState([]);
    const [runner1, setrunner1] = useState('');
    const [runner2, setrunner2] = useState('');


    function acronym(text) {
        return text
        .split(/\s/)
        .reduce(function(accumulator, word) {
          return accumulator + word.charAt(0);
        }, '');
       }
  
  
      
  
      useEffect(() => {
  
      
             
          if(!props.eventId){
            
            return;
          } 
           axios.post("http://localhost:3015/clientapi/clientlivestreaming", {
              eventid: props.eventId,
            })
            .then((result) => {
              if (result.status === 210) {
                if(result.data[0].scoreId && result.data[0].scoreId != ''){
                  setscoreId(result.data[0].scoreId);
                   
                }
                if(result.data[0].inn1 && result.data[0].inn1 != ''){
                  setInn1(result.data[0].inn1);
                   
                }
              }
            })
            .catch((e) => {
              //setIsError(true);
            });
  
          axios.post("http://localhost:3015/clientapi/getRunner", {
              eventid: props.eventId,
            })
            .then((result) => {
              if (result.status === 210) {
                  if(result.data[0].runnerName1.split(' ').length > 1){
                      setrunner1(acronym(result.data[0].runnerName1));
                  }
                  else{
                      setrunner1(result.data[0].runnerName1);
                  }
                  if(result.data[0].runnerName2.split(' ').length > 1){
                      setrunner2(acronym(result.data[0].runnerName2));
                  }
                  else{
                      setrunner2(result.data[0].runnerName2); 
                  }
              }
            })
            .catch((e) => {
              //setIsError(true);
            });
         }, [props.eventId,runner1,runner2]);
   
  
        useEffect(() => {
          if(!props.ScoreData || !props.ScoreData.thisOver || props.ScoreData.thisOver == ''){
              return;
          }
          let ov = props.ScoreData.thisOver.split('.');
          setthisOver(ov);
        },[props.ScoreData]);

        

    return (
        <React.Fragment>
             <app-default _nghost-serverapp-c13="">
    <app-match-details _nghost-serverapp-c45="">
        <div _ngcontent-serverapp-c45="" class="bg-match mob-none"></div>
        <div _ngcontent-serverapp-c45="" class="container">
            <div _ngcontent-serverapp-c45="" class="live-score-header mob-none">
                <div _ngcontent-serverapp-c45="" class="live-score-card">
                    <div _ngcontent-serverapp-c45="" class="d-flex">
                        <div _ngcontent-serverapp-c45="" class="team-inning">
                            {/* <div _ngcontent-serverapp-c45="" class="team-img pdl-0"><img _ngcontent-serverapp-c45="" alt="team image" src="https://storage.googleapis.com/teams-bucket/Teams/16.png" class="  ng-lazyloaded"/></div> */}
                            <div _ngcontent-serverapp-c45="" class="team-content">
                                <div _ngcontent-serverapp-c45="" class="team-name team-1  text-center"> {runner1}
                                    <span _ngcontent-serverapp-c45="" class="inning-f"></span></div>
                                <div _ngcontent-serverapp-c45="" class="team-score">
                                    <div _ngcontent-serverapp-c45="" class="runs f-runs"><span _ngcontent-serverapp-c45="">{props.ScoreData.teamA != null  ?  props.ScoreData.teamA != '' ? props.ScoreData.teamA+')':'Yet to bat' : ''}</span>
                                    {/* <span _ngcontent-serverapp-c45="" style={{marginLeft:'8px',fontSize:'14px', color:'hsla(0,0%,100%,.8)'}}>60.0</span> */}
                                    </div>
                                    {/* <div _ngcontent-serverapp-c45="" class="run-rate m-none"><span _ngcontent-serverapp-c45="" class="title">CRR : <span _ngcontent-serverapp-c45="" class="data">2.74</span></span>
                          
                                    </div> */}
                                </div>
                              
                            </div>
                        </div>
                        <div _ngcontent-serverapp-c45="" class="team-result">
                            {/* <div _ngcontent-serverapp-c45="" class="triangle-arrow"></div> */}
                            <div _ngcontent-serverapp-c45="" class="result-box" style={{fontWeight:700,color:'#edf149',fontSize:'16px'}}><span _ngcontent-serverapp-c45="">{props.ScoreData.ballStatus}</span>
                            </div>
                            {/* <div _ngcontent-serverapp-c45="" class="final-result m-none" style={{color:'#cfd1d3'}}> INDW trail by 333 runs </div> */}
                            <div _ngcontent-serverapp-c45="" class="final-result blinking2 m-none"> </div>
                        </div>
                        <div _ngcontent-serverapp-c45="" class="team-inning second-inning">
                            <div _ngcontent-serverapp-c45="" class="team-content second-team">
                                <div _ngcontent-serverapp-c45="" class="team-name team-2 text-center"><span _ngcontent-serverapp-c45="" class="inning-f"></span>
                                {runner2} </div>
                                {/* <div _ngcontent-serverapp-c45="" class="team-img team-2-img"><img _ngcontent-serverapp-c45="" alt="team image" src="../../../assets/img/Team_Flags.png" class=" ng-lazyloading"/></div> */}
                                <div _ngcontent-serverapp-c45="" class="team-score text-center">
                                    <div _ngcontent-serverapp-c45="" class="runs second-innings"><span _ngcontent-serverapp-c45="">{props.ScoreData.teamB != null  ?  props.ScoreData.teamB != '' ? props.ScoreData.teamB+')':'Yet to bat' : ''}</span></div>

                              
                                    {/* <div _ngcontent-serverapp-c45="" class="run-rate team-2-run-rate"><span _ngcontent-serverapp-c45="" class="title">CRR : <span _ngcontent-serverapp-c45="" class="data">2.74</span></span>
                                    </div> */}
                                </div>
                            </div>
                            
                            <div _ngcontent-serverapp-c45="" class="final-result blinking2 des-none"> </div>
                            {/* <div _ngcontent-serverapp-c45="" class="team-img m-none"><img _ngcontent-serverapp-c45="" alt="team image" src="https://storage.googleapis.com/teams-bucket/Teams/1L.png" class="  ng-lazyloaded"/></div> */}
                        </div>
                    </div>
                </div>
                <hr _ngcontent-serverapp-c45="" style={{borderTop: '1px solid rgba(159, 163, 167, 0.1)'}}/>
                {/* <div _ngcontent-serverapp-c45="" class="ml-2 tabs">
                    <ul _ngcontent-serverapp-c45="" class="nav">
                        <li _ngcontent-serverapp-c45="" routerlinkactive="active" class="nav-item"><a _ngcontent-serverapp-c45="" routerlink="info" class="nav-link" href="/scoreboard/9KT/LP/Only/1L/16/engw-vs-indw-only-india-women-tour-of-england-2021/info"> Match info</a></li>
                        <li _ngcontent-serverapp-c45="" routerlinkactive="active"
                            class="nav-item active"><a _ngcontent-serverapp-c45="" routerlink="live" class="nav-link" href="/scoreboard/9KT/LP/Only/1L/16/engw-vs-indw-only-india-women-tour-of-england-2021/live">Live</a></li>
                        <li _ngcontent-serverapp-c45="" routerlinkactive="active"
                            class="nav-item"><a _ngcontent-serverapp-c45="" routerlink="scorecard" class="nav-link" href="/scoreboard/9KT/LP/Only/1L/16/engw-vs-indw-only-india-women-tour-of-england-2021/scorecard">Scorecard</a></li>
                    </ul>
                </div> */}

<div _ngcontent-serverapp-c44="" class="container">
    
    
    <div _ngcontent-serverapp-c44="" class="overs des-none">
        <div _ngcontent-serverapp-c44="" class="heading m-none">Overs Timeline</div>
        <div _ngcontent-serverapp-c44="" id="slideOver" class="overs-timeline" >
            <div _ngcontent-serverapp-c44="" class="overs-slide">
                <div _ngcontent-serverapp-c44="" class="content" style={{display:'flex', justifyContent:'space-around'}}>
                    <h6 _ngcontent-serverapp-c44="" style={{color:'#cfd1d3',float:'left'}}>this Over:</h6>
                    {thisOver.map((item,id)=>{
                            return(
                               <div key = {id} class="thisoverrunmob">{item}</div>
                            )}
                           
                            )}
                </div>
            </div>
         
       
            
        </div>
    </div>



    <div _ngcontent-serverapp-c44="" class="live-data mob-none">
    <div _ngcontent-serverapp-c44="" class="overs mt-2 row mob-none">
        <div _ngcontent-serverapp-c44="" class="heading m-none">Overs Timeline</div>
        <div _ngcontent-serverapp-c44="" id="slider" class="overs-timeline" style={{justifyContent:'center'}}>
            <div _ngcontent-serverapp-c44="" class="overs-slide">
                <div _ngcontent-serverapp-c44="" class="content">
                    <h6 _ngcontent-serverapp-c44="">This Over:</h6>
                    {thisOver.map((item,id)=>{
                            return(
                               <div key = {id} class="thisoverrunmob">{item}</div>
                            )}
                           
                            )}
                </div>
            </div>

        </div>
    </div>
</div>
    
    
</div>
            </div>
        </div>
    </app-match-details>
</app-default>
        </React.Fragment>
    )
}
