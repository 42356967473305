import axios from 'axios'
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import moment from 'moment'

const cookies = new Cookies();


export default function Header(props) {
    const [Inplay, setInplay] = useState([]);


    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
    
        axios.post('https://flagapi.in/agentapi/liveMatchList',{
            sid:ssid,
            eventType:4
           })
           .then(result => {
               if(result.status === 200){
                result.data.map(item=>{
                    item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                  }) 
    
                  result.data.sort(function (a, b) {
                    return moment(a.marketStartTime) - moment(b.marketStartTime);
                  });
    
                  var obj = [];
                  result.data.map((item)=>{
                      if(moment() >= moment(item.marketStartTime)){
                        obj.push(item);
                      }
                     
                      
                  });
                  setInplay(obj);
            //  console.log(result);
            //     setInplay(result.data);    
               }
              } 
            ).catch(e => {
               //setIsError(true);
           }); 
    }, [])
    

    return (
        <React.Fragment>
                   <div class="row border-bottom">
            <nav class="navbar navbar-static-top" role="navigation" style={{marginBottom: "0"}}>
                <div class="navbar-header" onClick={()=>{props.dsClick()}}>
                    <a class="navbar-minimalize minimalize-styl-2 btn btn-primary"  style={{backgroundColor:'rgb(31 171 181)',borderColor:'rgb(31 171 181)',margin:'10px 5px 5px 8px',fontSize:'20px'}} >
                     {props.isActive === false &&
                        <i class="fa fa-bars"></i>
                        }
                        { props.isActive === true &&
                        <i class="fa fa-chevron-left"></i>}
                        </a>
                </div>
                <ul class="nav navbar-top-links navbar-right">

                    <li style={{float:'right'}}>  
                        <Link to="/Matches" class="text" data-method="delete" rel="nofollow" style={{marginTop:'8px',borderRadius:'5px', boxShadow:'inset 0 0.26667vw 0 0 rgb(31 171 181)', backgroundColor:'rgb(31 171 181)',display:'flex',height:'10px'}}>
                        {Inplay.length > 0  && 
                <span id="tagLive" class="tag-live" ><strong></strong></span>}
                {Inplay.length === 0  && <span> No <span style={{padding:'3px'}}>  </span> </span> } 
                             Live</Link>
                    </li>
                
                </ul>

            </nav>
        </div> 
        </React.Fragment>
    )
}
