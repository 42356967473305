import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Pagination from 'react-js-pagination';


const cookies = new Cookies();

var limit = [];

export default function CoinHistory(props) {

    const [allLog, setallLog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const [refresh,setrefresh] = useState(true);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = allLog.slice(indexOfFirstPost, indexOfLastPost);


    const url = window.location.href;
    const para = url.split( '/' );
    const le = para[4];
    const user = para[5];
   


        useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        axios.post('https://flagapi.in/agentapi/clientTransHistory',{
			  sid:ssid,
			  agentId:user
           }).then(result => {
            //setrefresh(false);   
           

            for(let i=0;i<result.data.length;i++){
                if(result.data[i]['fixLimit']!=0 && result.data[i]['fixLimit']!=""){
                   limit[i]=parseFloat(result.data[i]['fixLimit']);
                }
                else {
                   limit[i]=limit[i-1]+parseFloat(result.data[i]['coin']);
                }
              }


            
              axios.post('https://flagapi.in/agentapi/clientTransHistory1',{
                sid:ssid,
                agentId:user
             }).then(result1 => {
              setrefresh(false);   
             
  
             
              
            
              setallLog(result1.data);      
       }
      ).catch(e => {
       //setIsError(true);
       });   

            
          
           
     }
    ).catch(e => {
     //setIsError(true);
     });   
    }, [])


   const handlePageChange = ( pageNumber ) => {
      setCurrentPage( pageNumber )
    };

    return (
        <React.Fragment>
            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>{user}</h5>
      
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>

                            <tr>
                                <th>Changelog ID</th>
                                <th>Description</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Curr Limit</th>
                                <th>Created At</th>
                             
                            </tr>

                        </thead>
                        <tbody>

                        {allLog.map((item,index)=>{
                        var des;

                        if(item.type == 'coin'){
                            if(item.coin >= 0) des = "Coin Received from agent";
                            else des = "Coin Withdrawn by agent"; 
                        }
                        else des = "Coins after match declaration";

                        var obj1
                        var obj2;
                        if(item.coin >= 0){
                         obj2 = item.coin;
                        }
                        else{
                         obj2 = '-';
                        }

                        if(item.coin < 0){
                         obj1 = item.coin*-1;
                         
                        }
                        else{
                         obj1 = '-';  
                        }
                        var obj3 = item.balance;
                        
                        return(
                            <tr key = {index}>
                                <td>{item.id}</td>
                                <td>{des}</td>
                                <td>{obj1}</td>
                                <td>{obj2}</td>
                                <td>{(limit[allLog.length-index-1]).toFixed(2)}</td>
                                <td>{item.timeCurr}</td>
                               
                            </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
                {allLog.length > 0 &&  <div className="pages">
    <Pagination
        prevPageText='prev'
        pageRangeDisplayed={4}
        activePage={currentPage}
        nextPageText='next'
        totalItemsCount={allLog.length}
        onChange={handlePageChange}
        itemsCountPerPage={postsPerPage}
        hideFirstLastPages
      />
     </div>}
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
