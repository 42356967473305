import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {toast} from 'react-toastify'
import { Link } from 'react-router-dom';


const cookies = new Cookies();
toast.configure()

export default function CreateAgent(props) {

    const [firstname,setfirstname] = useState('');
    const [lastname,setlastname] = useState('');
    const [AgentShare, setAgentShare] = useState('');
    const [MyShare, setMyShare] = useState('');
    const [MatchComm, setMatchComm] = useState('0');
    const [SessionComm, setSessionComm] = useState('0');
    const [RollingComm, setRollingComm] = useState('0');
    const [level, setlevel] = useState('')
   
    
   const url = window.location.href;
   const para = url.split( '/' );
   const user = para[4];

   
   var ShareS =props.share;
   var matchCommv =props.matchcomm;
   var sessionCommv = props.sessioncomm;
   var rollingcommv = props.rollingcomm;

   const ChangeShare = (val)=>{
    if(parseFloat(val) > parseFloat(props.share)){
        setMyShare(parseInt(props.share));
        document.getElementById("share_Agent").innerHTML="My Share should be set below or equal to" +" "+ ShareS ;
      }
      else {
        setMyShare(val);
      }
    //setMyShare(val);
    let agentS = parseFloat(props.share) - parseFloat(val);
    if(agentS > 0){
      setAgentShare(parseInt(agentS));
    }
    else {
        setAgentShare(0);
    }
}

const ChangeSessionComm = (val)=>{
    if(parseFloat(val) > parseFloat(props.sessioncomm)){
        setSessionComm(parseInt(props.sessioncomm));
        document.getElementById("SessionCommm").innerHTML="My Commission should be set below or equal to" +" "+ sessionCommv ;
      }
      else {
        setSessionComm(val);
      }
}
const changeRollingComm = (val)=>{
    if(parseFloat(val) > parseFloat(props.rollingcomm)){
        setRollingComm(parseInt(props.rollingcomm));
        document.getElementById("rolingCommm").innerHTML="My Commission should be set below or equal to" +" "+ rollingcommv ;
      }
      else {
        setRollingComm(val);
      }
}   
const ChangeMatchComm = (val)=>{
    if(parseFloat(val) > parseFloat(props.matchcomm)){
        setMatchComm(parseInt(props.matchcomm));
        document.getElementById("MatchCommm").innerHTML="My Commission should be set below or equal to" +" "+ matchCommv ;
      }
      else {
        setMatchComm(val);
      }
}
    
    // const [comm,setcomm] = useState('');

    // useEffect(()=>{
        
    //     var ssid = cookies.get('sid');
    //     if(!ssid) return;
    //     axios.post('https://flagapi.in/agentapi/myComm',{
    //            sid:ssid
    
    //           })
    //           .then(result => {
                 
    //               if(result.status === 200){
    //                  setcomm(result.data);
    //                 console.log(result.data[0].comm);
                   
    //               }
         
    //              }
                     
    //             ).catch(e => {
    //               //setIsError(true);
    //       });
    //     },[]);


  
    useEffect(() => {
        var ssid = cookies.get('sid');
      if(!ssid) return;
        axios.post('https://flagapi.in/agentapi/SelectAgent',{
            sid:ssid,
            agentId:user
 
           })
           .then(result => {
              
               if(result.status === 200){
            
                setfirstname(result.data[0].first_name);
                setlastname(result.data[0].last_name);
                setMyShare(result.data[0].myShare);
                setAgentShare(result.data[0].share);
                setMatchComm(result.data[0].match_commission); 
                setSessionComm(result.data[0].session_commission); 
                setRollingComm(result.data[0].transaction_commission);
                setlevel(result.data[0].level);
                }
              }
                  
             ).catch(e => {
               //setIsError(true);
       });
    }, [])


        // const validateUser = ()=>{

        //   if(username === ''  || username === ' '){
        //      document.getElementById("userNameErrorText").style.color = "#D0021B";
        //      document.getElementById("userNameErrorText").innerHTML="Username can not be blank!";
        //      return;
        //   }
        //   var ssid = cookies.get('sid');
        //   if(!ssid) return;
        //   axios.post('https://flagapi.in/agentapi/NewvalidateAgent',{
        //          sid:ssid,
        //          agentId:username
      
        //         })
        //         .then(result => {
                   
        //             if(result.status === 200){
        //               setusernameAvail(false);  
        //               document.getElementById("userNameErrorText").style.color = "#D0021B";
        //               document.getElementById("userNameErrorText").innerHTML="Username not available!";
                       
        //              }
        //              else if(result.status === 206){
        //               setusernameAvail(true);   
        //               document.getElementById("userNameErrorText").style.color = "green";
        //               document.getElementById("userNameErrorText").innerHTML="Username available!";

        //             } 
           
        //            }
                       
        //           ).catch(e => {
                   
        //     });
        //   }   

        const LevelWise = ()=>{

            if(level==2){
                window.location.href = '/master';
               
            }else  if(level==3){
                window.location.href = '/superStockList';
               
            }
            else  if(level==4){
                window.location.href = '/stockList';
               
            }
            else  if(level==5){
                window.location.href = '/Agent';
               
            }      
        }

 
          
          const validatematchcomm = ()=>{
            if(MatchComm > props.matchcomm){
                document.getElementById("MatchCommm").innerHTML="My Commission should be set below or equal to"  +" "+ matchCommv;
            } else{
                document.getElementById("MatchCommm").innerHTML=""; 
            }
            }
            
            const validatsessioncomm = ()=>{
                if(SessionComm > props.sessioncomm){
                    document.getElementById("SessionCommm").innerHTML="My Commission should be set below or equal to"  +" "+ sessionCommv;
                } else{
                    document.getElementById("SessionCommm").innerHTML=""; 
                }
                }
            
            const validaterollingcomm = ()=>{
                if(RollingComm > props.rollingcomm){
                    document.getElementById("rolingCommm").innerHTML="My Commission should be set below or equal to"  +" "+ rollingcommv;
                } else{
                    document.getElementById("rolingCommm").innerHTML=""; 
                }
                }
            
      
  
      const validateShare = ()=>{
        if(MyShare === '' || MyShare === ' '){
            document.getElementById("share_Agent").innerHTML="Share can not be blank!";
        }
        else{
            document.getElementById("share_Agent").innerHTML=""; 
        }
    }

//   const validateFirst = ()=>{
//       if(firstname === '' || firstname === ' '){
//           document.getElementById("firstErrorText").innerHTML="firstname can not be blank!";
//       }
//       else{
//           document.getElementById("firstErrorText").innerHTML=""; 
//       }
//   }
 
    const addAgent = ()=>{

    //   if(username === ''  || username === ' '){
    //     validateUser(); 
    //   }

     if(
      MyShare === '' || MyShare === ' '){
    
     
      validatematchcomm();
      validatsessioncomm();
      validaterollingcomm();
      
    //     validateFirst();
    //     validateShare();
       
        return;
     }
        
        var ssid = cookies.get('sid');
        if(!ssid) return;
       
        axios.post('https://flagapi.in/agentapi/EditAgent',{
               sid:ssid,
               firstname:firstname,
               lastname:lastname,
               share:AgentShare,
               myShare:MyShare,
               matchComm:MatchComm,
               sessionComm:SessionComm,
               rollingComm:RollingComm,
               agentId:user
              })
              .then(result => {
                  if(result.status === 200){
                    toast.success('Agent edit successfully!', {position:toast.POSITION.TOP_CENTER});
                 LevelWise();
                
                  }
                
                else{
                   toast.warn('Something went wrong!', {position:toast.POSITION.TOP_CENTER})
                }
             }
                     
         ).catch(e => {
                  //setIsError(true);
     });
  }
            





    return (
        <React.Fragment>
            <section id="main" style={{minHeight: "481px"}}>

    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>New Agent</h2>
            <ol class="breadcrumb">
                <li>
                    <a href="/agent/dashboard">Dashboard</a>
                </li>
                <li>
                    <a href="/agent/agents">Agents</a>
                </li>
                <li class="active">
                    <strong>Edit Agent</strong>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>

    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>New Agent</h5>
                        <div class="ibox-tools">
                      
                            <ul class="dropdown-menu dropdown-user">
                                <li><a href="#">Config option 1</a>
                                </li>
                                <li><a href="#">Config option 2</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="ibox-content table-responsive">
                        <div accept-charset="UTF-8" action="/agent/agents" class="form-horizontal" id="agent_form" method="post">
                            <div style={{display:"none"}}><input name="utf8" type="hidden" value="✓"/><input name="authenticity_token" type="hidden" value="Eobi5cTkiTqewM/20L+5UwNQmRI82Kqx3WmsGisnl5A="/></div>
                          
                            {/* <div class="form-group"><label class="col-sm-2 control-label">User Level</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="User Name" readonly="readonly" type="text" value={Level} onChange = {(e)=>{setlevel(e.target.value);}}/>
                                </div>
                            </div> */}
                            {/* <div class="hr-line-dashed"></div>  */}
                            <div class="form-group"><label class="col-sm-2 control-label">User Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="User Name"  type="text"
                                 disabled   value={user}/>
                                 <span id="userNameErrorText"  class="error-text"></span>
                                </div>
                               
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>
                            {/* <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Password</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="Password"  type="password"
                                     onChange = {(e)=>{setpass(e.target.value);}}  onBlur={()=>{validatePass();}} value={pass}/>
                               <span id="passwordErrorText" class="error-text"></span>
                                </div>
                                
                            </div> */}
                            {/* <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Confirm Password</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="Password"  type="password"
                                     onChange = {(e)=>{setconfirmpass(e.target.value);}} onBlur={()=>{confirmPassword();}} value={confirmpass}/>
                                 <span id="repeatPasswordErrorText" class="error-text"></span>
                                </div>
                               
                            </div> */}
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>


                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">First Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" onChange = {(e)=>{setfirstname(e.target.value);}} defaultValue={firstname} id="agent_first_name" name="agent[first_name]" placeholder="First Name" type="text"/>
                                    <span id="firstErrorText" class="error-text"></span>
                                </div>
                                
                            </div>

                            <div class="hr-line-dashed"></div>


                            <div class="form-group"><label class="col-sm-2 control-label">Last Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" onChange = {(e)=>{setlastname(e.target.value);}} defaultValue={lastname}  id="agent_last_name" name="agent[last_name]" placeholder="Last Name" type="text"/>
                                </div>
                            </div>
                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">My Share</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_share" max="100.00" min="0.00" name="agent[agent_share]" placeholder="Agent Share" required="required"  type="number" value={MyShare}  onBlur={()=>{validateShare();}}  onChange = {(e)=>{ChangeShare(e.target.value);}}/>
                                    <text class="agent_share_error"><b>Note</b> : Max Agent Share + My Share should be set below or equal to <b>{props.share}</b></text>
                                   <br/> <span id="share_Agent" class="error-text"></span>
                                </div>
                            </div>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Agent Share</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_share" max="100.00" min="0.00" name="agent[agent_share]" placeholder="Agent Share" required="required"  type="number" value={AgentShare} />
                                    <text class="agent_share_error"><b>Note</b> : Max Agent Share + My Share should be set below or equal to <b>{props.share}</b></text>
                                </div>
                            </div>

                            {/* <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Agent Fix Limit</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_fix_limit" min="0.00" name="agent[fix_limit]" placeholder="Agent Fix Limit" required="required" type="number" value={AgentFixLimt} onChange = {(e)=>{setAgentFixLimit(e.target.value);}}/>
                                    <text class="agent_fix_limit_error"><b>Note</b> : Agent Fix Limit set below or equal to <b>{(parseFloat(props.balance) - parseFloat(props.ldb) - parseFloat(props.lwc) - parseFloat(props.le) - AgentFixLimt).toFixed(2)}</b></text>
                                </div>
                            </div> */}
                          
                          { parseFloat(props.matchcomm).toFixed(2) > 0 &&
                        <React.Fragment>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Agent Match Commission</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_match_comission" max="100.00" min="0.00" name="agent[match_commission]" placeholder="Agent Match Commission" required="required" type="text"  onBlur={()=>{validatematchcomm();}} value={MatchComm} onChange = {(e)=>{ChangeMatchComm(e.target.value);}}/>
                                    <text class="commi_error_match"><b>Note</b> : Agent Match Commission set below or equal to <b>{parseFloat(props.matchcomm).toFixed(2)}</b></text>
                                    <br/> <span id="MatchCommm" class="error-text"></span>
                                </div>
                            </div>
                            </React.Fragment>}
                            { parseFloat(props.sessioncomm).toFixed(2) > 0 &&
                        <React.Fragment>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Agent Session Commission</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_session_comission" max="100.00" min="0.00" name="agent[session_commission]" placeholder="Agent Session Commission" required="required" type="text" value={SessionComm} onBlur={()=>{validatsessioncomm();}} onChange = {(e)=>{ChangeSessionComm(e.target.value);}}/>
                                    <text class="commi_error_session"><b>Note</b> : Agent Session Commission set below or equal to <b>{parseFloat(props.sessioncomm).toFixed(2)}</b></text>
                                    <br/> <span id="SessionCommm" class="error-text"></span>
                               </div>
                            </div>
                            </React.Fragment>}

                            { parseFloat(props.rollingcomm).toFixed(2) > 0 &&
                        <React.Fragment>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Agent Rolling Commission</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_session_comission" max="100.00" min="0.00" name="agent[session_commission]" placeholder="Agent Session Commission" required="required" type="text" value={RollingComm} onBlur={()=>{validaterollingcomm();}} onChange = {(e)=>{changeRollingComm(e.target.value);}}/>
                                    <text class="commi_error_session"><b>Note</b> : Agent Session Commission set below or equal to <b>{parseFloat(props.rollingcomm).toFixed(2)}</b></text>
                                   <br/> <span id="rolingCommm" class="error-text"></span>
                                </div>
                            </div>
                            </React.Fragment>}
                          
                          
                            <div class="hr-line-dashed"></div>

                            <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">

                                    <Link class="btn btn-white" to="/superStockList">Cancel</Link>
                                    <button class="btn btn-primary" type="submit" id="submit" onClick = {()=>{addAgent();}}>Save Changes</button>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    )
  }