import React, { useState } from 'react'
import AgentComm from './AgentComm';
import ClientComm from './ClientComm';
import StockistComm from './StockistComm';
import SubcompComm from './SubcompComm';
import SuperStockComm from './SuperStockComm';

export default function CommiNLimit(props) {
    const [Select, setSelect] = useState(5);
    const [OpenPass, setOpenPass] = useState(false);
  
	const OpenPassModel = (Open)=>{
		setOpenPass(Open)
	}
 
    
    return (
        <React.Fragment>

<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>Agent</h2>
        <ol class="breadcrumb">
            <li>
                <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
                <a href="#"><strong>Commission &amp; Limits</strong></a>
            </li>
                        {/* <!-- <li class="active">
                            <strong></strong>
                        </li> --> */}
                    </ol>
                </div>
                <div class="col-lg-2">

                </div>
            </div>
            <div  className={`${(OpenPass)? "select": "wrapper wrapper-content animated fadeInRight"}`}> 
{/* <!--- agents --> */}
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                            <div class="ibox-content table-responsive" style={{padding:'10px 20px 10px'}}>
                            {props.userLevel < 2 &&  <button type="button"  onClick ={()=>{setSelect(5)}} className={`btn btn-primary ${(Select=== 5 )? "select": "null"}`}  style={Select=== 5 ? {backgroundColor:'#18a689',borderColor:'#18a689',width:'55px',marginLeft:'20px'}:{width:'55px',marginLeft:'20px'}}>SC</button>}
                            {props.userLevel < 3 &&  <button type="button"  onClick ={()=>{setSelect(2)}} className={`btn btn-primary ${(Select=== 2 )? "select": "null"}`}  style={Select=== 2 ? {backgroundColor:'#18a689',borderColor:'#18a689',width:'55px'}:{width:'55px'}}>SST</button>}
                            {props.userLevel < 4 && <button type="button"  onClick ={()=>{setSelect(3)}} className={`btn btn-primary ${(Select=== 3 )? "select": "null"}`}  style={Select=== 3 ? {backgroundColor:'#18a689',borderColor:'#18a689',width:'55px'}:{width:'55px'}}>SS</button>}
                            {props.userLevel < 5 &&   <button type="button" onClick ={()=>{setSelect(4)}} className={`btn btn-primary  ${(Select=== 4 )? "select": "null"}`}  style={Select=== 4 ? {backgroundColor:'#18a689',borderColor:'#18a689',width:'55px'}:{width:'55px'}}>SA</button>}
                            {props.userLevel < 6 &&   <button type="button" onClick ={()=>{setSelect(6)}} className={`btn btn-primary  ${(Select=== 6 )? "select": "null"}`}  style={Select=== 6 ? {backgroundColor:'#18a689',borderColor:'#18a689',width:'55px'}:{width:'55px'}}>SP</button>}
                            {/* {props.userLevel >= 5 &&   <button type="button" onClick ={()=>{setSelect(5)}} className={`btn btn-primary  ${(Select=== 5 )? "select": "null"}`}  style={Select=== 5 ? {backgroundColor:'#18a689',borderColor:'#18a689'}:{}}>Client</button>} */}
                                <div class="ibox-tools">
                                    {/* <!-- <a href="" class="btn btn-primary btn-xs" >Create new User</a> --> */}
                        </div>
                    </div>

                             
                    {Select=== 5 && props.userLevel < 2 &&
               <SubcompComm  OpenPass={OpenPass} OpenPassModel={OpenPassModel} user={props.user} agentStack={props.agentStack}/>
                        }
                             
                             
                                {/* {supper stocklist} */}
                     
                                { Select=== 2 &&
                          <SuperStockComm  OpenPass={OpenPass} OpenPassModel={OpenPassModel} user={props.user} agentStack={props.agentStack}/>
                                 }
                    {/* {stocklist} */}
                    {Select=== 3 &&
               <StockistComm  OpenPass={OpenPass} OpenPassModel={OpenPassModel} user={props.user} agentStack={props.agentStack}/>
    }
 
                          {/* {agentlist} */}
                          {Select=== 4 &&
                       <AgentComm  OpenPass={OpenPass} OpenPassModel={OpenPassModel} user={props.user} agentStack={props.agentStack}/>
                       }

                          {/* {client} */}
                          { props.userLevel <= 5 && Select === 6 &&
                            <ClientComm OpenPass={OpenPass} OpenPassModel={OpenPassModel}  user = {props.user} balance={props.balance} checkUpdateBalance = {props.checkUpdateBalance}/>
                            }
              </div>
          </div>          
      </div>
      <div class="row">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h5>Summary</h5>
                <div class="ibox-tools">
                </div>
            </div>
            <div class="ibox-content table-responsive">
                <table class="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th>Fix Limit</th>
                            <th>Limit Distributed Below</th>
                            <th>Limit With Clients</th>
                            
                            <th>Ledger Exposure</th>
                            <th>Current Limit</th>
                            <th>Remaining Limit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{parseFloat(props.balance).toFixed(2)}</td>
                            <td>{parseFloat(props.ldb).toFixed(2)}</td>
                            <td>{parseFloat(props.lwc).toFixed(2)}</td>
                            <td>{parseFloat(props.le*-1).toFixed(2)} </td>
                            <td>{(parseFloat(props.balance) - parseFloat(props.le)).toFixed(2)}</td>
                            <td>{(parseFloat(props.balance) -parseFloat(props.ldb) - parseFloat(props.lwc) - parseFloat(props.le)).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

  </div>
        </React.Fragment>
    )
}
