import React from 'react'
import { Link } from 'react-router-dom'

export default function Myclient(props) {
    return (
        <React.Fragment>


   
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>Clients</h2>
            <ol class="breadcrumb">
                <li>
                    <a href="/agent/dashboard">Dashboard</a>
                </li>
                <li>
                    <strong>Clients</strong>
                </li>
        
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>
    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        
                        <div class="ibox-tools">
                        <select class="" name="accountStatus" id="accountStatus" style={{float:'left', margin:'0px 10px'}}>
                    
                    <option value="0">ACTIVE
                    </option>
                    
                    <option value="1">SUSPENDED
                    </option>
                    
                    <option value="2">LOCKED
                    </option>
                    
                    <option value="-1">ALL</option>
                </select>
                            <a href="/agent/agents/new?role=super_stockist" class="btn btn-primary btn-xs">Create Clients</a>
                        </div>
                    </div>
                    <div class="ibox-content table-responsive">

                        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper form-inline dt-bootstrap no-footer">
                            <div class="dt-buttons"><a class="dt-button buttons-csv buttons-html5" tabindex="0" aria-controls="DataTables_Table_0" href="#"><span>CSV</span></a><a class="dt-button buttons-pdf buttons-html5" tabindex="0" aria-controls="DataTables_Table_0" href="#"><span>PDF</span></a></div>
                            <div
                                id="DataTables_Table_0_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control input-sm" placeholder="" aria-controls="DataTables_Table_0"/></label></div>
                       
                       
                       
                       
                       
                       
                       
                       
                       <table class="table table-bordered table-striped dataTables-example dataTable no-footer" id="DataTables_Table_0"
                            role="grid" aria-describedby="DataTables_Table_0_info">
                             <thead>
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="User Name: activate to sort column ascending" style={{width: 0}}>User Name</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: 0}}>Name</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Fix Limit: activate to sort column ascending" style={{width: 0}}>Fix Limit</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="My Share: activate to sort column ascending" style={{width: 0}}>Current Limit</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Max Share: activate to sort column ascending" style={{width: 0}}>Used Limit</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Max Share: activate to sort column ascending" style={{width: 0}}>Status</th> 
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" style={{width: "30%", textAlign:'center'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr role="row" class="odd">
                            <td class="agent_name"><Link to="/AgentDash" class="ico_account"  style={{display:'flex'}}> Demo</Link></td>
                            <td>Demo </td>
                            <td>20000000.0</td>
                            <td>6784485</td>
                            <td>2342358</td>
                            <td id="statusCol"><span id="status14" class="status-active"><img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Active</span></td>
                            <td class="" style={{display:'flex',padding:'5px, 10px'}}>
                    
                                        <a class="btn btn-white btn-sm" onClick={()=>{props.handleOpen2(true)}}><i class="fa fa-cog"></i> status</a>

                                        <Link to="/ChangepassAgent"><button type="button" class="btn btn-w-m btn-primary">Change Password</button></Link>
                                    </td>
                                </tr>

                                <tr role="row" class="even">       
                                    <td class="agent_name"><Link to="/AgentDash" class="ico_account" style={{display:'flex'}}> Demo</Link></td>
                                    <td>Demo </td>
                                    <td>1000000.0</td>
                                    <td>354745483</td>
                                    <td>234743580</td>
                                    <td id="statusCol"><span id="status14" class="status-active"><img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Active</span></td>
                                    <td class="" style={{display:'flex',padding:'5px, 10px'}}>
                    
                                        <a class="btn btn-white btn-sm" onClick={()=>{props.handleOpen2(true)}}><i class="fa fa-cog"></i> status</a>

                                        <Link to="/ChangepassAgent"><button type="button" class="btn btn-w-m btn-primary">Change Password</button></Link>
                                    </td>

                                </tr>
                        
                            </tbody>
                        </table>
                        <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div>
                        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                            <ul class="pagination">
                                <li class="paginate_button previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0">Previous</a></li>
                                <li class="paginate_button active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0">1</a></li>
                                <li class="paginate_button "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0">2</a></li>
                                <li class="paginate_button "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0">3</a></li>
                                <li class="paginate_button next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="4" tabindex="0">Next</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

        </React.Fragment>
    )
}
