import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
require('moment-timezone');

export default function SoccerList() {
    const [match, setMatch] = useState([]);

    useEffect(() => {
   
        Axios.get(
          `https://flagapi.in/agentapi/soccer`)
          
          .then(result => {
           
          
            setMatch(result.data); 
         
            }) 
          .catch(error => console.log(error));
      },[]);

    return (
        <React.Fragment>  
        <div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
  <h2>Matches</h2>
  <ol class="breadcrumb">
     <li>
        <a href="/agent/dashboard">Dashboard</a>
     </li>
     <li>
        <a href="/liveMatches"><strong>Manage Matches</strong></a>
     </li>
  </ol>
  </div>
  <div class="col-lg-2">
  </div>
  </div>
        <div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
        <div class="ibox float-e-margins">
            <div class="ibox-title">
                <h5>Cricket</h5>
              
                <div class="ibox-tools">
                </div>
            </div>
            <div class="ibox-content table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                        <th>DATE</th>
                            <th>Event Id</th>
                          <th>Event Name</th>
                            <th style={{textAlign:'center'}}>Match Block</th>
                        </tr>
                    </thead>
                    <tbody>
  
                    {match.map(function(item,id){
  

  return (
                        <tr id={id}>
                        <td><i class="fa fa-clock-o"></i>&nbsp; {item.marketStartTime}
                            </td>
                            <td>{item.eventId}</td>
                           
                            <td><strong>
                    <a >{item.eventName} </a>
                    </strong>    
                            </td>
                            <td style={{display:'flex', justifyContent:'center'}}>
                                <a class="switch_on" id="enableSparkCheck">
                                    <input type="hidden" id="sparkValue" value="1"/><span></span></a>
                                    <span style={{margin: "7px"}}><strong>Bet Inactive</strong></span> 
                                </td>
                        </tr>
                        )})} 
                        
                    </tbody>

                    {match.length === 0 &&  
                    <thead>
                      <th colSpan="4" style={{textAlign:'center',padding:'10px'}}>
                        There are currently no matches
                      </th>
                    </thead>
                    }
                </table>
  
                <div class="pull-right">
                </div>
            </div>
        </div>
    </div>
  </div>
  </div>
    </React.Fragment>
    )
}
