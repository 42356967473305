import React,{useState,useEffect} from 'react';
import { toast } from "react-toastify";
import axios from 'axios';
import Cookies from 'universal-cookie';
import "react-toastify/dist/ReactToastify.css";


const cookies = new Cookies();


export default function ReceiveCash(props) {
   const [ReceivCash, setReceivCash] = useState("");




   const url = window.location.href;
    const para = url.split( '/' );
    const ledgerExposure = para[4];
    const user = para[5];
   

   const ReciveCash = (e) => {
       e.preventDefault();

       if (ReceivCash === "") {
           toast.warn("Coin can not be Blank!", {
             position: toast.POSITION.TOP_CENTER,
           }
           );
       return;}
       var ssid = cookies.get('sid');
       if(!ssid) return;

       axios.post('https://flagapi.in/agentapi/receiveCash',{
         sid:ssid, 
         agentId:user,
         coin:ReceivCash
        })
        .then(result => {
           if(result.status === 200){
               toast.success("Coin Add Successfully", {
                   position: toast.POSITION.TOP_CENTER,
                 });
               window.location.href = '/CollectionReoprt';
               
               ;
            }
           }
               
         ).catch(e => {
            //setIsError(true);
        });   
           }

    return (
        <React.Fragment>
    <div class="row border-bottom">
</div>  
      <div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>Agent</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/agent/dashboard">Dashboard</a>
         </li>
         <li>
             <a href="/agent/agents?role=super_stockist">Super Stockists</a>
         </li>
         <li>
            <a href="/agent/agents/18413">SST18413 (Demo)</a>
         </li>
         <li class="active">
            <strong>Recieve Cash</strong>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
   <div class="col-lg-12">
      <div class="ibox float-e-margins">
         <div class="ibox-title">
            <h5>Receive Cash From Agent</h5>
            <div class="ibox-tools">
               <a class="collapse-link">
               <i class="fa fa-chevron-up"></i>
               </a>
               <a class="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
               <i class="fa fa-wrench"></i>
               </a>
               <ul class="dropdown-menu dropdown-user">
                  <li><a href="#">Market Settle</a></li>
                  <li><a href="#">Unsettle Market</a></li>
               </ul>
               <a class="close-link">
               <i class="fa fa-times"></i>
               </a>
            </div>
         </div>
         <div class="ibox-content table-responsive">
            <div accept-charset="UTF-8" action="/agent/agents/18413/receive_cash_from_agent" class="form-horizontal" data-remote="true" id="payment-form" method="post">
            <div class="form-group">
               <label class="col-lg-2 control-label">Agent Name:</label>
               <div class="col-lg-9">
                  <p class="form-control-static">{user}</p>
               </div>
            </div>
            {/* <!-- <input type="hidden" id="post_tag_list" name="coins_transaction_type" value="cash_payment" /> -->
            <!-- <div class="hr-line-dashed"></div> --> */}
            <div class="form-group">
               <label class="col-lg-2 control-label">Ledger Balance:</label>
               <div class="col-lg-9">
                  <p class="form-control-static available-coin" id="">{ledgerExposure}</p>
               </div>
            </div>
            {/* <!-- <div class="hr-line-dashed"></div> --> */}
            <div class="form-group">
               <label class="col-sm-2 control-label">Updates Coins</label>
               <div class="col-sm-9"><input type="text" id="" name="coins" value={ReceivCash} onChange={(e)=>{setReceivCash(e.target.value)}} class="form-control added-coin"/></div>
            </div>
            <div class="form-group"><label class="col-sm-2 control-label">Note</label>
              <div class="col-sm-9"><textarea id="" name="note" class="form-control added-note"></textarea></div>
            </div>
            {/* <!-- <div class="hr-line-dashed"></div> --> */}
            <div class="form-group">
               <div class="col-sm-9 col-sm-offset-3">
                  {/* <!-- <button class="btn btn-white" type="submit">Cancel</button> --> */}
                  <button class="btn btn-primary" onClick={(e)=>{ReciveCash(e)}} type="submit">Save Changes</button>
               </div>
            </div>
</div>         </div>
      </div>
   </div>
</div>

   
  
        </React.Fragment>
    )
}
