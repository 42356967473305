import React from 'react'
import { Link } from 'react-router-dom'




export default function Sportslist() {

    return (
        <React.Fragment>

            <div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>Matches</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/">Dashboard</a>
         </li>
         <li>
            <a href="/liveMatches"><strong>Manage Matches</strong></a>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>
            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                            <th style={{textAlign:'center',fontSize:'15px'}}>Sports</th>
                           
                                <th style={{textAlign:'center',fontSize:'15px'}}>Sports Block</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td style={{fontWeight:'700',textAlign:'center'}}><Link  to="/cricketList"><button type="button" class="btn btn-w-m btn-primary">Cricket</button></Link></td>            
                                <td style={{display:'flex', justifyContent:'center'}}>
                                    <a class="switch_on" id="enableSparkCheck">
                                        <input type="hidden" id="sparkValue" value="1"/><span></span></a>
                                        <span style={{margin: "7px"}}><strong>Bet Inactive</strong></span>                
                                    </td>
                            </tr>
                            <tr>
                            <td style={{fontWeight:'700',textAlign:'center'}}><Link to="/soccerList"><button type="button" class="btn btn-w-m btn-primary">Soccer</button></Link></td>            
                                <td style={{display:'flex', justifyContent:'center'}}>
                                    <a class="switch_on" id="enableSparkCheck">
                                        <input type="hidden" id="sparkValue" value="1"/><span></span></a>
                                        <span style={{margin: "7px"}}><strong>Bet Inactive</strong></span>                
                                    </td>
                            </tr>
                            <tr>
                            <td style={{fontWeight:'700',textAlign:'center'}}><Link to="/tennisList"><button type="button" class="btn btn-w-m btn-primary">Tennis</button></Link></td>            
                                <td style={{display:'flex', justifyContent:'center'}}>
                                    <a class="switch_on" id="enableSparkCheck">
                                        <input type="hidden" id="sparkValue" value="1"/><span></span></a>
                                        <span style={{margin: "7px"}}><strong>Bet Inactive</strong></span>                
                                    </td>
                            </tr>
                            <tr>
                            <td style={{fontWeight:'700',textAlign:'center'}}><Link to="/manageMatches"><button type="button" class="btn btn-w-m btn-primary">Kabbadi</button></Link></td>            
                                <td style={{display:'flex', justifyContent:'center'}}>
                                    <a class="switch_on" id="enableSparkCheck">
                                        <input type="hidden" id="sparkValue" value="1"/><span></span></a>
                                        <span style={{margin: "7px"}}><strong>Bet Inactive</strong></span>                
                                    </td>
                            </tr>
                            <tr>
                            <td style={{fontWeight:'700',textAlign:'center'}}><Link to="/manageMatches"><button type="button" class="btn btn-w-m btn-primary">Casino</button></Link></td>            
                                <td style={{display:'flex', justifyContent:'center'}}>
                                    <a class="switch_on" id="enableSparkCheck">
                                        <input type="hidden" id="sparkValue" value="1"/><span></span></a>
                                        <span style={{margin: "7px"}}><strong>Bet Inactive</strong></span>                
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pull-right">
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
        </React.Fragment>
    )
}
