import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {toast} from 'react-toastify'
import { Link } from 'react-router-dom';


const cookies = new Cookies();
toast.configure()

export default function CreateCompany(props) {
    const [username,setusername] = useState('');
    const [level,setlevel] = useState('1');
    const [usernameAvail,setusernameAvail] = useState(false);
    const [pass,setpass] = useState('');
    const [confirmpass,setconfirmpass] = useState('');
    const [firstname,setfirstname] = useState('');
    const [lastname,setlastname] = useState(' ');
    const [AgentShare, setAgentShare] = useState('');
    const [MyShare, setMyShare] = useState('')
    const [AgentFixLimt, setAgentFixLimit] = useState('0');
    const [MatchComm, setMatchComm] = useState('0');
    const [SessionComm, setSessionComm] = useState('0');
    const [RollingComm, setRollingComm] = useState('0');



    var ShareS =props.share;
    var matchCommv =props.matchcomm;
    var sessionCommv = props.sessioncomm;
    var rollingcommv = props.rollingcomm;
    var balanceV =  props.balance;

    const ChangeShare = (val)=>{
        if(parseFloat(val) > parseFloat(props.share)){
            setMyShare(parseInt(props.share));
            document.getElementById("share_Agent").innerHTML="My Share should be set below or equal to" +" "+ ShareS ;
          }
          else {
            setMyShare(val);
          }
        //setMyShare(val);
        let agentS = parseFloat(props.share) - parseFloat(val);
        if(agentS > 0){
          setAgentShare(parseInt(agentS));
        }
        else {
            setAgentShare(0);
        }
    }
    const ChangefixLimitBal = (val)=>{
        if(parseFloat(val) > parseFloat(props.balance)){
            setAgentFixLimit(parseInt(props.balance));
            document.getElementById("Fixlimit_balance").innerHTML="My Commission should be set below or equal to" +" "+ balanceV ;
          }
          else {
            setAgentFixLimit(val);
          }
    }
    const ChangeSessionComm = (val)=>{
        if(parseFloat(val) > parseFloat(props.sessioncomm)){
            setSessionComm(parseInt(props.sessioncomm));
            document.getElementById("SessionCommm").innerHTML="My Commission should be set below or equal to" +" "+ sessionCommv ;
          }
          else {
            setSessionComm(val);
          }
    }
    const changeRollingComm = (val)=>{
        if(parseFloat(val) > parseFloat(props.rollingcomm)){
            setRollingComm(parseInt(props.rollingcomm));
            document.getElementById("rolingCommm").innerHTML="My Commission should be set below or equal to" +" "+ rollingcommv ;
          }
          else {
            setRollingComm(val);
          }
    }   
    const ChangeMatchComm = (val)=>{
        if(parseFloat(val) > parseFloat(props.matchcomm)){
            setMatchComm(parseInt(props.matchcomm));
            document.getElementById("MatchCommm").innerHTML="My Commission should be set below or equal to" +" "+ matchCommv ;
          }
          else {
            setMatchComm(val);
          }
    }
    // const [comm,setcomm] = useState('');

    // useEffect(()=>{
        
    //     var ssid = cookies.get('sid');
    //     if(!ssid) return;
    //     axios.post('https://flagapi.in/agentapi/myComm',{
    //            sid:ssid
    
    //           })
    //           .then(result => {
                 
    //               if(result.status === 200){
    //                  setcomm(result.data);
    //                
                   
    //               }
         
    //              }
                     
    //             ).catch(e => {
    //               //setIsError(true);
    //       });
    //     },[]);

        const validateUser = ()=>{

            // if (AgentShare=== "" || AgentFixLimt === "" ||
            // MatchComm === "" || SessionComm === ""  || RollingComm === "") {
            //      document.getElementById("AllError").innerHTML="all fields are mandatory!";
            //      return;
            //  }
          if(username === ''  || username === ' '){
             document.getElementById("userNameErrorText").style.color = "#D0021B";
             document.getElementById("userNameErrorText").innerHTML="Username can not be blank!";
             return;
          }
          var ssid = cookies.get('sid');
          if(!ssid) return;
          axios.post('https://flagapi.in/agentapi/NewvalidateAgent',{
                 sid:ssid,
                 agentId:username
      
                })
                .then(result => {
                   
                    if(result.status === 200){
                      setusernameAvail(false);  
                      document.getElementById("userNameErrorText").style.color = "#D0021B";
                      document.getElementById("userNameErrorText").innerHTML="Username not available!";
                       
                     }
                     else if(result.status === 206){
                      setusernameAvail(true);   
                      document.getElementById("userNameErrorText").style.color = "green";
                      document.getElementById("userNameErrorText").innerHTML="Username available!";

                    } 
           
                   }
                       
                  ).catch(e => {
                    //setIsError(true);
            });
          }   


  const validatePass = ()=>{
          
              if(pass.length < 4){
                 
                 document.getElementById("passwordErrorText").innerHTML="Password must be atleast 4 char long!";
                 return;
              }
              else{
                 document.getElementById("passwordErrorText").innerHTML="";
              }
             
          }   
  
  const confirmPassword = ()=>{   
      if(pass !== confirmpass){
          document.getElementById("repeatPasswordErrorText").innerHTML="Password do not match!";
          return;
           }
          else{
              document.getElementById("repeatPasswordErrorText").innerHTML="";
              } 
      }   
      const validateShare = ()=>{
        if(MyShare === '' || MyShare === ' '){
            document.getElementById("share_Agent").innerHTML="Share can not be blank!";
        }
        else{
            document.getElementById("share_Agent").innerHTML=""; 
        }
    }

  const validateFirst = ()=>{
      if(firstname === '' || firstname === ' '){
          document.getElementById("firstErrorText").innerHTML="firstname can not be blank!";
      }
      else{
          document.getElementById("firstErrorText").innerHTML=""; 
      }
  }

  const validateFixLimit = ()=>{
    if(AgentFixLimt > props.balance){
        document.getElementById("Fixlimit_balance").innerHTML="My Commission should be set below or equal to"  +" "+ balanceV;
    } else{
        document.getElementById("Fixlimit_balance").innerHTML=""; 
    }
    }

  const validatematchcomm = ()=>{
if(MatchComm > props.matchcomm){
    document.getElementById("MatchCommm").innerHTML="My Commission should be set below or equal to"  +" "+ matchCommv;
} else{
    document.getElementById("MatchCommm").innerHTML=""; 
}
}

const validatsessioncomm = ()=>{
    if(SessionComm > props.sessioncomm){
        document.getElementById("SessionCommm").innerHTML="My Commission should be set below or equal to"  +" "+ sessionCommv;
    } else{
        document.getElementById("SessionCommm").innerHTML=""; 
    }
    }

const validaterollingcomm = ()=>{
    if(RollingComm > props.rollingcomm){
        document.getElementById("rolingCommm").innerHTML="My Commission should be set below or equal to"  +" "+ rollingcommv;
    } else{
        document.getElementById("rolingCommm").innerHTML=""; 
    }
    }

 
    useEffect(() => {
   
        axios.get(
          `https://flagapi.in/agentapi/getUserName?level=${1}`)
          
          .then(result => {
           
          
            setusername(result.data); 
         
            }) 
          .catch(error => console.log(error));
      },[]);
      

 
    const addAgent = ()=>{
      if(username === ''  || username === ' '){
        validateUser(); 
      }
      if(username === ''  || username === ' ' || pass !== confirmpass || pass.length < 4 ||
      usernameAvail === false || firstname === '' || firstname === ' ' || MyShare === '' || MyShare === ' '){
        validatePass();
        confirmPassword();
        validateFirst();
        validateShare();
        validatematchcomm();
        validatsessioncomm();
        validaterollingcomm();
        validateFixLimit();
        return;
     }
        var ssid = cookies.get('sid');
        if(!ssid) return;
       
        axios.post('https://flagapi.in/agentapi/addAgent',{
               sid:ssid,
               level:level,
               username:username,
               agentpass:pass,
               firstname:firstname,
               lastname:lastname,
               agentShare:AgentShare,
               myShare:MyShare,
               agentFixLimit:AgentFixLimt,
               matchComm:MatchComm,
               sessionComm:SessionComm,
               rollingComm:RollingComm,

              })
              .then(result => {
                  if(result.status === 200){
                    toast.success('Agent created successfully!', {position:toast.POSITION.TOP_CENTER}) 
                  
                    window.location.href = '/master';
                    return;        
                  }
                
                else{
                   toast.warn('Something went wrong!', {position:toast.POSITION.TOP_CENTER})
                }
             }
                     
         ).catch(e => {
                  //setIsError(true);
     });
  }
            


var Level;
if(level==1){
    Level = "Company"
} 


    return (
        <React.Fragment>
            <section id="main" style={{minHeight: "481px"}}>

    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>New Sub Company</h2>
            <ol class="breadcrumb">
                <li>
                    <Link href="/">Dashboard</Link>
                </li>
                <li>
                    <Link href="/master">Sub Company</Link>
                </li>
                <li class="active">
                    <strong>Create Sub Company</strong>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>

    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>New Super stockist</h5>
                        <div class="ibox-tools">
                      
                            <ul class="dropdown-menu dropdown-user">
                                <li><a href="#">Config option 1</a>
                                </li>
                                <li><a href="#">Config option 2</a>
                                </li>
                            </ul>
                        
                        </div>
                    </div>

                    <div class="ibox-content table-responsive">


                        <div accept-charset="UTF-8" action="/agent/agents" class="form-horizontal" id="agent_form" method="post">
                            <div style={{display:"none"}}><input name="utf8" type="hidden" value="✓"/><input name="authenticity_token" type="hidden" value="Eobi5cTkiTqewM/20L+5UwNQmRI82Kqx3WmsGisnl5A="/></div>

                            <div class="form-group"><label class="col-sm-2 control-label">User Level</label>
                                <div class="col-sm-10">
                                
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="User Name" readonly="readonly" type="text" value={Level} onChange = {(e)=>{setlevel(e.target.value);}}/>
                               
                                </div>
                            </div>
                            <div class="hr-line-dashed"></div> 
                            <div class="form-group"><label class="col-sm-2 control-label">User Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="User Name"  type="text"
                                     onChange = {(e)=>{setusername(e.target.value);}} onBlur={()=>{validateUser();}} value={username}/>
                                    <span id="userNameErrorText"  class="error-text"></span> 
                                </div>
                               
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Password</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="Password"  type="password"
                                     onChange = {(e)=>{setpass(e.target.value);}} onBlur={()=>{validatePass();}} value={pass}/>
                                <span id="passwordErrorText" class="error-text"></span>
                                </div>
                                
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>


                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Confirm Password</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_user_name" name="agent[user_name]" placeholder="Password"  type="password"
                                     onChange = {(e)=>{setconfirmpass(e.target.value);}} onBlur={()=>{confirmPassword();}} value={confirmpass}/>
                                <span id="repeatPasswordErrorText" class="error-text"></span>
                                </div>
                                
                            </div>
                            <input id="agent_ancestry" name="agent[ancestry]" type="hidden" value="18243"/>
                            <input id="agent_role" name="agent[role]" type="hidden" value="master"/>


                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">First Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" onChange = {(e)=>{setfirstname(e.target.value);}} onBlur={()=>{validateFirst();}} value={firstname} id="agent_first_name" name="agent[first_name]" placeholder="First Name" type="text"/>
                                    <span id="firstErrorText" class="error-text"></span>
                                </div>
                                
                            </div>

                            <div class="hr-line-dashed"></div>


                            <div class="form-group"><label class="col-sm-2 control-label">Last Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" onChange = {(e)=>{setlastname(e.target.value);}} value={lastname}  id="agent_last_name" name="agent[last_name]" placeholder="Last Name" type="text"/>
                                </div>
                            </div>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">My Share</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_share" max="100.00" min="0.00" name="agent[agent_share]" placeholder="Agent Share" required="required"  type="number" value={MyShare}  onBlur={()=>{validateShare();}}  onChange = {(e)=>{ChangeShare(e.target.value);}}/>
                                    <text class="agent_share_error"><b>Note</b> : Max Agent Share + My Share should be set below or equal to <b>{props.share}</b></text>
                                   <br/> <span id="share_Agent" class="error-text"></span>
                                </div>
                            </div>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Agent Share</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_share" max="100.00" min="0.00" name="agent[agent_share]" placeholder="Agent Share" required="required"  type="number" value={AgentShare} />
                                    <text class="agent_share_error"><b>Note</b> : Max Agent Share + My Share should be set below or equal to <b>{props.share}</b></text>
                                </div>
                            </div>

                            <div class="hr-line-dashed"></div>

                            <div class="form-group"><label class="col-sm-2 control-label">Agent Fix Limit</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="entered_agent_fix_limit" min="0.00" name="agent[fix_limit]" placeholder="Agent Fix Limit" required="required" type="number" value={AgentFixLimt} onBlur={()=>{validateFixLimit();}} onChange = {(e)=>{ChangefixLimitBal(e.target.value);}}/>
                                    <text class="agent_fix_limit_error"><b>Note</b> : Agent Fix Limit set below or equal to <b>{parseFloat(props.balance - AgentFixLimt).toFixed(2)}</b></text>
                                    <br/> <span id="Fixlimit_balance" class="error-text"></span>
                                </div>
                            </div>
                          
                            { parseFloat(props.matchcomm).toFixed(2) > 0 &&
                        <React.Fragment>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Agent Match Commission</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_match_comission" max="100.00" min="0.00" name="agent[match_commission]" placeholder="Agent Match Commission" required="required" type="text"  onBlur={()=>{validatematchcomm();}} value={MatchComm} onChange = {(e)=>{ChangeMatchComm(e.target.value);}}/>
                                    <text class="commi_error_match"><b>Note</b> : Agent Match Commission set below or equal to <b>{parseFloat(props.matchcomm).toFixed(2)}</b></text>
                                    <br/> <span id="MatchCommm" class="error-text"></span>
                                </div>
                            </div>
                            </React.Fragment>}
                            { parseFloat(props.sessioncomm).toFixed(2) > 0 &&
                        <React.Fragment>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Agent Session Commission</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_session_comission" max="100.00" min="0.00" name="agent[session_commission]" placeholder="Agent Session Commission" required="required" type="text" value={SessionComm} onBlur={()=>{validatsessioncomm();}} onChange = {(e)=>{ChangeSessionComm(e.target.value);}}/>
                                    <text class="commi_error_session"><b>Note</b> : Agent Session Commission set below or equal to <b>{parseFloat(props.sessioncomm).toFixed(2)}</b></text>
                                    <br/> <span id="SessionCommm" class="error-text"></span>
                               </div>
                            </div>
                            </React.Fragment>}

                            { parseFloat(props.rollingcomm).toFixed(2) > 0 &&
                        <React.Fragment>
                            <div class="hr-line-dashed"></div>
                            <div class="form-group"><label class="col-sm-2 control-label">Agent Rolling Commission</label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="agent_session_comission" max="100.00" min="0.00" name="agent[session_commission]" placeholder="Agent Session Commission" required="required" type="text" value={RollingComm} onBlur={()=>{validaterollingcomm();}} onChange = {(e)=>{changeRollingComm(e.target.value);}}/>
                                    <text class="commi_error_session"><b>Note</b> : Agent Session Commission set below or equal to <b>{parseFloat(props.rollingcomm).toFixed(2)}</b></text>
                                   <br/> <span id="rolingCommm" class="error-text"></span>
                                </div>
                            </div>
                            </React.Fragment>}
                           
                           
                            <div class="hr-line-dashed"></div>
                            <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">

                                    <Link class="btn btn-white" to="/superStockList">Cancel</Link>
                                    <button class="btn btn-primary" type="submit" id="submit" onClick = {()=>{addAgent();}}>Save Changes</button>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    )
  }