import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {toast} from 'react-toastify'
import PasswModel from './PasswModel'

toast.configure()
const cookies = new Cookies();


export default function ClientComm(props) {
    const [FixLimit,setFixLimit] = useState();
	const [agents,setagents] = useState([]);
	const [arrIndex,setarrIndex] = useState(-1);
	const [bankingType,setbankingType] = useState('no');
	const [agentPass,setagentPass] = useState('');
	const [updated,setupdated] = useState(false);
	const [showPlayerLog,setshowPlayerLog] = useState(false);
	const [updateLimit, setupdateLimit] = useState(false);
	const [showAllLog,setshowAllLog] = useState(false);
	const [refresh,setrefresh] = useState(true);
	const [search, setsearch] = useState('');
	const [agentsID, setagentsID] = useState('')
	const [creditref,setcreditref] = useState('');
    const [user,setuser] = useState([]);



 
    var sum_A1 = 0, sum_B1 = 0, sum_C1 = 0, sum_D1 = 0, sum_D2 = 0;;

	useEffect(()=>{
       
       var ssid = cookies.get('sid');
       if(!ssid) return;
        
       axios.post('https://flagapi.in/agentapi/clientBelowDetailInfo',{
               sid:ssid
    
              })
              .then(result => {
                
                  if(result.status === 200){
                     
                    let arr = [];
                    for (let key in result.data) { 
                        console.log(result.data)
                        if (result.data.hasOwnProperty(key)) 
                        { 
							result.data[key].user = key;
							result.data[key].banking = 'no';
							result.data[key].amount = '';
							result.data[key].remark = '';
							result.data[key].changeCf = 'no';

                            arr.push(result.data[key]); 
                                      
                        } 
                    }

					var arr1 = arr.filter(function(item) {
						let sub1 = item.user.substring(0,search.length);
						return sub1.toUpperCase() === search.toUpperCase();
					})
                     
					setagents(arr1); 
					
					if(refresh){
						setrefresh(false);
					  }
                    
                  }
         
                 }
                     
            ).catch(e => {
                  //setIsError(true);
        });

			  
	},[updated]);	

	
	const updateDeposit = (index)=>{
		/*var arr = [...agents];
		if(arr[index].banking === 'D'){
		  arr[index].banking = 'no';
		  
		}
		else{
		  arr[index].banking = 'D';
		  
		 }

		var newArr = [];
		arr.map((item,index)=>{
			if((item.banking === 'D' || item.banking === 'W') && !isNaN(parseFloat(item.amount)) && item.amount != 0){

			} 
		}) 
		setagents(arr);*/
		var arr = [...agents];
      
	    arr.map((item,index)=>{
		   arr[index].changeCf = 'no';
	    })

		if(arrIndex === index && bankingType === 'D'){
			setarrIndex(-1);
			setbankingType('no');
		}
		else{
			setarrIndex(index);
		    setbankingType('D');
		}
		
        
	  }

	// const updateWithdraw = (index)=>{
    //     /*var arr = [...agents];
	// 	if(arr[index].banking === 'W'){
	// 	  arr[index].banking = 'no';
          
	// 	}
	// 	else{
	// 	  arr[index].banking = 'W';

	// 	}
    //     setagents(arr);

    //    */
	//   var arr = [...agents];
      
	//     arr.map((item,index)=>{
	// 	   arr[index].changeCf = 'no';
	//     })

	//   if(arrIndex === index && bankingType === 'W'){
	// 	setarrIndex(-1);
	// 	setbankingType('no');
	//   }
	//   else{
	// 	setarrIndex(index);
	// 	setbankingType('W');
	//    }

	//   }

	// const updateAmount = (val,index)=>{
    //     var arr = [...agents];
		
	// 	arr[index].amount = val;
          
    //     setagents(arr);

	//   }

	const updateRemark = (val,index)=>{
        var arr = [...agents];
		
		arr[index].remark = val;
          
        setagents(arr);

	  }


	const updateCf = (val,index)=>{
        var arr = [...agents];

		
		arr[index].changeCf = val;


        setcreditref(arr[index].cf);  
        setagents(arr);

	  }

	const changeCreditRef = (val,index)=>{
        setcreditref(val);

	  }



	  
	const submitPayment = ()=>{
		
		if(agentPass === '' || agentPass === ' '){
		   toast.warn('Password can not be blank! ', {position:toast.POSITION.TOP_CENTER})
		   return;
		}
		else if(arrIndex < 0){
		   toast.warn('Please select an agent', {position:toast.POSITION.TOP_CENTER})
		   return;
		}
		else if(isNaN(parseFloat(agents[arrIndex].amount))){
		   toast.warn('Please select a valid amount', {position:toast.POSITION.TOP_CENTER})
		   return;
		}
		else if(agents[arrIndex].amount <= 0){
			toast.warn('Please enter a positive amount', {position:toast.POSITION.TOP_CENTER})
			return;
		 }
		else if((parseFloat(agents[arrIndex].amount) > parseFloat(agents[arrIndex].balance)) && bankingType === 'W'){
			toast.warn('Amount can not be more than Available D/W', {position:toast.POSITION.TOP_CENTER})
			return;
		 }
		var ssid = cookies.get('sid');
		if(!ssid) return;
		 
		axios.post('https://flagapi.in/agentapi/playerBanking',{
			sid:ssid,
			agentId:agents[arrIndex].user,
			// bankingType: bankingType,
			amount: agents[arrIndex].amount,
            remark: agents[arrIndex].remark,
            myPass:agentPass,
			updateCF:agents[arrIndex].changeCf,
			creditVal:creditref

		   })
		   .then(result => {
			  
			   if(result.status === 200){
				  
				toast.success('Player balance updated successfully!', {position:toast.POSITION.TOP_CENTER})
                clearAll();
                props.checkUpdateBalance();
				setupdated(!updated);
				props.OpenPassModel(false)
				
			   }
			   else if(result.status === 211){
				  
				toast.warn('Wrong password!', {position:toast.POSITION.TOP_CENTER}) 
					 
			   }
               else{
				toast.warn('Player balance update failed!', {position:toast.POSITION.TOP_CENTER}) 
				
			   }
	  
			  }
				  
		 ).catch(e => {
			   //setIsError(true);
	   });

		//arr[index].remark = val;       

	  }
	  const clearAll = ()=>{
		setarrIndex(-1);
		setbankingType('no');
		setagentPass('');

		var arr = [...user];
		arr.map((item)=>{
			item.amount = '';
			item.remark = '';
		})
		
		setuser(arr);

	  }
     
		 
	  const UpdateFixlimit=()=>{

		if(agentPass === '' || agentPass === ' '){
			toast.warn('Password can not be blank! ', {position:toast.POSITION.TOP_CENTER})
			return;
		 }
		 else if(isNaN(parseFloat(FixLimit))){
			toast.warn('Please select a valid amount', {position:toast.POSITION.TOP_CENTER})
		
			return;
			
		 }
		var ssid = cookies.get('sid');
			axios.post('https://flagapi.in/agentapi/clientFixlimit', {
			sid: ssid,
			curr_limit:FixLimit,
			clientId:agentsID,
			password:agentPass
			
		}) .then(result => {
			if(result.status === 200) { 
				setupdateLimit(!updateLimit);
				toast.success('Fixlimit updated successfully', {position:toast.POSITION.TOP_CENTER});
				// SaveUpdateLimit();
				props.OpenPassModel(false)
			}  
			else if(result.status === 206){
			toast.warn('Wrong password!', {position:toast.POSITION.TOP_CENTER}) 
				
		   }
			}).
			catch(e => {

			});    
	   }



    return (
        <React.Fragment>

{ props.OpenPass &&
        <PasswModel agentPass ={agentPass} setagentPass={setagentPass}  UpdateFixlimit={UpdateFixlimit} OpenPassModel={props.OpenPassModel}/> }

				<div class="ibox-content table-responsive">
                        <table class="table table-bordered table-responsive">
                            <thead>
                                <tr> 
                                    <th style={{textAlign:'center'}}> &nbsp; &nbsp;User Name  &nbsp;&nbsp;</th>
									<th style={{textAlign:'center'}}>Name</th>
								     <th style={{textAlign:'center'}}>Fix Limit</th>
                                     <th style={{textAlign:'center'}}>Current Limit</th>
                                     {/* <th style={{textAlign:'center'}}>P/L</th> */}
                                    <th style={{textAlign:'center'}}>Used Limit</th>
                                    {/* <th style={{textAlign:'center'}}>Deposit / Withdraw</th> */}
                                    <th style={{textAlign:'center'}}>Action</th>
                                </tr>
                               
                            </thead>
							{agents.length === 0 &&  <tbody><th colspan="7" style={{textAlign:'center',padding:'15px'}}>There are no client Commision</th> </tbody>}
                            <tbody>

                            			{agents.map((item,index)=>{
             
			 let expo = item.exposure ? item.exposure : 0;
			 var avail_balance = item.balance ? item.balance : 0;
             let b = parseFloat(avail_balance)- Math.abs(expo);
          
                
			 //var avail_balance = parseFloat(b)+ Math.abs(expo);
			
			 let cr = parseFloat(b) - parseFloat(item.cf);
             sum_A1 = sum_A1 + parseFloat(avail_balance);
			 sum_B1 = sum_B1 + parseFloat(b);
			 sum_C1 = sum_C1 + parseFloat(expo);
			 sum_D1 = sum_D1 + parseFloat(item.cf);
			 sum_D2 = sum_D2 + cr;
			
         return(
                            <tr key={index} data-agent-id="1294">  
                                    <td style={{textAlign:'center'}}><span className={`lv_0`}>PL</span> {item.user}</td>
									<td style={{textAlign:'center'}}> {item.first_name}</td>
                                    <td style={{paddingTop:'10px'}}>
                                        <input class="update-agent-info col-xs-6 " style={{width:'125px',marginLeft:'20px'}} data-field-name="fix_limit"  id="fix_limit" name="fix_limit" type="text"  defaultValue={avail_balance} onChange={(e) => setFixLimit(e.target.value)} />
                                    </td>
                                    <td style={{paddingTop:'10px'}}><input class="update-agent-info col-xs-6" style={{width:'125px',marginLeft:'20px'}} data-field-name="session_commission" disabled   id="session_commission" name="session_commission" type="text" value={parseFloat(b).toFixed(2)}/>
                                    </td>
                                    {/* <td style={{paddingTop:'10px'}}>
                                        <input class="update-agent-info col-xs-6 " style={{width:'125px',marginLeft:'20px'}} data-field-name="fix_limit"  id="fix_limit" name="fix_limit" disabled type="text" value="500000.0"/>
                                    </td> */}
                                    <td style={{paddingTop:'10px'}}>
                                        <input class="update-agent-info col-xs-6 " style={{width:'125px',marginLeft:'20px'}} data-field-name="fix_limit"  id="fix_limit" name="fix_limit" disabled type="text" value={'('+parseFloat(Math.abs(expo)).toFixed(2)+')'}/>
                                    </td>

                                    {/* <td class="DW-amount" style={{justifyContent:'center'}}>
                                        <ul className ={`btn_list-DW ${index === arrIndex && bankingType === 'D' ? "select-D":""} ${index === arrIndex && bankingType === 'W' ? "select-W":""}`} style={{listStyle:'none'}}>
                                            <li onClick={()=>{updateDeposit(index);}}><a id="dBtn" style={index === arrIndex && bankingType === 'D' ? {lineHeight: '13px',fontSize: '13px',backgroundColor:'green',borderColor:'green'}:{lineHeight: '13px',fontSize: '13px'}}   class="btn btn-primary">D</a></li>
                                            <li onClick={()=>{updateWithdraw(index);}}><a id="wBtn" style={index === arrIndex && bankingType === 'W' ? {lineHeight: '13px',fontSize: '13px', marginRight:'9px',backgroundColor:'red',borderColor:'#18a689'}:{lineHeight: '13px',fontSize: '13px', marginRight:'9px'}} class="btn btn-primary">W</a></li>
                                            </ul>
                                            <input id={"amount"+index} onChange = {(e)=>{updateAmount(e.target.value,index);}} value ={item.amount} align style={{paddingLeft:'9px', width:'125px',marginLeft:'20px'}} class="" name="amount" type="number"  placeholder="0" maxlength="18" />
                                               </td> */}
                                    <td><button type="button" onClick={()=>{props.OpenPassModel(true); setagentsID(item.user);}} class="btn btn-primary update-agent" style={{lineHeight: '15px',fontSize: '12px'}}>Update</button>
                                    </td>
                                </tr>
                                 )})}      
                          </tbody>
                      </table>
                  </div>
        </React.Fragment>
    )
}
