import React,{useState,useEffect} from 'react'
import { toast } from "react-toastify";
import axios from 'axios';
import Cookies from 'universal-cookie';
import "react-toastify/dist/ReactToastify.css";

const cookies = new Cookies();
export default function MatchLedger() {

    const [agentLed, setagentLed] = useState([])
    // AgentLedger
    const url = window.location.href;
        const para = url.split( '/' );
        const user = para[4];
        
    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
 
        axios.post('https://flagapi.in/agentapi/AgentLedger',{
          sid:ssid,  
          agentId:user
         })
         .then(result => {
             if(result.status === 200){
           ;
           setagentLed(result.data)
                 }
            }
                
          ).catch(e => {
             //setIsError(true);
         });   
    }, [])

    return (
        <React.Fragment>
            <div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>Agents</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/agent/dashboard">Dashboard</a>
         </li>
         <li>
            <a href="/agent/agents?role=super_stockist">Super Stockists</a>
         </li>
         <li>
            <a href="/agent/agents/18413">SST18413 (Demo)</a>
         </li>
         <li class="active">
            <strong>Match Ledger</strong>
            </li> 
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>

            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">


       



                <div class="ibox-title">
                    <h5>Agent Ledger</h5>
                    <div class="ibox-tools">

                        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                                <i class="fa fa-wrench"></i>
                            </a>
                        <ul class="dropdown-menu dropdown-user">
                            {/* <!-- <li><a target="_blank" href="/agent/agents//ledger_show_pdf.pdf">Export PDF</a>
                                </li> --> */}
                        </ul>
                    </div>
                </div>
                <div class="ibox-content table-responsive">

                    <table class="table">
                        <thead>
                            <tr>
                                {/* <!-- <th>S No</th> --> */}
                                <th>DATE/TIME</th>
                                <th>Collection Name</th>
                                <th>DEBIT</th>
                                <th>CREDIT</th>
                                <th>Balance</th>

                            </tr>
                        </thead>
                        <tbody>


                        {agentLed.length === 0 && <p colSpan="6" class="no-data" style={{padding:0}}>There are no Agent Ledger.</p>} 
            {agentLed.map((item,index)=>{ 
                var Debit;
                var credit;
                var cash_paid;
              if(item.exposureAbove > 0){
                  Debit = "-"
              }else{
                  Debit =item.exposureAbove;
              }

              if(item.exposureAbove < 0){
                credit = "-"
            }else{
                credit =item.exposureAbove;
            }
            if(item.eventId == 'cash'){
                if(item.exposureAbove < 0){
                    cash_paid = "Cash Paid";
                }else {
                    cash_paid = item.eventName;
                }
                 }   
                
                   return(
                            <tr id={index}>
                                <td id="getdate_23812662">{item.timeCurr}</td>
                                <td>{item.eventName }</td>
                                <td>{Debit} </td>
                                <td>{credit}</td>
                                <td>{item.ledgerBalance}</td>
                            </tr>
                                )})}

                        </tbody>
                        {/* <tfoot>
                            <tr>
                                <th>
                                    Total</th>
                                <!-- Username -->
                                <th>-1864024.62</th>
                            </tr>
                        </tfoot> */}
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
