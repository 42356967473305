import axios from 'axios'
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import moment from 'moment'

const cookies = new Cookies();

export default function Matches() {
    const [Inplay, setInplay] = useState([]);

useEffect(() => {
    var ssid = cookies.get('sid');
    if(!ssid) return;

    axios.post('https://flagapi.in/agentapi/liveMatchList',{
        sid:ssid,
       eventType:4
       })
       .then(result => {
           if(result.status === 200){
            result.data.map(item=>{
                item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                return item;
              }) 

              result.data.sort(function (a, b) {
                return moment(a.marketStartTime) - moment(b.marketStartTime);
              });

              var obj = [];
              result.data.map((item)=>{
                  if(moment() >= moment(item.marketStartTime)){
                    obj.push(item);
                  }
                 
                  
              });
              setInplay(obj);
        //  console.log(result);
        //     setInplay(result.data);    
           }
          } 
        ).catch(e => {
           //setIsError(true);
       }); 
}, [])


    return (
        <React.Fragment>
            <div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>Matches</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/dashboard">Dashboard</a>
         </li>
         <li>
            <a href="/Matches"><strong>Matches</strong></a>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>
            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    {/* <h5>Matches</h5> */}
              
                    <div class="ibox-tools">
                    </div>
                </div>
                
                <div class="ibox-content table-responsive">
                    
                <div class="row scroll-form">
                            <table class="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="bet-place-tbl-th align-left" style={{backgroundColor:"#6E8FC9"}}>Market : Match Winner</th>
                                    </tr>
                                    <tr>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>RUNNER</th>
                                        <th class="bet-odds-type bet-place-tbl-th">LAGAI</th>
                                        <th class="bet-odds-type bet-place-tbl-th">KHAI</th>
                                        <th class="bet-place-tbl-th" style={{backgroundColor: "#49494A"}}>POSITION</th>
                                    </tr>
                                </thead>
                             
                                <tbody>
                                    <tr>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'rgb(132, 195, 65)', color: 'rgb(255, 255, 255)'}}>England </td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer" odd-val="1.77" market-id="294797" runner-id="548209" match-id="224642" data-id="LAGAI" id="Match Winner_224642_back_Leicestershire  _224642">24</td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer" odd-val="1.8" market-id="294797" runner-id="548209" match-id="224642" data-id="KHAI" id="Match Winner_224642_lay_Leicestershire  _224642">34</td>
                                        <td data-runner-sid="Leicestershire  _224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" >
                                      
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-runner-sid="Derbyshire_224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'white', color: 'rgb(73, 73, 74)'}}>India</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer  close-status close-status" odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="LAGAI"
                                            id="Match Winner_224642_back_Derbyshire_224642">45</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer  close-status close-status" odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="KHAI"
                                            id="Match Winner_224642_lay_Derbyshire_224642">34</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" style={{color: "rgb(73, 73, 74)"}}>
                                       
                                        </td>
                                    </tr>
                             
                                    <tr>
                                        <td data-runner-sid="Derbyshire_224642" data-column="runner_name" class="bet-place-tbl-td" style={{textAlign: 'left', backgroundColor: 'white', color: 'rgb(73, 73, 74)'}}>The Draw</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="back" class="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer  close-status close-status" odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="LAGAI"
                                            id="Match Winner_224642_back_Derbyshire_224642">45</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="lay" class="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer  close-status close-status" odd-val="1.01" market-id="294797" runner-id="548210" match-id="224642" data-id="KHAI"
                                            id="Match Winner_224642_lay_Derbyshire_224642">56</td>
                                        <td data-runner-sid="Derbyshire_224642" data-column="position" class="bet-place-tbl-td  bet-val-cent" data-position="0.0" style={{color: "rgb(73, 73, 74)"}}>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    
                    
                    
                    
                    
                    
                    {/* <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Event Name</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Inplay.map((item,index)=>{     
                             return(
                     <tr id={index}> 
                                <td><strong>
                              <Link to={`/liveReport/${item.eventId}/${item.eventType}`} >{item.eventName}</Link>
                        </strong><br/>       
                                </td>
                            </tr>
                            )})}

                    {Inplay.length === 0  &&
                            <tr>
                                <td>
                                There are currently no matches in Live
                                </td>
                            </tr>}
                        </tbody>
                    </table> */}


                    {/* <div class="pagination"><span class="previous_page disabled">← Previous</span> 
                    <em class="current">1</em> <a rel="next" href="/agent/matches?page=2">2</a>
                     <a href="/agent/matches?page=3">3</a> <a href="/agent/matches?page=4">4</a>
                      <a href="/agent/matches?page=5">5</a>  <a href="/agent/matches?page=6">6</a>
                                 <a class="next_page" rel="next" href="/agent/matches?page=2">Next →</a></div> */}
                    <div class="pull-right">
                    </div>
                </div>
                
            
             
           


            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
