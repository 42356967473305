import React from 'react'
import { Link } from 'react-router-dom'

export default function ClientDash() {

   const url = window.location.href;
    const para = url.split( '/' );
    const le = para[4];
    const user = para[5];
    const coins = para[6];
  
    console.log(user);
    
    return (
        <React.Fragment>
 
    <div class="row border-bottom">
    </div>

    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>Agent</h2>
            <ol class="breadcrumb">
                <li>
                    <Link href="/">Dashboard</Link>
                </li>
                <li>
                    <Link to="/newuser">client</Link>
                </li>
                <li>
                    <strong><Link to="/ClientDash">client Dashboard</Link></strong>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">
        </div>
    </div>



    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h1>Client Dashboard</h1>
                    </div>  
                    <div class="ibox-content table-responsive">
                        <p style={{textAlign:'center'}}> 
                            <Link to={`/CoinHistory/${le}/${user}`} ><button type="button" class="btn btn-primary btn-lg">Coin History</button></Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div class="row">
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    {/* <!-- <span class="label label-success pull-right">Monthly</span> --> */}
                    <h5>Coins</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{coins}</h1>
                    {/* <!--  <div class="stat-percent font-bold text-success">98% <i class="fa fa-bolt"></i></div>
                                <small>Total income</small> --> */}
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    {/* <!-- <span class="label label-info pull-right">Annual</span> --> */}
                    <h5>Ledger Balance</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{le}</h1>
                    {/* <!-- <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div>
                                <small>New orders</small> --> */}
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    {/* <!-- <span class="label label-primary pull-right">Today</span> --> */}
                    <h5></h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins"></h1>
                    {/* <!-- <div class="stat-percent font-bold text-navy">44% <i class="fa fa-level-up"></i></div>
                                <small>New visits</small> --> */}
                </div>
            </div>
        </div>

    </div>


  

        </React.Fragment>
    )
}
