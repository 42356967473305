import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

var globalArray1 = [];
var globalArray2 = [];


export default function StockList(props) {
   const [user,setuser] = useState([]);
   const [client,setclient] = useState([]);
   const [agentView,setagentView] = useState(1);
   const [clientView,setclientView] = useState(1);
   const [a,seta] = useState(1);
   const [b,setb] = useState(1);
   const [refresh,setrefresh] = useState(true);
   
   
    useEffect(()=>{
       
        if(props.agentStack.length > 1)return;
        globalArray1 = [];
        globalArray2 = [];
        var ssid = cookies.get('sid');
        if(!ssid) return;
        setrefresh(true);
        if(window.changePage != 1){
          setagentView(1);
          setclientView(1);
        }
    
        axios.post('https://flagapi.in/agentapi/clientBelowDetailInfo',{
            sid:ssid
    
              })
              .then(result => {
                  if(result.status === 200){
                   
                    
                    for (let key in result.data) { 
                        
                        if (result.data.hasOwnProperty(key)) 
                        { 
                            result.data[key].user = key;
                            globalArray2.push(result.data[key]);
                        } 
                    }
                    
                    let arr = [];
                    globalArray2.map((item)=>{
                        arr.push(item);
                    });
                    var startCount = 0;
                    if(window.changePage == 1){
                      var startCount = 15*(clientView-1);
                    }
                    setclient(arr.splice(startCount,15)); 
                    if(window.changePage == 1){
                        window.changePage = 2;
                    }

                  }

                 }
                     
             ).catch(e => {
                  //setIsError(true);
             });
             
             
          
        },[props.updatedStatus]);


        
    useEffect(()=>{
        
        let arr = [];
        globalArray1.map((item)=>{
            arr.push(item);
        });
        var startCount = 15*(agentView-1);
        
        
        setuser(arr.splice(startCount,15)); 


        },[agentView]);


    useEffect(()=>{
       
       
        
        let arr = [];
        globalArray2.map((item)=>{
            arr.push(item);
        });
        var startCount = 15*(clientView-1);
        
        
        setclient(arr.splice(startCount,15)); 
                    
        },[clientView]);


    const changeCountAgent = (e)=>{
        
        seta(parseInt(e.target.value));
        
    }

    const changeCountClient = (e)=>{
       
        setb(parseInt(e.target.value));
        
        
    }

    useEffect(()=>{
       
        if(props.agentStack.length <= 1)return;
        var agentId = props.agentStack[props.agentStack.length-1].userid;
        
        var ssid = cookies.get('sid');
        if(!ssid) return;
        globalArray1 = [];
        globalArray2 = [];
        setagentView(1);
        setclientView(1);
        setrefresh(true);
        axios.post('https://flagapi.in/agentapi/agentBelowBelowDetailInfo',{
               sid:ssid,
               agentId:agentId
    
              })
              .then(result => {
                setrefresh(false);
                  if(result.status === 200){
                  
                    for (let key in result.data) { 
                        
                        if (result.data.hasOwnProperty(key)) 
                        { 
                            result.data[key].user = key;
                            globalArray1.push(result.data[key]);
                            
                            
                        } 
                    }
                    let arr = [];
                    globalArray1.map((item)=>{
                        arr.push(item);
                    });
                    var startCount = 15*(agentView-1);
                    
                    setuser(arr.splice(0,15)); 
                    

                  }
         
                 }
                     
                ).catch(e => {
                  //setIsError(true);
            });

        axios.post('https://flagapi.in/agentapi/clientBelowBelowDetailInfo',{
            sid:ssid,
            agentId:agentId

    
              })
              .then(result => {
                  if(result.status === 200){
                    
                    for (let key in result.data) { 
                        
                        if (result.data.hasOwnProperty(key)) 
                        { 
                            result.data[key].user = key;
                            globalArray2.push(result.data[key]);
                        } 
                    }
                    let arr = [];
                    globalArray2.map((item)=>{
                        arr.push(item);
                    });
                    var startCount = 15*(clientView-1);
                    
                    setclient(arr.splice(0,15)); 
                    
                 

                  }
         
                 }
                     
             ).catch(e => {
                  //setIsError(true);
             });
             
   
      },[props.agentStack.length]);

    
      useEffect(()=>{
    
        
        var arr1 = globalArray1.filter(function(item) {
            let sub1 = item.user.substring(0,props.search.length);
            return sub1.toUpperCase() === props.search.toUpperCase();
        })
        
        var arr2 = globalArray2.filter(function(item) {
            let sub1 = item.user.substring(0,props.search.length);
            return sub1.toUpperCase() === props.search.toUpperCase();
        })
        
        if(props.search === ''){
            setuser(arr1.splice(0,15)); 
            setclient(arr2.splice(0,15)); 
            setagentView(1);
            setclientView(1);
            return;
        }
        setuser(arr1.splice(0,14)); 
        setclient(arr2.splice(0,14)); 
                    
        },[props.searchBtn]);
        
        

    return (
        <React.Fragment>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2> Client</h2>
            <ol class="breadcrumb">
                <li>
                    <a href="/agent/dashboard">Dashboard</a>
                </li>
                <li>
                    <strong> Client</strong>
                </li>
        
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>
    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        
                        <div class="ibox-tools">
                        <select class="" name="accountStatus" id="accountStatus" style={{float:'left', margin:'0px 10px'}}>
                    
                    <option value="0">ACTIVE
                    </option>
                    
                    <option value="1">SUSPENDED
                    </option>
                    
                    <option value="2">LOCKED
                    </option>
                    
                    <option value="-1">ALL</option>
                </select>
                            <Link to="/createclient" class="btn btn-primary btn-xs">Create Client</Link>
                        </div>
                    </div>
                    <div class="ibox-content table-responsive">

                        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper form-inline dt-bootstrap no-footer">
                            <div class="dt-buttons"><a class="dt-button buttons-csv buttons-html5" tabindex="0" aria-controls="DataTables_Table_0"><span>CSV</span></a><a class="dt-button buttons-pdf buttons-html5" tabindex="0" aria-controls="DataTables_Table_0"><span>PDF</span></a></div>
                            <div
                                id="DataTables_Table_0_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control input-sm" placeholder="" aria-controls="DataTables_Table_0"/></label></div>
                        <table class="table table-bordered table-striped dataTables-example dataTable no-footer" id="DataTables_Table_0"
                            role="grid" aria-describedby="DataTables_Table_0_info">
                        <thead>
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="User Name: activate to sort column ascending" style={{width: 0}}>User Name</th>
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: 0}}>Name</th>
                                    {/* <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Fix Limit: activate to sort column ascending" style={{width: 0}}>Balance</th> */}
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="My Share: activate to sort column ascending" style={{width: 0}}>Share</th>
                                    {/* <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Max Share: activate to sort column ascending" style={{width: 0}}>Max Share</th> */}
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Max Share: activate to sort column ascending" style={{width: 0}}>Status</th> 
                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" style={{width: "30%", textAlign:'center'}}>Actions</th>
                                </tr>
                            </thead>
                            {client.length === 0 &&  <tbody><th colspan="7" style={{textAlign:'center',padding:'15px'}}>There are no client in  List</th> </tbody>}
                            <tbody>
                            {client.map((item,index)=>{
                            let b = item.balance ? item.balance : 0;               
                            let le = item.le ? item.le : 0;
                            let expo = item.exposure ? item.exposure : 0;
                            var status = 1;
                            let cr = parseFloat(b) - parseFloat(item.cf);
                            if(item.userBlocked == 0 && item.betBlocked == 0){
                                status = 1;
                            }
                            else if(item.userBlocked == 0 && item.betBlocked == 1){
                                status = 2;
                            }
                            else if(item.userBlocked == 1){
                                status = 3;
                            }
                            return( 
                            <tr id={index} role="row" class="odd">
                            <td class="agent_name">
                            <Link  to={`/ClientDash/${item.le}/${item.user}/${item.balance}`} class="ico_account"  style={{display:'flex'}}>
                                <span className="lv_0">PL</span> {item.user}</Link></td>
                            <td> {item.user} </td>
                            {/* <td>{item.balance}</td>
                            <td>00.0</td> */}
                            <td>00.0</td>
                            <td id="statusCol">
                                {item.userBlocked == 0 && item.betBlocked == 0 && 
                                <span id="status14" class="status-active">
                                <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Active</span>}
                                {item.userBlocked == 0 && item.betBlocked == 1 &&
                                <span id="status14" class="status-active">
                                <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Suspended</span>}
                                {item.userBlocked == 1 &&
                                <span id="status14" class="status-active">
                                <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Locked</span>}
                                </td>
                            <td class="" style={{display:'flex',padding:'5px, 10px'
                                }}>         
                                <Link to={`/editClient/${item.user}`}  class="btn btn-white btn-sm" ><i class="fa fa-pencil-square"></i> Edit</Link>    
                           <a class="btn btn-white btn-sm" onClick={()=>{window.changePage = 1; props.HandlePopup(1,true,{user:item.user,userstatus:status,type:'cli'})}}><i class="fa fa-cog"></i> status</a>
                                        <Link to={`/Changeclientpass/${item.user}`}><button type="button" class="btn btn-w-m btn-primary">Change Password</button></Link>
                                    </td>
                                </tr>
                            )})}
                            </tbody>
                        </table>
                        
                        {/* <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div> */}
                        {globalArray2.length > 0 && 
                        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                            <ul class="pagination">
                                <li className={`paginate_button previous ${(clientView > 1) ? "": "disabled"}`}  id="DataTables_Table_0_previous"onClick = {()=>{if(clientView > 1){setclientView(clientView-1);}}}><a  aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0">Previous</a></li>
                                <li class="paginate_button active"><a aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0">{clientView}</a></li>

                                <li onClick = {()=>{if(client.length >= 15){setclientView(clientView+1);}}} className={`paginate_button next ${(client.length >= 15) ? "": "disabled"}`}   id="DataTables_Table_0_next"><a aria-controls="DataTables_Table_0" data-dt-idx="4" tabindex="0">Next</a></li>
                            </ul>
                        </div>}
                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

        </React.Fragment>
    )
}
