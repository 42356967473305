import React, { useState } from "react";
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";


const cookies = new Cookies();
toast.configure();

export default function ManagePass() {
    const [Newpassword, setNewpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [password, setpassword] = useState("");


    const ChangePassword = (e) => {
        e.preventDefault();
     
        //var strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
        //var test = Newpassword.match(strongRegex);
    
        if (Newpassword === "" || confirmpassword === "" || password === "") {
          toast.warn("Password can not be Blank!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (Newpassword != confirmpassword) {
          toast.warn(" password is not matched!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (Newpassword === password) {
          toast.warn("Old password and new password are same!", {
            position: toast.POSITION.TOP_CENTER,
          });
        /*} else if (!test) {
          toast.warn(
            "Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..)",
            { position: toast.POSITION.TOP_CENTER }
          );*/
        } 
        else {
            var ssid = cookies.get('sid');
            if(!ssid) return;
          axios
            .post("https://flagapi.in/agentapi/changeOwnPassword", {
              sid:ssid,
              currPass: password,
              newPass: Newpassword,
            })
            .then((result) => {
              if (result.status === 200) {
                toast.success("Password Changed Successfully", {
                  position: toast.POSITION.TOP_CENTER,
                });
               
              }
              
    
              if (result.status === 220) {
                toast.warn("Your old password is wrong", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
              
            })
            .catch((e) => {
              //setIsError(true);
            });
        }
      };
    return (
        <React.Fragment>
            <div class="row wrapper border-bottom white-bg page-heading">
                <div class="col-lg-10">
                    <h2>Agent</h2>
                    <ol class="breadcrumb">
                        <li>
                            <a href="/agent/dashboard">Dashboard</a>
                        </li>
                        
                        <li class="active">
                          <strong>Manage Password</strong>
                        </li>
                    </ol>
                </div>
                <div class="col-lg-2">

                </div>
            </div>
            <div class="wrapper wrapper-content animated fadeInRight">
               <div class="row">
                <div class="col-lg-12">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>Change Password</h5>
                            <div class="ibox-tools">
                                <ul class="dropdown-menu dropdown-user">
                                    <li><a href="#">Config option 1</a>
                                    </li>
                                    <li><a href="#">Config option 2</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div class="ibox-content table-responsive" style={{padding: "15px 20px 56px"}}>
                            <form class="form-horizontal" id="edit_user_4">
                               <div class="form-group"><label class="col-sm-2 control-label">OLD PASSWORD</label>
                                  <div class="col-sm-10">              
                                  <input type="password" id="password" name="password" class="form-control login_text_field" placeholder="OLD PASSWORD"
                                  value={password}
                                  onChange={(e) => {
                                    setpassword(e.target.value);
                                  }}
                                  />
                                  </div>
                                </div>
                                
                                <div class="hr-line-dashed"></div>

                                <div class="form-group"><label class="col-sm-2 control-label">NEW PASSWORD</label>
                                    <div class="col-sm-10">
                                      <input type="password" id="new_password" name="new_password" class="form-control login_text_field" placeholder="NEW PASSWORD"   value={Newpassword}
                                    onChange={(e) => {setNewpassword(e.target.value);}}/>
                                    </div>
                                </div>

                                <div class="hr-line-dashed"></div>

                                <div class="form-group"><label class="col-sm-2 control-label">CONFIRM PASSWORD</label>
                                    <div class="col-sm-10">              
                                      <input type="password" id="password_confirmation" name="password_confirmation" class="form-control login_text_field" placeholder="CONFIRM PASSWORD"
                                       value={confirmpassword} onChange={(e) => {setconfirmpassword(e.target.value);}}
                                      />
                                    </div>
                                </div>
                                </form>

                                <div class="form-group">
                                    <div class="col-sm-4 col-sm-offset-2">
                                        
                                        <button class="btn btn-primary change_password_btn" type="submit" id="submit create_bets"  onClick={(e) => {ChangePassword(e);}}>Save Changes</button>
                                    </div>
                                </div>
                        </div>
                    </div>         
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}
