import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export default function Fancybook(props) {
    const [allLog, setallLog] = useState([]);
    const [refresh,setrefresh] = useState(true);


    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        axios.post('https://flagapi.in/agentapi/newagentFancyExposure',{
        sid:ssid,
        eventId:props.eventId,
        selectionId:props.selectionId
        
			  
           }).then(result => {
            setrefresh(false);
            if(result.status === 200){
                
              var res = [];
              for (let key in result.data) { 
                    
                 if (result.data.hasOwnProperty(key)) 
                 {  let obj = {};
                    obj.run = key;
                    obj.position = result.data[key];   
                    res.push(obj);       
                 } 
              }
              // console.log(res)
              setallLog(res);  
            }          
     }
    ).catch(e => {
     //setIsError(true);
     });   
    }, [])
    return (
        <React.Fragment>
            <div id="dynamic_modal">
            <div class="modal-backdrop fade in"></div>
    <div class="modal fade in" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{display: 'block', paddingLeft: "0px"}}>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick = {()=>{props.changeDownlineLive();}}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>RUNS</th>
                                <th>POSITION</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allLog.map((item,index)=>{
        return(
                            <tr id={index}>
                                <td>{item.run}</td>
                                <td>{item.position >= 0 ? '+' + parseFloat(item.position).toFixed(2) : '-'+ parseFloat(Math.abs(item.position)).toFixed(2)}</td>
                            </tr>
                          )})}
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick = {()=>{props.changeDownlineLive();}}>Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

        </React.Fragment>
    )
}
