import React from 'react'

export default function MyLedger() {
    return (
        <React.Fragment>

<div class="row wrapper border-bottom white-bg page-heading">
   <div class="col-lg-10">
      <h2>MY LEDGERS</h2>
      <ol class="breadcrumb">
         <li>
            <a href="/agent/dashboard">Dashboard</a>
         </li>
       
         <li>
            <a href="#"><strong>My Ledgers</strong></a>
         </li>
      </ol>
   </div>
   <div class="col-lg-2">
   </div>
</div>
            <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Agent Ledger</h5>
                    <div class="ibox-tools">
                        <a class="dropdown-toggle" data-toggle="dropdown" href="#"><i class="fa fa-wrench"></i></a>
                        <ul class="dropdown-menu dropdown-user">
                            <li><a target="_blank" href="/agent/ledgers/index_pdf.pdf?start_date=&amp;end_date=">Export PDF</a></li>
                        </ul>
                    </div>
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                {/* <!-- <th>S No</th> --> */}
                                <th>LID</th>
                                <th>DATE/TIME</th>
                                <th>ENTRY</th>
                                <th>DEBIT</th>
                                <th>CREDIT</th>
                                <th>Balance</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>23816004</td>
                                <td id="getdate_23816004">31 May 21</td>
                                <td>Cash Paid</td>
                                <td>1949051.38</td>
                                <td> - </td>
                                <td>-0.0</td>
                                <td>
                                    <div class="note-short-text full_text_modal">TRANSDFER NEW PENAL </div>
                                </td>
                            </tr>
                            <tr>
                                <td>23019907</td>
                                <td id="getdate_23019907">11 Apr 21</td>
                                <td>Sunrisers Hyderabad vs Kolkata Knight Riders</td>
                                <td> - </td>
                                <td>235871.97</td>
                                <td>391540.5</td>
                                <td>
                                    <div class="note-short-text full_text_modal"></div>
                                </td>
                            </tr>
                            <tr>
                                <td>22879098</td>
                                <td id="getdate_22879098">01 Apr 21</td>
                                <td>New Zealand Women vs Australia Women</td>
                                <td>6265.18</td>
                                <td> - </td>
                                <td>-222439.46</td>
                                <td>
                                    <div class="note-short-text full_text_modal"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
