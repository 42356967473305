import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import Cookies from 'universal-cookie';
import {toast} from 'react-toastify'
import Backgreound from './fonts/bg.jpg';
import Logo from './fonts/betadda.png'

toast.configure()
const cookies = new Cookies();

var cc;
var tt;
toast.configure()

export default function DeskLogin(props) {
    const [userid, setId] = useState("");
	const [password, setPassword] = useState("");


    const handleLogin = () => {
		if (userid === "") {
			document.getElementById("errorMsg").innerHTML="Username can not be blank!";
		    // toast.warn('Username can not be blank! ', {position:toast.POSITION.TOP_CENTER})
		   return;
		}
		if (password === "") {
			document.getElementById("errorMsg").innerHTML="Password can not be blank!";
			// toast.warn('Password can not be blank! ', {position:toast.POSITION.TOP_CENTER})
			return;
		}

		var ssid = cookies.get("sid");
	  
		axios
		  .post("https://flagapi.in/agentapi/newagentLogin", {
			id: userid,
			password: password,
		  })
		  .then((result) => {
			if (result.status === 200) {
			  cookies.set("sid", result.data, { path: "/" });
			  props.checkShowLogin(true); 
			//   toast.success('Login Successfull ', {position:toast.POSITION.TOP_CENTER})
			//   return;
			}
			else if(result.status === 205){	  
				document.getElementById("errorMsg").innerHTML="User Blocked!";
				// toast.warn('User blocked! ', {position:toast.POSITION.TOP_CENTER})
				return;	
			  }
			 else {
			//   toast.warn('Username or password incorrect!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML="Username or password incorrect!";
				return;	
			}
		  })
		  .catch((e) => {
			//setIsError(true);
		  });
	  };
    return (
        <React.Fragment>
         <div id="wrapper">
  {/* START Template Canvas */}
  <div id="canvas">
    {/* START Themer */}             
    <div className="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <div>
          <br />
          <h1 className="logo-name" style={{fontSize: '47px'}}>newbook777
            {/* <img alt="image" style="width:250px;" src="/images/LOGO_BLACK.png"/> */}
          </h1>
        </div>
        <h3>Welcome to newbook777</h3>
        <h3>Sign In</h3>
        <h5 style={{color: '#000', fontWeight: 'bold'}} id="loginMessage">-</h5>
        <div acceptCharset="UTF-8" method="post" id="fromTarget" action="/dashboard;jsessionid=582DF9295FF0F59E59D726C6C5520DD8"><div style={{display: 'none'}}><input name="utf8" type="hidden" defaultValue="âœ“" /><input name="authenticity_token" type="hidden" defaultValue="dx5JlyKxxHTyvt2g/c+cx3LBrHguBKiuWomrOusoD/0=" /></div>
          <div className="form-group">
		  <input class="form-control" type="text" value = {userid} onChange ={(e)=>{setId(e.target.value)}} name="username" placeholder="Username"/>
          </div>
          <div className="form-group">
		  <input class="form-control" type="password" value = {password} onChange ={(e)=>{setPassword(e.target.value)}} name="pass" placeholder="Password"/>
          </div>
          <button type="submit" className="btn btn-primary block full-width m-b"  onClick = {()=>{handleLogin()}}>Login</button>
          {/* <a href="/agents/password/new"><small>Forgot password?</small></a> */}
        </div>
        <p className="m-t"> <small>newbook777 © 2015</small> </p>
      </div>
    </div>
    {/*/ END Template Main Content */}
  </div>
  {/*/ END Template Canvas */}
</div>

		    {/* <div class="limiter">
    <div class="container-login100" style={{ backgroundImage:`url(${Backgreound})` }}>
        <div class="wrap-login100">
            <div class="login100-form validate-form">
                <span class="login100-form-logo">
				<img src={Logo} style={{height:'90%'}}/>
					</span>

                <span class="login100-form-title p-b-34 p-t-27">
					
						Agent Log in
					</span>

                <div class="wrap-input100 validate-input" data-validate="Enter username">
                    <input class="input100" type="text" value = {userid} onChange ={(e)=>{setId(e.target.value)}} name="username" placeholder="Username"/>
                    <span class="focus-input100" ><i class="fa fa-user" style={{fontSize:'20px',margin:'12px'}}></i></span>
                </div>

                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" value = {password} onChange ={(e)=>{setPassword(e.target.value)}} name="pass" placeholder="Password"/>
                    <span class="focus-input100" >
						<i class="fa fa-lock" style={{fontSize:'20px',margin:'12px'}}></i></span>
                </div>

							<p id="errorMsg" style={{textAlign:'center',color:'red'}}></p>
                <div class="container-login100-form-btn" >
                    <button class="login100-form-btn" onClick = {()=>{handleLogin()}}>
							Login
						</button>
                </div>

             
            </div>
        </div>
    </div>
</div> */}
        </React.Fragment>
    )
}
