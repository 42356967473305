import React from 'react';

export default function Dashboared(props) {
 
var totalshare = 100;
var remainingshare = (totalshare - props.share);


    return (
        <React.Fragment>
        <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-lg-10">
                <h2>DASHBOARD</h2>
                <ol class="breadcrumb">
                    <li>
                        <a href="/">Dashboard</a>
                    </li>
                    <li>
                        <a href="/"><strong>{props.level}</strong></a>
                    </li>
                </ol>
            </div>
            <div class="col-lg-2">
            </div>
        </div>

        <div class="wrapper wrapper-content">
            <div class="row">

                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-info pull-right">Annual</span> --> */}
                            <h5>MY USERNAME</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{props.user}</h1>
                            {/* <!-- <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div> --> */}
                            <small> </small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-primary pull-right">Today</span> --> */}
                            <h5>MY LEVEL</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins"><span class="translation_missing" title="translation missing: en.sub company">{props.level}</span></h1>
                            {/* <!-- <div class="stat-percent font-bold text-navy">44% <i class="fa fa-level-up"></i></div>
                  <small>New visits</small> --> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-info pull-right">Annual</span> --> */}
                            <h5>MY FIX LIMIT</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{props.balance}</h1>
                            {/* <!-- <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div>
                  <small>New orders</small> --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- <div class="row">
      
   </div> --> */}
            <div class="row">
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-primary pull-right">Today</span> --> */}
                            <h5>Company Contact</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{props.companyContact}</h1>
                            {/* <!-- <div class="stat-percent font-bold text-navy">44% <i class="fa fa-level-up"></i></div>
                  <small>New visits</small> --> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-info pull-right">Annual</span> --> */}
                            <h5>Maximum My Share</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{props.share}%</h1>
                            {/* <!-- <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div>
                  <small>New orders</small> --> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-primary pull-right">Today</span> --> */}
                            <h5>Minimum Company Share</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{remainingshare}%</h1>
                            {/* <!-- <div class="stat-percent font-bold text-navy">44% <i class="fa fa-level-up"></i></div>
                  <small>New visits</small> --> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            { parseFloat(props.matchcomm).toFixed(2) > 0 &&
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-primary pull-right">Today</span> --> */}
                            <h5>Match Commission</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{parseFloat(props.matchcomm).toFixed(2)}</h1>
                            {/* <!-- <div class="stat-percent font-bold text-navy">44% <i class="fa fa-level-up"></i></div>
                  <small>New visits</small> --> */}
                        </div>
                    </div>
                </div>}
                {parseFloat(props.sessioncomm).toFixed(2) > 0 &&
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-info pull-right">Annual</span> --> */}
                            <h5>Session Commission</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{parseFloat(props.sessioncomm).toFixed(2)}</h1>
                            {/* <!-- <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div>
                  <small>New orders</small> --> */}
                        </div>
                    </div>
                </div>}
                {parseFloat(props.rollingcomm).toFixed(2) > 0 &&
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            {/* <!-- <span class="label label-info pull-right">Annual</span> --> */}
                            <h5>Rolling Commission</h5>
                        </div>
                        <div class="ibox-content">
                            <h1 class="no-margins">{parseFloat(props.rollingcomm).toFixed(2)}</h1>
                            {/* <!-- <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div>
                  <small>New orders</small> --> */}
                        </div>
                    </div>
                </div>}
            </div>

        </div>
        </React.Fragment>
    )
}
