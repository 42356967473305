import React from 'react'

export default function CompanyReport() {
    return (
        <React.Fragment>

<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>Company Report</h2>
        <ol class="breadcrumb">
            <li>
                <a href="/dashboard">Dashboard</a>
            </li>
            <li>
                <a href="/liveMatches">Matches</a>
            </li>
            <li>
                <a href="/liveMatches">Islamabad United vs Quetta Gladiators</a>
            </li>
            <li>
                <a href="#"><strong><strong>Company Report</strong></strong></a>
            </li>
        </ol>
    </div>
    <div class="col-lg-2">
    </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Match Report</h5>
                    {/* <!-- <div class="ibox-tools">
              <a class="collapse-link">
              <i class="fa fa-chevron-up"></i>
              </a>
              <a class="dropdown-toggle" data-toggle="dropdown" href="#">
              <i class="fa fa-wrench"></i>
              </a>
              <ul class="dropdown-menu dropdown-user">
                 <li><a href="#">Config option 1</a>
                 </li>
                 <li><a href="#">Config option 2</a>
                 </li>
              </ul>
              <a class="close-link">
              <i class="fa fa-times"></i>
              </a>
           </div> --> */}
                </div>
                <div class="ibox-content table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr class="tbl-header-middle">
                                <th colspan="1"></th>
                                <th colspan="3">Plus / Minus</th>
                                <th colspan="3">COMMISSION</th>
                                <th colspan="6">OTHERS</th>
                            </tr>
                            <tr>
                                <th>Client Name</th>
                                <th>Match Plus Minus</th>
                                <th>Session Plus Minus</th>
                                <th>Total</th>
                                <th>Match Commission</th>
                                <th>Session Commission</th>
                                <th>Total Commission</th>
                                <th>System Plus/Minus</th>
                                <th>OTHERS</th>
                                <th>Final</th>
                                <th>% Share</th>
                                <th>My Share</th>
                                <th>Company Share</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a>demo01</a>
                                </td>
                                {/* <!-- Username --> */}
                                <td>0.0</td>
                                {/* <!-- Match Plus/Minus --> */}
                                <td>1050.0</td>
                                {/* <!-- Session Plus/Minus --> */}
                                <th>1050.0</th>
                                {/* <!-- Total --> */}
                                <td>0.0</td>
                                {/* <!-- Match Commission --> */}
                                <td>21.0</td>
                                {/* <!-- Session Commission --> */}
                                <th>21.0</th>
                                {/* <!-- Total Commission --> */}
                                <td>1029.0</td>
                                {/* <!-- System Plus/Minus --> */}
                                <td>15.0</td>
                                {/* <!-- Other --> */}
                                <th>1044.0</th>
                                {/* <!-- Share % --> */}
                                <td>40.0</td>
                                {/* <!-- Final Report --> */}
                                <td>411.6</td>
                                {/* <!--  My Share --> */}
                                <td>632.4</td>
                                {/* <!-- Comapany Share --> */}
                            </tr>
                            <tr>
                                <td>
                                    <a>demo</a>
                                </td>
                                {/* <!-- Username --> */}
                                <td>-2500.0</td>
                                {/* <!-- Match Plus/Minus --> */}
                                <td>-200.0</td>
                                {/* <!-- Session Plus/Minus --> */}
                                <th>-2700.0</th>
                                {/* <!-- Total --> */}
                                <td>0.0</td>
                                {/* <!-- Match Commission --> */}
                                <td>0.0</td>
                                {/* <!-- Session Commission --> */}
                                <th>0.0</th>
                                {/* <!-- Total Commission --> */}
                                <td>-2700.0</td>
                                {/* <!-- System Plus/Minus --> */}
                                <td>15.0</td>
                                {/* <!-- Other --> */}
                                <th>-2685.0</th>
                                {/* <!-- Share % --> */}
                                <td>40.0</td>
                                {/* <!-- Final Report --> */}
                                <td>-1080.0</td>
                                {/* <!--  My Share --> */}
                                <td>-1605.0</td>
                                {/* <!-- Comapany Share --> */}
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    Total
                                    {/* <!-- Username --> */}
                                </th>
                                <th>-2500.0</th>
                                {/* <!-- Match Plus/Minus --> */}
                                <th>850.0</th>
                                {/* <!-- Session Plus/Minus --> */}
                                <th>-1650.0</th>
                                {/* <!-- Total --> */}
                                <th>0.0</th>
                                {/* <!-- Match Commission --> */}
                                <th>21.0</th>
                                {/* <!-- Session Commission --> */}
                                <th>21.0</th>
                                {/* <!-- Total Commission --> */}
                                <th>-1671.0</th>
                                {/* <!-- System Plus/Minus --> */}
                                <th>30.0</th>
                                {/* <!-- Other --> */}
                                <th>-1641.0</th>
                                {/* <!-- Share% --> */}
                                <th></th>
                                {/* <!-- Final Report --> */}
                                <th>-668.4</th>
                                {/* <!--  My Share --> */}
                                <th>-972.6</th>
                                {/* <!-- Comapany Share --> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
